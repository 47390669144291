import { lazy, useReducer } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { ProtectedRoute } from '@admin8/components';
import { Layout } from '@admin8/layout';
import { mainContextStore, setMainContextStoreDispatch } from '@admin8/shared/utils';
import { MainContext, initialState, mainReducer } from '@admin8/store';

const Billings = lazy(() =>
  import('../pages/Billings').then((module) => ({ default: module.default })),
);

const Billing = lazy(() =>
  import('../pages/Billing').then((module) => ({ default: module.default })),
);

const Employe = lazy(() =>
  import('../pages/Employee').then((module) => ({ default: module.default })),
);

const Help = lazy(() => import('@admin8/help').then((module) => ({ default: module.Help })));

const Agenda = lazy(() =>
  import('../pages/Agenda').then((module) => ({ default: module.default })),
);

const Dashboard = lazy(() =>
  import('../pages/Dashboard').then((module) => ({ default: module.default })),
);

const Settings = lazy(() =>
  import('../pages/Settings').then((module) => ({ default: module.default })),
);

const Package = lazy(() =>
  import('../pages/Package').then((module) => ({ default: module.default })),
);

const Service = lazy(() =>
  import('../pages/Service').then((module) => ({ default: module.default })),
);

const Services = lazy(() =>
  import('../pages/Services').then((module) => ({ default: module.default })),
);

const Reports = lazy(() =>
  import('../pages/Reports').then((module) => ({ default: module.default })),
);

const EmployeeReports = lazy(() =>
  import('../pages/EmployeeReports').then((module) => ({ default: module.default })),
);

const EmployeeDailyReport = lazy(() =>
  import('../pages/EmployeeDailyReport').then((module) => ({ default: module.default })),
);

const Vouchers = lazy(() =>
  import('../pages/Vouchers').then((module) => ({ default: module.default })),
);

const Voucher = lazy(() =>
  import('../pages/Voucher').then((module) => ({ default: module.default })),
);

const Users = lazy(() => import('@admin8/user').then((module) => ({ default: module.Users })));

const User = lazy(() => import('@admin8/user').then((module) => ({ default: module.User })));

const Modal = lazy(() =>
  import('../components/modal').then((module) => ({ default: module.Modal })),
);

const NotFound = lazy(() =>
  import('@admin8/not-found').then((module) => ({ default: module.NotFound })),
);

const Login = lazy(() => import('@admin8/login').then((module) => ({ default: module.default })));

const routes = [
  { path: '/', Component: Agenda },
  { path: '/dashboard', Component: Dashboard },
  { path: '/billings', Component: Billings },
  { path: '/billings/:id', Component: Billing },
  { path: '/resources/:id', Component: Employe },
  { path: '/services', Component: Services },
  { path: '/service/:service', Component: Service },
  { path: '/packages/:id', Component: Package },
  { path: '/settings', Component: Settings },
  { path: '/reports', Component: Reports },
  { path: '/employee-reports', Component: EmployeeReports },
  { path: '/help', Component: Help },
  { path: '/users', Component: Users },
  { path: '/user/:id', Component: User },
  { path: '/employee-daily-report', Component: EmployeeDailyReport },
  { path: '/vouchers', Component: Vouchers },
  { path: '/vouchers/:id', Component: Voucher },
  { path: '/login', Component: Login, isAllowed: true },
  { path: '*', Component: NotFound, isAllowed: true },
];

const router = (t, isLoggenIn) =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout modals={<Modal />} />}>
        {routes.map(({ path, Component, isAllowed }) => {
          const isRouteAllowed =
            isLoggenIn && isAllowed && path === '/login'
              ? false
              : isAllowed
                ? isAllowed
                : isLoggenIn;

          const redirectPath = '/';

          const protectedRouteProps = {
            isAllowed: isRouteAllowed,
            ...(isLoggenIn && { redirectPath }),
          };

          const componentProps = {
            ...(path === '/' && { title: t('Welcome to administration Spell') }),
            ...(path === '*' && { isLogged: true }),
          };

          return (
            <Route
              path={path}
              key={path}
              element={
                <ProtectedRoute {...protectedRouteProps}>
                  <Component {...componentProps} />
                </ProtectedRoute>
              }
            />
          );
        })}
      </Route>,
    ),
  );

export default function App() {
  const { t } = useTranslation();
  const [mainContext, dispatch] = useReducer(mainReducer, initialState);
  if (!mainContextStore.isReady) {
    setMainContextStoreDispatch(dispatch);
  }

  return (
    <MainContext.Provider value={{ mainContext, dispatch }}>
      <RouterProvider router={router(t, mainContext.userLoggedIn)} />
    </MainContext.Provider>
  );
}
