import { RightPanelProps } from '@admin8/shared/types';

import SelectLanguage from '../modal/SelectLanguage';
import './RightPanel.scss';

export default function RightPanel({ sections, selectLang = false }: RightPanelProps) {
  return (
    <div className="right">
      <div>
        <ul>
          {sections.map((item) => (
            <li
              key={item.toCheck}
              className={`${item.warning ? 'warning' : 'done'}${item.active ? ' active' : ''}`}
              {...(item.scrollToSection && { onClick: item.scrollToSection })}
            >
              {item.toCheck}
            </li>
          ))}
        </ul>
        {selectLang && <SelectLanguage />}
      </div>
    </div>
  );
}
