import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import { initReactI18next } from 'react-i18next';

import { getLocalStorageItem } from '@admin8/shared/utils';

import translationEN from './en/translation.json';
// import translationHU from './hu/translation.json';

// Resources
export const resources = {
  en: {
    translations: translationEN,
  },
};

// Detector options
export const options = {
  // order and from where user language should be detected
  order: ['navigator', 'htmlTag'],
  lookupLocalStorage: 'i18nextLng',

  // cache user language
  caches: ['localStorage'],
  excludeCacheFor: ['cimode'],
};

type ConfigurationType = {
  returnNull: boolean;
  lng: string;
  fallbackLng: string;
  debug: boolean;
  load: 'languageOnly' | 'all';
  // have a common namespace used around the full app
  ns: string[];
  defaultNS: string;
  keySeparator: string | false;
  interpolation: {
    escapeValue: boolean;
    formatSeparator: string;
  };
  react: {
    useSuspense: boolean;
  };
};
// i18n configuration
const configuration: ConfigurationType = {
  returnNull: false,
  lng: getLocalStorageItem('i18nextLng') ?? 'en',
  fallbackLng: 'en',
  debug: false,
  load: 'languageOnly',
  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },
  react: {
    useSuspense: true,
  },
};

export { configuration };

export const i18nRef = {
  isReady: false,
  i18n: null,
};

i18n.use(XHR).use(LanguageDetector).use(initReactI18next);

export default i18n;
