import constants from '@admin8/shared/constants';
import { FormValuesNotificationType } from '@admin8/shared/types';
import { escapeString } from '@admin8/shared/utils';

const { RECORD_STATUS_NEW, RECORD_STATUS_VALID } = constants;

export const getNotifications = (
  first: number,
  page: number,
  text = { value: '', name: '' },
  sort,
) => {
  return {
    query: `
    {
      notifications(
        first: ${first}
        page: ${page}
        where: {
          AND: [
            {
              OR: [
                { column: STATUS_ID, value: ${RECORD_STATUS_NEW} }
                { column: STATUS_ID, value: ${RECORD_STATUS_VALID} }
              ]
            }
            ${
              text.name === 'CONTENT' && text.value
                ? `{ column: CONTENT, operator: LIKE, value: "%${text.value}%" }`
                : ''
            }
            ${
              text.name === 'CREATED_AT' && text.value
                ? `{ column: CREATED_AT, operator: LIKE, value: "%${text.value}%" }`
                : ''
            }
          ]
        }
        ${sort ? `orderBy: { column: ${sort.fieldName}, order: ${sort.orderDirection} }` : ''}
        ) {
        paginatorInfo { total, lastPage }
        data {
          id
          status {
            id
          }
          language_id
          content
          created_at
          updated_at
        }
      }
    } 
      `,
  };
};

export const getNotification = (id: string) => {
  return {
    query: `
    {
      notification(id: "${id}") {
        id
        language_id
        content
        status { id }
      }
    }
      `,
  };
};

export const createNotification = (data: FormValuesNotificationType) => {
  return {
    query: `
    mutation {
      notificationCreate(
        input: { author_id: 1, language_id: ${data.language}, content: "${escapeString(
          data.content,
        )}", status_id: ${data.status} }
      ) {
        id
        language_id
        content
      }
    }
      `,
  };
};

export const updateNotification = (id: string, data: FormValuesNotificationType) => {
  return {
    query: `
    mutation {
      notificationUpdate(
        id: "${id}"
        input: { id: "${id}", status_id: ${data.status}, content: "${escapeString(
          data.content,
        )}", language_id: ${data.language} }
      ) {
        id
        status {
          id
        }
        language_id
        content
      }
    }
      `,
  };
};

export const removeNotifications = (idsArr: string[]) => {
  return {
    query: `
    mutation($ids: [ID!]!) {
      notificationDeleteMultiple(id: $ids) {
        id
      }
    }
      `,
    variables: {
      ids: idsArr,
    },
  };
};
