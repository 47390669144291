import { useContext, useEffect } from 'react';

import { MainContext, setIsEditing } from '@admin8/store';

export default function useIsFormDirty2(dirtyFields) {
  const { dispatch } = useContext(MainContext);

  useEffect(() => {
    const isDirty = Object.values(dirtyFields).filter(Boolean).length > 0;

    if (isDirty) {
      dispatch(setIsEditing(true));
    } else {
      dispatch(setIsEditing(false));
    }
    // eslint-disable-next-line
  }, [Object.keys(dirtyFields).length]);
}
