import { ChartSliderContextActions, ChartSliderContextType } from '@admin8/shared/types';
import { dates /* , generateMonth */ } from '@admin8/shared/utils';

const { YEAR_NOW, FIRST, LAST_MONTH } = dates;

const mapFunc = (arr: any[]) =>
  arr.map((item) => ({
    value: item,
    label: String(item),
  }));

export const ChartSliderReducer = (
  state: ChartSliderContextType['chartSliderContext'],
  action: ChartSliderContextActions,
) => {
  switch (action.type) {
    case 'SET_LAST_YEAR': {
      const lastYearNum = action?.payload;
      return { ...state, lastYear: lastYearNum };
    }
    case 'SET_MONTH': {
      return { ...state, month: action.payload };
    }
    case 'SET_MONTH_TURNOVER': {
      return { ...state, monthTurnover: action.payload };
    }
    /* case 'SET_DAY': {
      return { ...state, day: action.payload };
    } */
    case 'SET_MARKS': {
      const years = [];
      for (let i = state.lastYear; i <= YEAR_NOW; i++) years.push(i);
      const data = mapFunc(years);
      return { ...state, marks: data };
    }
    case 'SET_MARKS_MONTH': {
      const months = [];
      for (let i = FIRST; i <= LAST_MONTH; i++) months.push(i);
      const data = mapFunc(months);
      return { ...state, marksMonth: data };
    }
    /* case 'SET_MARKS_DAY': {
      const date = new Date(state.sliderValue, state.month - 1);
      const numOfWeeks = generateMonth(date).length;
      let weeks = [];
      for (let i = FIRST; i <= numOfWeeks; i++) weeks.push(i);
      const data = mapFunc(weeks);
      return { ...state, marksDay: data };
    } */
    case 'SET_SLIDER_VALUE': {
      return { ...state, sliderValue: action.payload };
    }
    case 'SET_SLIDER_VALUE_TURNOVER': {
      return { ...state, sliderValueTurnover: action.payload };
    }
    default:
      return state;
  }
};
