import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';

import { getSlugs } from '@admin8/graphql';
import { DataProductSlug } from '@admin8/shared/types';
import { debounce } from '@admin8/shared/utils';

export default function useCreateAndCheckSlug({ productVersion, trigger }) {
  const [slug, setSlug] = useState<string>('');
  const [slugExist, setslugExist] = useState<boolean>(false);

  const GET_POST = getSlugs(slug, productVersion);
  const { data } = useQuery<DataProductSlug, Error>(['slug', GET_POST], {
    enabled: slug.length > 0,
  });

  const onSlugChangeHandler = debounce((slug) => setSlug(slug), 1000);

  useEffect(() => {
    const slugExist = data?.data?.data?.productSlug;
    if (slugExist) setslugExist(slugExist.exists);
    if (slug.length === 0) setslugExist(false);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (slugExist) trigger('slug');
  }, [slugExist, trigger]);

  return { slug, slugExist, onSlugChangeHandler };
}
