import { createContext } from 'react';

import { TermsContextType } from '@admin8/shared/types';

export const termsState = {
  defaultTerms: [],
  terms: [],
  deleted: [],
  schema: {},
};

const TermsContext = createContext<TermsContextType>({
  termsContext: termsState,
  dispatchTerms: () => null,
});

export default TermsContext;
