import constants from '@admin8/shared/constants';
import {
  CategoriesArrType,
  CategoriesType,
  CategoriesWithExpandedType,
  HierarchizedTree,
} from '@admin8/shared/types';

const { LANGUAGE_EN, LANGUAGE_CZ } = constants;

const sortChildren = (parent: HierarchizedTree) => {
  parent?.children?.sort((a, b) =>
    a.title && b.title ? (a?.title?.toLowerCase() > b?.title?.toLowerCase() ? 1 : -1) : 0,
  );
  parent?.children?.forEach((item) => sortChildren(item));
};

export const hierarchize = (
  categories: CategoriesArrType,
  current: string | null | undefined = '',
): HierarchizedTree[] => {
  const copyOfCategories = [...categories];
  const trimmedTreeData: HierarchizedTree[] = [];
  let counter = 0;

  const expandParent = (element: CategoriesType) => {
    const foundParent = copyOfCategories.find((item) => item.id === element.parent_id);
    if (foundParent) {
      (foundParent as CategoriesWithExpandedType).expanded = true;
      expandParent(foundParent);
    }
  };

  copyOfCategories.forEach((item) => {
    if (item.id === current && item.parent_id !== null) {
      expandParent(item);
    }
  });

  const createRootCategories = (item: CategoriesWithExpandedType) => {
    const czTexts = item?.texts?.find((txt) => txt.language_id === LANGUAGE_CZ) ?? undefined;
    const enTexts = item?.texts?.find((txt) => txt.language_id === LANGUAGE_EN) ?? undefined;
    // top level categories
    const newRootCategory: HierarchizedTree = {
      title: item.name,
      id: item.id,
      is_visible: item.is_visible,
      texts_cz: {
        title: czTexts?.name ?? '',
        slug: czTexts?.slug ?? '',
        description: czTexts?.description ?? '',
      },
      texts_en: {
        title: enTexts?.name ?? '',
        slug: enTexts?.slug ?? '',
        description: enTexts?.description ?? '',
      },
      cover_image: item.cover_image,
      parent_id: item.parent_id,
      children: [],
      expanded: item.expanded,
      ...(item?.isDisabled && { isDisabled: item?.isDisabled }),
    };
    attachChildren(newRootCategory);
    trimmedTreeData.push(newRootCategory);
  };

  const attachChildren = (parent: HierarchizedTree) => {
    ++counter;
    if (counter < 1000) {
      // for safety
      const directChildren = (copyOfCategories as CategoriesWithExpandedType[]).filter(
        (item2) => item2.parent_id === parent.id,
      );
      directChildren.forEach((child) => {
        const czTexts = child?.texts?.find((txt) => txt.language_id === LANGUAGE_CZ);
        const enTexts = child?.texts?.find((txt) => txt.language_id === LANGUAGE_EN);
        const newChild = {
          title: child.name,
          id: child.id,
          is_visible: child.is_visible,
          texts_cz: {
            title: czTexts?.name ?? '',
            slug: czTexts?.slug ?? '',
            description: czTexts?.description ?? '',
          },
          texts_en: {
            title: enTexts?.name ?? '',
            slug: enTexts?.slug ?? '',
            description: enTexts?.description ?? '',
          },
          cover_image: child.cover_image,
          parent_id: child.parent_id,
          children: [],
          expanded: child.expanded,
        };
        attachChildren(newChild);
        parent?.children?.push(newChild);
      });
      return parent;
    } else {
      return false;
    }
  };

  (copyOfCategories as CategoriesWithExpandedType[])
    .filter((item) => item.parent_id === null)
    .forEach((item) => createRootCategories(item));

  // sort Alphabetically
  trimmedTreeData.sort((a, b) =>
    a?.title && b?.title ? (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1) : 0,
  );
  trimmedTreeData.forEach((item) => sortChildren(item));

  return trimmedTreeData;
};
