import { useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';

import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NotPublishedText } from '@admin8/components';
import { InputPasswordProps } from '@admin8/shared/types';

export default function InputPassword({
  control,
  hasChanged,
  name = 'password',
  showPasswordTxt,
  isRequired = false,
  error,
  css = '',
  errorMsg,
  label,
  minLength,
  showHelperTxt = true,
  disabled = false,
}: InputPasswordProps) {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const showPasswordHandler = () => setShowPassword((bool) => !bool);

  const inputWrapperCss = css && { className: css };
  const isNotPublishedTextEnabled = typeof hasChanged === 'boolean';

  const controllerRules = {
    required: isRequired,
    minLength: minLength ? minLength : 8,
  };

  const inputProps = (onChange, onBlur, value, ref) => ({
    variant: 'outlined' as const,
    type: showPassword ? 'text' : 'password',
    label: label ? label : t('Password'),
    className: `input${hasChanged ? ' notPublishedOneLine' : ''}`,
    error: !!error,
    ...(errorMsg && { style: { marginBottom: errorMsg ? '1.3rem' : '' } }),
    value,
    onChange,
    onBlur,
    inputRef: ref,
    'data-testid': name,
    autoComplete: 'on',
    ...(showHelperTxt && { helperText: t('The password must contain at least 8 characters') }),
    ...(disabled && { disabled: disabled }),
  });

  return (
    <div {...inputWrapperCss}>
      {isNotPublishedTextEnabled && <NotPublishedText condition={hasChanged} />}

      {showPasswordTxt && (
        <p className="info user">{t('Enter the password only if you wish to change it')}</p>
      )}

      <Controller
        name={name}
        control={control}
        rules={controllerRules}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            {...inputProps(onChange, onBlur, value, ref)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={showPasswordHandler}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      {isRequired && error && <div className="error">{t('You must enter a password')}</div>}
      {errorMsg && <div className="errorMessage">{t(errorMsg)}</div>}
    </div>
  );
}
