import { useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

export default function CssTransition({ children }) {
  const location = useLocation();

  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={location.key}
        classNames="page"
        timeout={300}
        unmountOnExit
        appear
        exit={false}
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
}
