import { TableContextType } from '@admin8/shared/types';

export const getCountries = (
  first: number,
  page: number,
  text = { value: '', name: '' },
  filters: TableContextType['tableContext']['customFilters']['countries'],
  sort,
) => {
  return {
    query: `
      {
        countries(first: ${first}, page: ${page}${
          text.value || typeof filters?.status === 'boolean'
            ? `, where: {
          ${
            typeof filters?.status === 'boolean'
              ? `AND: { column: IS_VISIBLE, value: ${filters.status} }`
              : ''
          }
          ${
            text.name === 'CODE' && text.value
              ? `OR: { column: CODE, operator: LIKE, value: "%${text.value}%" }`
              : ''
          }
          ${
            text.name === 'NAME' && text.value
              ? `OR: { column: NAME, operator: LIKE, value: "%${text.value}%" }`
              : ''
          }
        }`
            : ''
        }${sort ? `, orderBy: { column: ${sort.fieldName}, order: ${sort.orderDirection} }` : ''}) {
          paginatorInfo { total, lastPage }
          data {
            id
            code
            code_long
            name
            is_visible
            texts {
              language_id
              name
              full_name
            }
          }
        }
      }    
      `,
  };
};

export const getCountry = (id: string) => {
  return {
    query: `
      {
        country(id: ${id}) {
          id
          code
          code_long
          name
          is_visible
          texts {
            language_id
            name
            full_name
          }
        }
      }  
      `,
  };
};

export const updateCountry = (id: string, isVisible: boolean) => {
  return {
    query: `
    mutation {
        countryUpdate(id: ${id}, input: { is_visible: ${isVisible} }) {
          id
          code
          code_long
          name
          is_visible
          texts {
            language_id
            name
            full_name
          }
        }
      }
      `,
  };
};
