import { createContext } from 'react';

import { TableContextType } from '@admin8/shared/types';

export const initialTableState = {
  customFilters: {
    products: { category: { id: '', isParentCategory: false, name: '' } },
    posts: { language: '', type: '' },
    events: { active: '', type: '', difficulty: '', location: '' },
    countries: { status: '' },
    orders: { currency: '' },
  },
};

export const TableContext = createContext<TableContextType>({
  tableContext: initialTableState,
  dispatchTable: () => null,
});
