import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { GalleryProps } from '@admin8/shared/types';

import GalleryDnD from './GalleryDnD';

export default function GalleryWrapper(props: GalleryProps) {
  return (
    <DndProvider backend={HTML5Backend}>
      <GalleryDnD {...props} />
    </DndProvider>
  );
}
