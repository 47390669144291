import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';

import { EditorState, Modifier } from 'draft-js';

import { useTranslation } from 'react-i18next';

import Icon from '../../../icon';

export default function ToolbarMailToLink(props: any) {
  const { editorState, onChange, setFocus } = props;
  const { t } = useTranslation();
  const [mail, setMail] = useState('');
  const inputOnChangeHandler = (e: ChangeEvent<HTMLInputElement>) => setMail(e.target.value);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const showModal = () => setIsOpenModal(true);
  const hideModal = () => setIsOpenModal(false);
  const cancelModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setTimeout(() => hideModal());
  };

  const addLink = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    const contentState = (editorState as EditorState).getCurrentContent();
    const blockMap = contentState.getBlockMap();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
      href: `mailto:${mail}`,
      url: `mailto:${mail}`,
      target: '_self',
      title: `mailto:${mail}`,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const selection = editorState.getSelection();
    const start = editorState.getSelection().getStartOffset();
    const end = editorState.getSelection().getEndOffset();
    const key = blockMap.last().getKey();
    const newSelection = selection.merge({
      anchorKey: key,
      anchorOffset: end + mail.length,
      focusKey: key,
      focusOffset: end + mail.length,
    });

    let newcontentState;
    if (start === end) {
      newcontentState = Modifier.insertText(
        contentState,
        selection,
        mail,
        editorState.getCurrentInlineStyle(),
        entityKey,
      );
    } else {
      newcontentState = Modifier.applyEntity(contentState, selection, entityKey);
    }

    const newState = EditorState.createWithContent(newcontentState);

    onChange(EditorState.forceSelection(newState, newSelection));

    // onChange(EditorState.push(editorState, newcontentState, 'insert-characters'));
    setTimeout(() => hideModal());
    setFocus();
  };

  useEffect(() => {
    const checkOutsideClick = (e: any) => {
      if (toolbarRef.current && toolbarRef.current?.contains(e.target)) {
        showModal();
      } else if (modalRef.current && !modalRef.current?.contains(e.target)) {
        hideModal();
      } else {
        return;
      }
    };

    document.addEventListener('click', checkOutsideClick);

    return () => document.removeEventListener('click', checkOutsideClick);
  }, []);

  return (
    <div ref={toolbarRef} className="editorToolbarMail" onClick={showModal} title="email">
      <div className="rdw-option-wrapper">
        <Icon id="envelope" width={15} height={15} />
      </div>

      <div ref={modalRef} className={`editorToolbarModal${isOpenModal ? ' show' : ''}`}>
        <label htmlFor="email">{t('Email address')}</label>
        <input type="email" name="email" onChange={inputOnChangeHandler} />
        <div className="btns">
          <button disabled={mail.length === 0} onClick={addLink}>
            {t('Add')}
          </button>
          <button onClick={cancelModal}>{t('Cancel')}</button>
        </div>
      </div>
    </div>
  );
}
