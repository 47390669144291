const saloonPaths = {
  segment: 'Recepce',
  routes: [
    {
      alt: 'agenda',
      name: 'Denní rozvrh',
      isEnabled: ['agenda read'],
      path: '/',
      icon: 'calendar',
    },
    {
      alt: 'billings',
      name: 'Pokladna',
      isEnabled: ['billings read'],
      path: '/billings',
      icon: 'dollar',
      activeClass: ['billings'],
    },
  ],
};

const reportPaths = {
  segment: 'reporty',
  routes: [
    {
      alt: 'dashboard',
      name: 'Denní',
      isEnabled: ['daily_reports read'],
      path: '/dashboard',
      icon: 'eye',
      activeClass: ['dashboard'],
    },
    {
      alt: 'reports',
      name: 'Měsíční',
      isEnabled: ['reports read'],
      path: '/reports',
      icon: 'cube',
    },
    {
      alt: 'employee-reports',
      name: 'Zaměstnanci',
      isEnabled: ['employee_report read'],
      path: '/employee-reports',
      icon: 'cube',
    },
  ],
};

const otherPaths = {
  segment: 'jiné',
  routes: [
    {
      alt: 'users',
      name: 'Users',
      isEnabled: ['users read'],
      path: '/users',
      icon: 'person-2',
      activeClass: ['user', 'customer'],
    },
    {
      alt: 'vouchers',
      name: 'Vouchers',
      isEnabled: ['vouchers read'],
      path: '/vouchers',
      icon: 'giftcard',
      activeClass: ['voucher'],
    },
    {
      alt: 'settings',
      name: 'Settings',
      isEnabled: ['settings read'],
      activeClass: ['settings'],
      path: '/settings',
      icon: 'gearshape',
    },

    {
      alt: 'help',
      name: 'Help',
      isEnabled: true,
      path: '/help',
      icon: 'questionmark-circle',
    },
  ],
};

const navigation = [saloonPaths, reportPaths, otherPaths];
export default navigation;
