import { useContext } from 'react';

import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';

import {
  hideBranches,
  hideCategory,
  hideConsultants,
  hideEvents,
  hidePages,
  hidePosts,
  hideProducts,
} from '@admin8/graphql';
import { useEventEnterListener } from '@admin8/shared/hooks';
import { GraphQLPageSchemasObjType, QueryObjectType } from '@admin8/shared/types';
import { defaultMutationFn } from '@admin8/shared/utils';
import { MainContext, setModal } from '@admin8/store';

import BaseModal from './BaseModal';

const SetHidden = () => {
  const {
    mainContext: { modal, tableRef },
    dispatch,
  } = useContext(MainContext);
  const { pathname } = useLocation();
  const pageName = pathname?.substring(1);

  const handleClose = () => {
    modal.returnFunction && modal.returnFunction();
    dispatch(setModal({ open: false, id: null, data: null, returnFunction: null, size: null }));
  };

  const { mutate } = useMutation((graphQuery: QueryObjectType) => defaultMutationFn(graphQuery));

  const onSubmit = () => {
    const idsFromTable = tableRef?.current?.state?.data
      ?.filter((item) => item?.tableData?.checked)
      .map((item) => item.id);

    const graphQLPageSchemas: GraphQLPageSchemasObjType = {
      products: hideProducts(idsFromTable),
      consultants: hideConsultants(idsFromTable),
      posts: hidePosts(idsFromTable),
      events: hideEvents(idsFromTable),
      branches: hideBranches(idsFromTable),
      pages: hidePages(idsFromTable),
      ...(modal?.data ? { categories: hideCategory(modal?.data) } : null),
    };

    const graphQLPageSchema = graphQLPageSchemas[pageName];
    const SET_HIDDEN_POST = graphQLPageSchema;
    mutate(SET_HIDDEN_POST);
    handleClose();
  };

  useEventEnterListener(onSubmit);

  return <BaseModal title="hide" onSubmit={onSubmit} onClose={handleClose} />;
};

export default SetHidden;
