import { createContext } from 'react';

import { CategoryContextType } from '@admin8/shared/types';

export const initialCategoryState = {
  category: null,
  parent_id: '',
  texts: {
    description_cz: '',
    description_en: '',
    name_cz: '',
    name_en: '',
    parent_id: '',
    slug_cz: '',
    slug_en: '',
  },
  image: null,
};

export const CategoryContext = createContext<CategoryContextType>({
  categoryContext: initialCategoryState,
  dispatchCategory: () => null,
});
