import { useTranslation } from 'react-i18next';

import { Icon, NotPublishedText } from '@admin8/components';
import { EmptyImageProps } from '@admin8/shared/types';
import './EmptyImage.scss';

export default function EmptyImage({ hasChanged, openModal, name = '' }: EmptyImageProps) {
  const { t } = useTranslation();
  return (
    <div
      className={`emptyImage${hasChanged ? ' notPublishedOneLine' : ''}`}
      onClick={(e) => openModal(e, 1, 'xxl')}
      data-testid={name}
    >
      <NotPublishedText condition={hasChanged} />
      <Icon id="upload" width={15} height={15} />
      {t('upload')}
    </div>
  );
}
