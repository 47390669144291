export default {
  // Generic
  'generic.add': 'Přidat',
  'generic.cancel': 'Zrušit',

  // BlockType
  'components.controls.blocktype.h1': 'Nadpis 1',
  'components.controls.blocktype.h2': 'Nadpis 2',
  'components.controls.blocktype.h3': 'Nadpis 3',
  'components.controls.blocktype.h4': 'Nadpis 4',
  'components.controls.blocktype.h5': 'Nadpis 5',
  'components.controls.blocktype.h6': 'Nadpis 6',
  'components.controls.blocktype.blockquote': 'Bloková citace',
  'components.controls.blocktype.code': 'Kód',
  'components.controls.blocktype.blocktype': 'Typ bloku',
  'components.controls.blocktype.normal': 'Normální',

  // Color Picker
  'components.controls.colorpicker.colorpicker': 'Výběr barvy',
  'components.controls.colorpicker.text': 'Text',
  'components.controls.colorpicker.background': 'Zvýraznit',

  // Embedded
  'components.controls.embedded.embedded': 'Vložené',
  'components.controls.embedded.embeddedlink': 'Vložený odkaz',
  'components.controls.embedded.enterlink': 'Zadejte odkaz',

  // Emoji
  'components.controls.emoji.emoji': 'Emoji',

  // FontFamily
  'components.controls.fontfamily.fontfamily': 'Písmo',

  // FontSize
  'components.controls.fontsize.fontsize': 'Velikost písma',

  // History
  'components.controls.history.history': 'Historie',
  'components.controls.history.undo': 'Zpět',
  'components.controls.history.redo': 'Vpřed',

  // Image
  'components.controls.image.image': 'Obrázek',
  'components.controls.image.fileUpload': 'Nahrání souboru',
  'components.controls.image.byURL': 'URL',
  'components.controls.image.dropFileText': 'Přetáhněte soubor nebo jej nahrajte kliknutím',

  // Inline
  'components.controls.inline.bold': 'Tučně',
  'components.controls.inline.italic': 'Kurzíva',
  'components.controls.inline.underline': 'Podtrhnout',
  'components.controls.inline.strikethrough': 'Přeškrtnutí',
  'components.controls.inline.monospace': 'Jednoprostorový',
  'components.controls.inline.superscript': 'Horní index',
  'components.controls.inline.subscript': 'Dolní index',

  // Link
  'components.controls.link.linkTitle': 'Název odkazu',
  'components.controls.link.linkTarget': 'Cíl odkazu',
  'components.controls.link.linkTargetOption': 'Otevřít odkaz v novém okně',
  'components.controls.link.link': 'Odkaz',
  'components.controls.link.unlink': 'Odpojit',

  // List
  'components.controls.list.list': 'Seznam',
  'components.controls.list.unordered': 'Neuspořádaný seznam',
  'components.controls.list.ordered': 'Uspořádaný seznam',
  'components.controls.list.indent': 'Odrážka',
  'components.controls.list.outdent': 'Předsadit',

  // Remove
  'components.controls.remove.remove': 'Odstranit',

  // TextAlign
  'components.controls.textalign.textalign': 'Zarovnání textu',
  'components.controls.textalign.left': 'Vlevo',
  'components.controls.textalign.center': 'Na střed',
  'components.controls.textalign.right': 'Vpravo',
  'components.controls.textalign.justify': 'Vyrovnat',
};
