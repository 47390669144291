import { useEffect } from 'react';

export default function useEventEnterListener(
  handleSubmit: (action: boolean | string) => void | (() => void),
  optional = false,
) {
  const handleKeySubmit = (e: KeyboardEvent) => {
    if (e.keyCode === 13 || e.code === 'Enter') {
      optional ? handleSubmit(optional) : (handleSubmit as () => void)();
    }
  };

  useEffect(() => {
    document.addEventListener('keypress', handleKeySubmit);

    return () => document.removeEventListener('keypress', handleKeySubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
