import { Fragment, useCallback, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { Icon } from '@admin8/components';
import { useWindowWidth } from '@admin8/shared/hooks';
import { getLocalStorageItem, removeStoredData } from '@admin8/shared/utils';
import { AppContext, MainContext, setIsEditing, setLoginStatus, setOpenNav } from '@admin8/store';

import navigation from './nav.config';

import './Nav.scss';

export default function Nav() {
  const {
    mainContext: { isOpenNav, permissions, isEditing },
    dispatch,
  } = useContext(MainContext);
  const { enabledPages } = useContext(AppContext);
  const { t } = useTranslation();
  const width = useWindowWidth();
  const navigate = useNavigate();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (animate === false) setAnimate(true);
    // eslint-disable-next-line
  }, []);

  const handleCloseNav = () => dispatch(setOpenNav(!isOpenNav));

  const logOutHandler = useCallback(() => {
    removeStoredData();
    dispatch(setLoginStatus(false));
    dispatch(setOpenNav(false));
    isEditing && dispatch(setIsEditing(false));
    navigate('/login');
  }, [dispatch, isEditing, navigate]);

  return (
    <nav className={`nav${isOpenNav ? ' open' : ''}${animate ? ' animateNav' : ''}`}>
      <div className="navInner">
        {width >= 1024 && (
          <div className="headlineNav">
            <p className="headlineNavText">{t('Navigation')}</p>
          </div>
        )}

        <ul>
          {navigation.map(({ segment, routes }) => (
            <Fragment key={segment}>
              <li>
                <h3 className="text_small">{segment}</h3>
              </li>

              {routes.map((route) => {
                const hasActiveClass = Object.hasOwn(route, 'activeClass');
                const checkActive = () =>
                  !!(route as { activeClass: string[] }).activeClass?.find(
                    (item: string) => window.location.pathname.indexOf(item) > -1,
                  );

                const checkAvailability = () =>
                  !!permissions?.find((permission) => {
                    let isAvailable = false;
                    (route.isEnabled as string[]).forEach((enableRoute) => {
                      if (enableRoute === permission) isAvailable = true;
                    });
                    return isAvailable;
                  });

                const canRouteRender =
                  ((typeof route.isEnabled === 'boolean' && route.isEnabled) ||
                    (Array.isArray(route.isEnabled) && permissions && checkAvailability())) &&
                  enabledPages?.includes(route.alt);

                return canRouteRender ? (
                  <li key={route.alt}>
                    <NavLink
                      to={route.path}
                      className={({ isActive }) =>
                        (hasActiveClass && checkActive()) || isActive ? 'active' : undefined
                      }
                      {...(width <= 1024 && { onClick: handleCloseNav })}
                    >
                      <Icon id={route.icon} width={20} height={20} /> {t(route.name)}
                    </NavLink>
                  </li>
                ) : null;
              })}
            </Fragment>
          ))}
        </ul>

        {width <= 1024 && (
          <ul className="mobileNavLogout">
            <li>
              <NavLink
                to="/profile"
                className={({ isActive }) => (isActive ? 'active profile' : 'profile')}
                onClick={handleCloseNav}
              >
                <>
                  <span>{getLocalStorageItem('user_typo')}</span> {t('Profile')}
                </>
              </NavLink>
            </li>
            <li>
              <button className="logout" onClick={logOutHandler}>
                <Icon id="sign-out" width={20} height={20} /> {t('Log out')}
              </button>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
}
