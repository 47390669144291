import { useState } from 'react';

import { Autocomplete, Button, Paper, TextField } from '@mui/material';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  AutocompleteOptions,
  AutocompleteProps,
  FormDefaultsCategoryType,
} from '@admin8/shared/types';

export default function AutocompleteSearch({
  name,
  css = '',
  options,
  label,
  hasChanged,
  error,
  isRequired = false,
  errorMessage = '',
  defaultValue,
  onAddNewItem = null,
  onChange: customOnChange = undefined,
  onInput: customOnInput = undefined,
}: AutocompleteProps) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<FormDefaultsCategoryType>();

  const [val, setVal] = useState<string>('');
  const isErrorMsgEnabled = isRequired && error && errorMessage;

  const findOptionByValue = (value) => {
    return options.find((option) => option.value === value) || null;
  };

  const handleInputChange = (e) => {
    customOnInput && customOnInput(e);
    setVal(e?.target?.value);
  };

  return (
    <div {...(css && { className: css })}>
      <Controller
        name={name}
        control={control}
        rules={{ required: isRequired }}
        defaultValue={defaultValue?.value}
        render={({ field }) => {
          return (
            <Autocomplete
              value={findOptionByValue(field.value)}
              options={options}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
              noOptionsText={
                val.replace(/\s+/g, '').length < 3 ? t('Type 3 chars') : t('No options')
              }
              getOptionLabel={(option) => option.label}
              defaultValue={defaultValue}
              onInput={handleInputChange}
              onChange={(e, selectedOption) => {
                if (selectedOption) {
                  field.onChange(selectedOption?.value);
                  customOnChange && customOnChange(selectedOption?.value);
                }
              }}
              // {...inputProps(onChange, value, ref, name)}
              PaperComponent={
                onAddNewItem
                  ? ({ children }) => {
                      return (
                        <Paper>
                          {children}
                          <Button
                            color="primary"
                            fullWidth
                            sx={{ justifyContent: 'flex-start', pl: 2 }}
                            onMouseDown={() => {
                              onAddNewItem();
                            }}
                          >
                            + {t('Add client')}
                          </Button>
                        </Paper>
                      );
                    }
                  : undefined
              }
              renderInput={(params) => (
                <TextField
                  {...(isRequired && { error: !!error })}
                  {...params}
                  label={label}
                  className={`input hiddenHelperText${hasChanged ? ' notPublishedOneLine' : ''}${
                    errors?.[name] ? ' hideHelperText' : ''
                  }`}
                />
              )}
            />
          );
        }}
      />
      {isErrorMsgEnabled && <div className="error">{t(errorMessage)}</div>}
    </div>
  );
}
