import { memo, useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { NotPublishedText } from '@admin8/components';
import { useWindowWidth } from '@admin8/shared/hooks';
import { GalleryImageBoxProps, GalleryProps, GalleryReducer } from '@admin8/shared/types';
import { axios } from '@admin8/shared/utils';
import { MainContext, setModal } from '@admin8/store';

import {
  galleryAdd,
  galleryRemove,
  galleryReplace,
  gallerySerialize,
  gallerySet,
  galleryUpdate,
} from './actions';
import GalleryCustomDragLayer from './GalleryCustomDragLayer';
import GalleryImageBox from './GalleryImageBox';
import GallerySlider from './GallerySlider';
import GalleryUploadImage from './GalleryUploadImage';
import {
  GALLERY_FOUR_ITEM,
  GALLERY_ONE_ITEM,
  GALLERY_THREE_ITEM,
  GALLERY_TWO_ITEM,
  GALLERY_TWO_ITEM_1,
  getBreakpointValue,
  getWidth,
} from './helpers';
import { galleryReducer } from './reducer';
import './GalleryDnD.scss';

const GalleryDnD = memo(
  ({ defaultImages = [], onChange, changedAssets, detectChanges, setValue }: GalleryProps) => {
    const { t } = useTranslation();
    const { dispatch } = useContext(MainContext);
    const [images, setImages] = useReducer<GalleryReducer>(galleryReducer, []);
    const windowWidth = useWindowWidth();
    const [customWidth, setCustomWidth] = useState<number>(GALLERY_TWO_ITEM);
    const [galleryCss, setGalleryCss] = useState('');

    //set default images and check for missing positions in array and serialize
    useEffect(() => {
      if (images.length > 0) return;

      const copyOfDefaultImages = [...defaultImages];
      if (copyOfDefaultImages.length === 0) return;
      const imagesPositions = copyOfDefaultImages.map((item, index) =>
        item.position ? item.position : index,
      );
      for (let i = 1; i < imagesPositions.length; i++) {
        if (imagesPositions[i] - imagesPositions[i - 1] !== 1) {
          setImages(gallerySerialize(copyOfDefaultImages));
          return;
        }
      }
      setImages(gallerySet(copyOfDefaultImages));
      // eslint-disable-next-line
    }, [defaultImages]);

    // set custom width based on viewport
    useEffect(() => {
      if (windowWidth > 0) {
        if (images.length === 0) {
          customWidth !== GALLERY_ONE_ITEM && setCustomWidth(GALLERY_ONE_ITEM);
        } else {
          const newWidth = getWidth(getBreakpointValue(windowWidth));
          customWidth !== newWidth && setCustomWidth(newWidth);
        }
      }
      // eslint-disable-next-line
    }, [windowWidth, images]);

    // gallery css based on images length and custom width of items
    useEffect(() => {
      if (customWidth === GALLERY_ONE_ITEM && galleryCss.length > 0) return setGalleryCss('col1');
      if (customWidth === GALLERY_TWO_ITEM) return setGalleryCss('col2');
      if (customWidth === GALLERY_TWO_ITEM_1) return setGalleryCss('col2Justify');
      if (customWidth === GALLERY_THREE_ITEM) {
        const isFourItems = images.length % 4 === 0 ? true : false;
        const maxThreeItems = images.length === 2 ? true : false;
        return setGalleryCss(
          `col3${isFourItems ? ' fourItems' : ''}${maxThreeItems ? ' maxThreeItems' : ''}`,
        );
      }
      if (customWidth === GALLERY_FOUR_ITEM)
        return setGalleryCss(`col4${images.length === 2 ? ' maxThreeItems' : ''}`);
      // eslint-disable-next-line
    }, [customWidth, images]);

    // update parent state and value, after set images updating process
    const firstUpdate = useRef(true);
    useEffect(() => {
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return;
      }
      if (images) {
        onChange(images);
        setValue('assets', JSON.stringify(images));
        // changedAssets && detectChanges('assets');
        /*  images.length > 1 && setCustomWidth(GALLERY_TWO_ITEM);
      (images.length === 0 || images.length === 1) && setCustomWidth(GALLERY_ONE_ITEM); */
      }
      // eslint-disable-next-line
    }, [images]);

    // add or replace image
    const openModalsHandler = (
      id: number,
      size = 'sm',
      action: boolean | string = false,
      indexOfImage = 0,
    ) => {
      dispatch(
        setModal({
          id: id,
          open: true,
          returnFunction: async (data: string) => {
            const response = await axios.get(`/admin/asset/${data}`);
            if (response?.data?.id) {
              const imageObject = {
                file_name: response.data.file_name,
                url: response.data.url,
                mimetype: response.data.mimetype,
                id: response.data.id,
                position: 0,
              };
              action === 'replace'
                ? setImages(galleryReplace(imageObject, indexOfImage))
                : setImages(galleryAdd(imageObject));
            }
          },
          size: size,
          data: {},
        }),
      );
    };

    const updatePosition = useCallback(
      (dragIndex: number, hoverIndex: number) => setImages(galleryUpdate(dragIndex, hoverIndex)),
      [],
    );

    const removeImage = (indexOfImage: number) => setImages(galleryRemove(indexOfImage));

    const renderGalleryImageBox = useCallback(
      (props: GalleryImageBoxProps) => (
        <GalleryImageBox key={props.image.id + props.indexOfImage} {...props} />
      ),
      [],
    );

    return (
      <>
        <div className="flex">
          <h2 className="secondHeadline">{t('Gallery')}</h2>
          {images.length > 0 && (
            <GallerySlider setWidth={setCustomWidth} imagesLength={images.length} />
          )}
        </div>
        <div className={`sectionBox${changedAssets ? ' notPublishedOneLine' : ''}`}>
          <NotPublishedText condition={changedAssets} />
          <div className={`gallery ${galleryCss}`}>
            {images
              .sort((a, b) => a.position! - b.position!)
              .map((item, index) =>
                renderGalleryImageBox({
                  image: item,
                  indexOfImage: index,
                  removeImage: removeImage,
                  openModalsHandler: openModalsHandler,
                  update: updatePosition,
                  itemsWidth: customWidth,
                }),
              )}
            {GalleryCustomDragLayer()}
            <GalleryUploadImage openModal={openModalsHandler} />
          </div>
        </div>
      </>
    );
  },
);

export default GalleryDnD;
