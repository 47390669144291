import {
  CategoryType,
  LanguageTypes,
  MainAction,
  MainActionType,
  ToastSeverityType,
} from '@admin8/shared/types';

export const setLoginStatus = (isLoggedIn: boolean): MainAction => ({
  type: MainActionType.SET_LOGIN_STATUS,
  payload: isLoggedIn,
});

export const setIsEditing = (isEditing: boolean): MainAction => {
  return { type: MainActionType.SET_IS_EDITING, payload: isEditing };
};

export const setEditLang = (editLang: LanguageTypes): MainAction => ({
  type: MainActionType.SET_EDIT_LANG,
  payload: editLang,
});

export const setOpenNavigation = (isOpenNav: boolean): MainAction => ({
  type: MainActionType.SET_OPEN_NAV,
  payload: isOpenNav,
});

export const setModal = (settings: {
  open?: boolean;
  id?: number | null;
  data?: {} | null;
  returnFunction?: any | null;
  size?: string | null;
  imageId?: string | null;
  category?: any;
}): MainAction => ({
  type: MainActionType.SET_MODAL,
  payload: settings,
});

export const setModalCategory = (settings: {
  data?: {} | null;
  category?: CategoryType | null;
}): MainAction => ({
  type: MainActionType.SET_MODAL_CATEGORY,
  payload: settings,
});

export const setCategoryEditorCurrent = (current: null | string): MainAction => ({
  type: MainActionType.SET_CATEGORY_EDITOR_CURRENT,
  payload: current,
});

export const setChonkyFolder = (folderId: string): MainAction => ({
  type: MainActionType.SET_CHONKY_FOLDER,
  payload: folderId,
});

export const setOpenNav = (isOpen: boolean): MainAction => ({
  type: MainActionType.SET_OPEN_NAV,
  payload: isOpen,
});

export const setPermissions = (permissionsArr: string[]): MainAction => ({
  type: MainActionType.SET_PERMISSIONS,
  payload: permissionsArr,
});

export const setToast = (toastObj: {
  message: string;
  severity: ToastSeverityType;
}): MainAction => ({
  type: MainActionType.SET_TOAST,
  payload: toastObj,
});

export const setCloaseToast = (): MainAction => ({
  type: MainActionType.SET_CLOSE_TOAST,
});

export const setEditor = (editor): MainAction => ({
  type: MainActionType.SET_EDITOR,
  payload: editor,
});

export const setUserRoles = (typeId): MainAction => ({
  type: MainActionType.SET_USER_ROLES,
  payload: typeId,
});
export const setTableRef = (typeId): MainAction => ({
  type: MainActionType.SET_TABLE_REF,
  payload: typeId,
});
