import { useCallback, useState } from 'react';

const yOffset = 200;

export default function useSectionsRef() {
  const [sections, setsections] = useState<{ current: HTMLElement[] }>({ current: [] });

  const setRef = useCallback(
    (node: HTMLElement) =>
      node !== null && setsections((prevState) => ({ current: [...prevState.current, node] })),
    [],
  );

  const handleSectionSlideTo = (id: number) => {
    const mainElement = document.querySelector('.main');
    if (mainElement) {
      const currentElement = sections?.current[id];
      const y = currentElement?.getBoundingClientRect()?.top + mainElement.scrollTop - yOffset;
      sections?.current[id] && mainElement.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return { sections, setRef, handleSectionSlideTo };
}
