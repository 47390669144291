import { ChangeEvent } from 'react';

import { TextField } from '@mui/material';

import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputProps } from '@admin8/shared/types';

import NotPublishedText from '../../notPublishedText/NotPublishedText';
import '../base.scss';

export default function Input({
  defaultValue,
  name = '',
  label = '',
  hasChanged,
  error,
  control,
  isRequired = false,
  validation = {},
  inputValidation = null,
  errorMessage = '',
  disabled = false,
  type = 'text',
  css = '',
  title = '',
  onFocus = undefined,
  multiline = false,
  onBlur: customOnBlur = undefined,
  onChange: customOnChange = undefined,
  onlyDigits = false,
}: InputProps) {
  const { t } = useTranslation();
  const isNotPublishedTextEnabled = typeof hasChanged === 'boolean';
  const inputWrapperCss = css && { className: css };
  const inputCss = `input${hasChanged ? ' notPublishedOneLine' : ''}`;

  const inputOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, onChange) => {
    customOnChange && customOnChange(e);
    onChange(
      type === 'number'
        ? +e.target.value
        : onlyDigits
          ? e.target.value.replace(/[^\d.]/g, '')
          : e.target.value,
    );
  };

  const inputOnBlur = (e, onBlur) => {
    customOnBlur && customOnBlur(e);
    onBlur(e);
  };

  const inputProps = (onChange, onBlur, value, ref, title) => ({
    variant: 'outlined' as const,
    type,
    label: t(label),
    className: inputCss,
    error: !!error,
    value,
    title,
    onChange: (e) => inputOnChange(e, onChange),
    onBlur: (e) => inputOnBlur(e, onBlur),
    inputRef: ref,
    'data-testid': name,
    ...(typeof onFocus === 'function' && { onFocus }),
    ...(disabled && { inputProps: { readOnly: true, disabled: true, ...inputValidation } }),
    ...(multiline && { multiline }),
    ...(!disabled && inputValidation && { inputProps: { ...inputValidation } }),
  });

  return (
    <div {...inputWrapperCss}>
      {isNotPublishedTextEnabled && <NotPublishedText condition={hasChanged} />}

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required: isRequired, ...validation }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField {...inputProps(onChange, onBlur, value, ref, title)} />
        )}
      />

      {isRequired && error && !error?.message && <div className="error">{t(errorMessage)}</div>}
      {error && error?.message && <div className="error">{t(error.message)}</div>}
    </div>
  );
}
