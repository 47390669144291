import { ChartSliderActionType, ChartSliderContextActions } from '@admin8/shared/types';

export const setCharSliderLastYear = (lastYear: number): ChartSliderContextActions => ({
  type: ChartSliderActionType.SET_LAST_YEAR,
  payload: lastYear,
});
export const setCharSliderMonth = (month: number): ChartSliderContextActions => ({
  type: ChartSliderActionType.SET_MONTH,
  payload: month,
});
export const setCharSliderMonthTurnover = (month: number): ChartSliderContextActions => ({
  type: ChartSliderActionType.SET_MONTH_TURNOVER,
  payload: month,
});
/* export const setCharSliderDay = (day: number): ChartSliderContextActions => ({
  type: ChartSliderActionType.SET_DAY,
  payload: day,
}); */
export const setCharSliderMarks = (): ChartSliderContextActions => ({
  type: ChartSliderActionType.SET_MARKS,
});
export const setCharSliderMarksMonth = (): ChartSliderContextActions => ({
  type: ChartSliderActionType.SET_MARKS_MONTH,
});
/* export const setCharSliderMarksDay = (): ChartSliderContextActions => ({
  type: ChartSliderActionType.SET_MARKS_DAY,
}); */
export const setCharSliderSliderValue = (sliderValue: number): ChartSliderContextActions => ({
  type: ChartSliderActionType.SET_SLIDER_VALUE,
  payload: sliderValue,
});
export const setCharSliderSliderValueTurnover = (
  sliderValue: number,
): ChartSliderContextActions => ({
  type: ChartSliderActionType.SET_SLIDER_VALUE_TURNOVER,
  payload: sliderValue,
});
