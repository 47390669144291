import axios from './axios';

export default async function defaultMutationFn(query: any) {
  try {
    const response = await axios.post(``, query);
    if (response.status === 200 && typeof response?.data?.errors === 'undefined') {
      if (response?.data?.errors?.[0].message) {
        return response?.data?.errors?.[0].message;
      } else {
        return response;
      }
    } else {
      if (response?.data?.errors?.[0].message === 'Unauthenticated.') {
        return response?.data?.errors?.[0].message;
      } else {
        if (response?.data?.errors.length === 1) {
          return response?.data?.errors?.[0].message;
        } else {
          return response?.data?.errors;
        }
      }
    }
  } catch (error) {
    if (error?.message) {
      return error.message;
    } else {
      return error;
    }
  }
}
