import { useCallback, useEffect, useState } from 'react';

import { Backdrop, Fade, Modal } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { createModal } from 'react-modal-promise';

import { Icon } from '@admin8/components';

type LoseChangesModalProps = {
  isOpen: boolean;
  onResolve: (bool: boolean) => void;
  onReject: (bool: boolean) => void;
};

const LoseChangesModal = ({ isOpen, onResolve, onReject }: LoseChangesModalProps) => {
  const { t } = useTranslation();
  const [modalRef, setmodalRef] = useState<null | HTMLDivElement>(null);
  const keyHandler = (event: KeyboardEvent) => {
    const { keyCode, code } = event;
    if (keyCode === 13 || code === 'Enter') onResolve(true);
    if (keyCode === 27 || code === 'Escape') onReject(false);
  };
  const setRef = useCallback(
    (node: HTMLDivElement | null) => node !== null && setmodalRef(node),
    [],
  );

  useEffect(() => {
    if (modalRef) modalRef.addEventListener('keydown', keyHandler);
    return () => {
      if (modalRef) modalRef.removeEventListener('keydown', keyHandler);
    };
    // eslint-disable-next-line
  }, [modalRef]);

  return (
    <Modal
      open={isOpen}
      onClose={() => onReject(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      className="modal sm"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      ref={setRef}
    >
      <Fade in={isOpen}>
        <div className="modalWrapper">
          <div className="baseModal">
            <div className="top">
              <div>{t('You have unsaved changes.')}</div>
              <button className="onlyIcon" onClick={() => onReject(false)}>
                <Icon id="x-mark" width={15} height={15} />
              </button>
            </div>
            <div className="body">
              <p className="question">
                {t('Do you really want to continue and lose your changes?')}
              </p>
              <div className="flex spaceEvenly withQuestion">
                <button className="button" onClick={() => onResolve(true)}>
                  {t('Yes')}
                </button>
                <button className="button" onClick={() => onReject(false)}>
                  {t('No')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
export const promiseModal = createModal(LoseChangesModal);
