import { memo, useEffect, useRef, useState } from 'react';

import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import { GalleryImageBoxProps, SetTimeoutType } from '@admin8/shared/types';
import { imageFallback } from '@admin8/shared/utils';

import { GALLERY_THREE_ITEM } from './helpers';
import Icon from '../icon';

interface DropItem {
  imageIndex: number;
  indexOfImage: number;
  imageId: string;
  imageSrc: string;
}

const GalleryImageBox = memo(
  ({
    image,
    indexOfImage,
    removeImage,
    openModalsHandler,
    update,
    itemsWidth,
  }: GalleryImageBoxProps) => {
    const { t } = useTranslation();
    const imageRef = useRef<null | HTMLDivElement>(null);

    const [{ handlerPosition, isOver }, drop] = useDrop(() => ({
      accept: 'ImageBox',
      drop: (item: DropItem, _monitor) => update(item.imageIndex, item.indexOfImage),
      collect: (monitor) => ({
        handlerPosition: monitor.getHandlerId(),
        isOver: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
      }),
      hover(item, _monitor) {
        if (!imageRef.current) return;

        const dragIndex = item.indexOfImage;
        const hoverIndex = indexOfImage;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) return;

        /*    // Determine rectangle on screen
      const hoverBoundingRect = imageRef.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Get horizontal middle
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      // Get pixels to the left
      const hoverClientX = clientOffset!.x - hoverBoundingRect.left;

      const upwards = dragIndex > hoverIndex && hoverClientY > hoverMiddleY;
      const downwards = dragIndex < hoverIndex && hoverClientY < hoverMiddleY;
      const leftwards = dragIndex > hoverIndex && hoverClientX > hoverMiddleX;
      const rightwards = dragIndex < hoverIndex && hoverClientX < hoverMiddleX;

      if (upwards && (leftwards || rightwards)) return;
      if (downwards && (leftwards || rightwards)) return;
      if (leftwards && (downwards || upwards)) return;
      if (rightwards && (downwards || upwards)) return; */
        // Time to actually perform the action
        // update(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        // console.log('hover', item, dragIndex, hoverIndex, monitor.getHandlerId());
        item.indexOfImage = hoverIndex;
      },
    }));
    const imageId = image.id;
    const imageSrc = image.url;
    const imageIndex = image.position;

    const [{ isDragging }, drag, preview] = useDrag(() => ({
      type: 'ImageBox',
      item: () => {
        return { imageId, indexOfImage, imageSrc, imageIndex };
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
        // ids: monitor.getTargetIds(),
      }),
      end: (_item, monitor) => monitor.getDropResult(),
    }));

    drag(drop(imageRef));

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    const [displayID, setdisplayID] = useState(true);
    const [displayButtons, setdisplayButtons] = useState(false);
    const [buttonsAnimation, setbuttonsAnimation] = useState(false);

    useEffect(() => {
      let timer: SetTimeoutType;
      displayButtons && setTimeout(() => setbuttonsAnimation(true), 200);
      return () => clearTimeout(timer);
    }, [displayButtons]);

    const mouseEnterHandler = () => {
      if (itemsWidth <= GALLERY_THREE_ITEM) {
        setdisplayID(false);
        setdisplayButtons(true);
      }
    };
    const mouseLeaveHandler = () => {
      let timer: SetTimeoutType;
      if (itemsWidth <= GALLERY_THREE_ITEM) {
        setdisplayID(true);
        timer = setTimeout(() => {
          setdisplayButtons(false);
          setbuttonsAnimation(false);
        }, 200);
      }
      return () => clearTimeout(timer);
    };

    return (
      <div
        ref={imageRef}
        data-handler-position={handlerPosition}
        className={`galleryImageBox${isDragging ? ' dragged' : ''}${isOver ? ' overImage' : ''}${
          itemsWidth <= GALLERY_THREE_ITEM ? ' activateHoverScale' : ''
        }`}
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
      >
        <div className={`imageBoxOverlay${isOver ? ' animate' : ''}`}></div>
        <div className="imageBox">
          <a
            className={`imageOpener${itemsWidth <= GALLERY_THREE_ITEM ? ' disableHover' : ''}`}
            href={image?.url}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={image?.url}
              alt={image?.file_name}
              onError={imageFallback}
              className="image"
            />
          </a>
          {itemsWidth > GALLERY_THREE_ITEM && !isDragging && (
            <div className="imageBoxBottomLine">
              <button className="button" onClick={() => removeImage(indexOfImage)}>
                <Icon id="trash" width={15} height={15} /> {t('delete')}
              </button>
              <button
                className="button"
                onClick={(e) => {
                  e.preventDefault();
                  openModalsHandler(1, 'xxl', 'replace', indexOfImage);
                }}
              >
                <Icon id="arrow-uturn-forward" width={15} height={15} /> {t('replace')}
              </button>
            </div>
          )}
          {!isDragging && displayButtons && (
            <div className={`imageBoxBottomLine animated${buttonsAnimation ? ' start' : ''}`}>
              <button className="button" onClick={() => removeImage(indexOfImage)}>
                <Icon id="trash" width={15} height={15} /> {t('delete')}
              </button>
              <button
                className="button"
                onClick={() => openModalsHandler(1, 'xxl', 'replace', indexOfImage)}
              >
                <Icon id="arrow-uturn-forward" width={15} height={15} /> {t('replace')}
              </button>
            </div>
          )}
        </div>

        <div className={`helperText${displayID ? '' : ' hidden'}`}>{image?.id}</div>
      </div>
    );
  },
);

export default GalleryImageBox;
