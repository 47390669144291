import constants from '@admin8/shared/constants';
import { TableContextType } from '@admin8/shared/types';
const { RECORD_STATUS_DELETED } = constants;

export const getOrders = (
  first: number,
  page: number,
  text = { value: '', name: '' },
  filters?: TableContextType['tableContext']['customFilters']['orders'],
  sort?: any,
) => {
  return {
    query: `
    {
      orders(first: ${first}, page: ${page}, where: {
        AND:[{ column: STATUS_ID, operator: NEQ, value: ${RECORD_STATUS_DELETED} }${
          filters && filters?.currency
            ? `, { column: CURRENCY, operator: LIKE, value: "%${filters.currency}%" }`
            : ''
        }]
        ${
          text.name === 'ORDER_NUMBER' && text.value
            ? `OR: { column: ORDER_NUMBER, operator: LIKE, value: "%${text.value}%" }`
            : ''
        }
        }${
          text.name === 'NAME' && text.value
            ? `, hasAddresses: { column: NAME, operator: LIKE, value: "%${text.value}%" }`
            : ''
        }${sort ? `orderBy: { column: ${sort.fieldName}, order: ${sort.orderDirection} }` : ''}) {
        paginatorInfo { total, lastPage }
        data {
          id
          user {
            name
            email
            id
          }
          user_id
          delivery {
            id
            name
          }
          payment {
            id
            name
          }
          payment_status {
            id
          }
          payments {
            id
            payment_id
            status_id
            dttm
            pay_id
          }
          order_number
          price_total
          currency
          addresses {
            id
            order_id
            type_id
            user_address_id
            street
            name
            city
            county
            country
            postcode
            phone
          }
          created_at
        }
      }
    }    
      `,
  };
};

export const getOrder = (id: string, langId: number) => {
  return {
    query: `
    {
      order(id: "${id}") {
        id
        email
        price_base
        price_tax
        price_discount
        price_delivery
        price_payment
        price_total
        currency
        status_id
        status {
          id
          name
        }
        order_number
        variable_number
        note
        payment_status {
          id
        }
        payments {
          id
          payment_id
          status_id
          dttm
          pay_id
        }
        addresses {
          id
          order_id
          type_id
          user_address_id
          street
          name
          city
          county
          country
          country_detail {
            texts(where: { column: LANGUAGE_ID, value: ${langId} }) {
              full_name
            }
          }
          postcode
          phone
        }
        products {
          id
          name
    
          version {
            id
            texts {
              language_id
              name
            }
            cover_image {
              url
              name
            }
    
            combinations {
              pivot {
                combination {
                  id
                  actual_version {
                    name
                    texts {
                      language_id
                      name
                    }
                    cover_image {
                      name
                      url
                    }
                  }
                }
                amount
              }
            }
            product {
              name
              id
              category {
                id
                name
              }
              categories {
                id
                name
              }
            }
          }
          pivot {
            item_price
            amount
            position
          }
        }
        discounts {
          code
          name
        }
        user {
          name
          email
        }
        delivery {
          id
          name
        }
        payment {
          id
          name
        }
      }
    }
      `,
  };
};

export const removeOrders = (idsArr: string[]) => {
  return {
    query: `
    mutation($ids: [ID!]!) {
      orderDeleteMultiple(id: $ids) {
        id
      }
    }    
      `,
    variables: {
      ids: idsArr,
    },
  };
};

export const getInquiryList = ({ first, page }) => {
  return {
    query: `
    {
      inquiryList(first: ${first}, page: ${page}) {
        paginatorInfo { total, lastPage }
        data {
          current_order {
            id
            created_at
            status_id
            status {
              name
              id
            }
            delivery_id
            delivery {
              id
              name
              texts {
                language_id
                name
                description
              }
              prices {
                id
                currency
                delivery {
                  id
                }
                limit_from
                limit_to
                amount
              }
            }
            payment {
              name
              id
              texts {
                language_id
                name
                description
              }
            }
            delivery_branch_info
            delivery_branch_id
            payment_id
            price_base
            price_tax
            price_discount
            price_delivery
            price_payment
            price_total
            order_number
            variable_number
            currency
            email
            note
            addresses {
              id
              type_id
              first_name
              last_name
              name
              street
              city
              country
              postcode
              phone
            }
            products {
              name
              id
              tax_rate
              code
              version {
                cover_image {
                  url
                }
                texts {
                  name
                  slug
                  description
                }
              }
              pivot {
                amount
                item_price
                row_price
                tax_rate
                tax_price
                position
                product_variant_id
                variant {
                  name
                }
              }
            }
            discounts {
              name
              pivot {
                amount
              }
            }
          }
        }
      }
    }
  `,
  };
};

export const getOrderVersionDetail = (id: string) => {
  return {
    query: `
    {
      orderVersionDetail(current_order_id: "${id}") {
        current_order {
          id
          email
          price_base
          price_tax
          price_discount
          price_delivery
          price_payment
          price_total
          currency
          status_id
          status {
            id
            name
          }
          order_number
          variable_number
          note
          payment_status {
            id
          }
          payments {
            id
            payment_id
            status_id
            dttm
            pay_id
          }
          addresses {
            id
            order_id
            type_id
            user_address_id
            street
            name
            city
            county
            country
            country_detail {
              texts(where: { column: LANGUAGE_ID, value: 210 }) {
                full_name
              }
            }
            postcode
            phone
          }
          products {
            id
            name
    
            version {
              id
              texts {
                language_id
                name
              }
              cover_image {
                url
                name
              }
              combinations {
                pivot {
                  combination {
                    id
                    actual_version {
                      name
                      texts {
                        language_id
                        name
                      }
                      cover_image {
                        name
                        url
                      }
                    }
                  }
                  amount
                }
              }
              product {
                name
                id
                category {
                  id
                  name
                }
                categories {
                  id
                  name
                }
              }
            }
            pivot {
              item_price
              amount
              position
            }
          }
          discounts {
            code
            name
          }
          user {
            name
            email
          }
          delivery {
            id
            name
          }
          payment {
            id
            name
          }
        }
        type {
          id
          name
        }
        parent_order {
          id
          email
          price_base
          price_tax
          price_discount
          price_delivery
          price_payment
          price_total
          currency
          status_id
          status {
            id
            name
          }
          order_number
          variable_number
          note
          payment_status {
            id
          }
          payments {
            id
            payment_id
            status_id
            dttm
            pay_id
          }
          addresses {
            id
            order_id
            type_id
            user_address_id
            street
            name
            city
            county
            country
            country_detail {
              texts(where: { column: LANGUAGE_ID, value: 210 }) {
                full_name
              }
            }
            postcode
            phone
          }
          products {
            id
            name
    
            version {
              id
              texts {
                language_id
                name
              }
              cover_image {
                url
                name
              }
              combinations {
                pivot {
                  combination {
                    id
                    actual_version {
                      name
                      texts {
                        language_id
                        name
                      }
                      cover_image {
                        name
                        url
                      }
                    }
                  }
                  amount
                }
              }
              product {
                name
                id
                category {
                  id
                  name
                }
                categories {
                  id
                  name
                }
              }
            }
            pivot {
              item_price
              amount
              position
            }
          }
          discounts {
            code
            name
          }
          user {
            name
            email
          }
          delivery {
            id
            name
          }
          payment {
            id
            name
          }
        }
      }
    }    
  `,
  };
};

export const createQuote = (variables) => {
  return {
    query: `
    mutation(
      $parent_order_id: ID!
      $products: [ConnectOrderProductInput!]
      $note: String
    ) {
      orderQuoteCreate(
        input: {
          parent_order_id: $parent_order_id
          products: { connect: $products }
          note: $note
        }
      ) {
        current_order {
          id
        }
        origin_order {
          id
          products {
            id
            name
          }
        }
      }
    }
  `,
    variables,
  };
};

export const updateQuote = (variables) => {
  return {
    query: `
    mutation($id: ID!, $products: [ConnectOrderProductInput!], $note: String) {
      orderQuoteUpdate(
        id: $id
        input: { products: { connect: $products }, note: $note }
      ) {
        current_order {
          id
        }
        origin_order {
          id
          products {
            id
            name
          }
        }
      }
    }    
  `,
    variables,
  };
};

export const submitQuote = (variables) => {
  return {
    query: `
    mutation($id: ID!) {
      orderQuoteSubmit(id: $id) {
        current_order {
          id
        }
        origin_order {
          id
          products {
            id
            name
          }
        }
      }
    }      
  `,
    variables,
  };
};

export const quoteList = (variables) => {
  return {
    query: `
    query quoteList($first: Int!, $page: Int!) {
      quoteList(first: $first, page: $page) {
        paginatorInfo {
          total
          lastPage
        }
        data {
          created_at
          type {
            id
            name
          }
          current_order {
            id
            created_at
            currency
            status {
              id
              name
            }
            status_id
            delivery {
              id
              name
            }
            delivery_id
            delivery_branch_id
            payment {
              id
              name
            }
            payment_id
            payment_status {
              id
              name
            }
            payment_status_id
            price_base
            price_tax
            price_discount
            price_delivery
            price_payment
            price_total
            order_number
            email
            note
            addresses {
              id
              name
              first_name
              last_name
              street
              city
              country
              postcode
              phone
            }
            products {
              id
              name
              pivot {
                amount
                item_price
                position
              }
            }
          }
          origin_order {
            id
            status_id
            delivery_id
            delivery_branch_id
            payment_id
            payment_status_id
            price_base
            price_tax
            price_discount
            price_delivery
            price_payment
            price_total
            order_number
            email
            note
            addresses {
              id
              name
              first_name
              last_name
              street
              city
              country
              postcode
              phone
            }
            products {
              id
              name
              pivot {
                amount
                item_price
                position
              }
            }
          }
         
        }
      }
    }        
    `,
    variables,
  };
};

export const orderBooksList = (variables) => {
  return {
    query: `
    query orderBooksList($first: Int!, $page: Int) {
      orderBooksList(first: $first, page: $page) {
        paginatorInfo {
          total
          lastPage
        }
        data {
          created_at
          current_order {
            id
            status_id
            status {
              name
              id
            }
            delivery_id
            delivery {
              id
              name
              texts {
                language_id
                name
                description
              }
              prices {
                id
                currency
                delivery {
                  id
                }
                limit_from
                limit_to
                amount
              }
            }
            payment {
              name
              id
              texts {
                language_id
                name
                description
              }
            }
            delivery_branch_info
            delivery_branch_id
            payment_id
            price_base
            price_tax
            price_discount
            price_delivery
            price_payment
            price_total
            order_number
            variable_number
            currency
            email
            note
            addresses {
              id
              type_id
              first_name
              last_name
              name
              street
              city
              country
              postcode
              phone
            }
            products {
              name
              id
              tax_rate
              code
              version {
                cover_image {
                  url
                }
                texts {
                  name
                  slug
                  description
                }
              }
              pivot {
                amount
                item_price
                row_price
                tax_rate
                tax_price
                position
                product_variant_id
                variant {
                  name
                }
              }
            }
            discounts {
              name
              pivot {
                amount
              }
            }
          }
        }
      }
    }    
      `,
    variables,
  };
};
