import { DashboardContextActions, DashboardContextType } from '@admin8/shared/types';

export const DashboardReducer = (
  state: DashboardContextType['dashboardContext'],
  action: DashboardContextActions,
) => {
  switch (action.type) {
    case 'SET_ORDERS': {
      return {
        ...state,
        dataOrders: action.payload.dataOrders,
      };
    }
    default:
      return state;
  }
};
