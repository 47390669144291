import { useEffect, useState } from 'react';

export default function useWindowWidth(): number {
  const isClient = typeof window === 'object';

  const [width, setwidth] = useState<number>(0);

  const handleResize = () => setwidth(window.innerWidth);

  useEffect(() => {
    if (!isClient) return;
    setwidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  return width;
}
