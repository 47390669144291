import { useTranslation } from 'react-i18next';

import { GalleryUploadImageProps } from '@admin8/shared/types';

import Icon from '../icon';

const GalleryUploadImage = ({ openModal }: GalleryUploadImageProps) => {
  const { t } = useTranslation();
  return (
    <div className="emptyImageContainer">
      <div className="emptyImage" onClick={() => openModal(1, 'xxl')}>
        <Icon id="upload" width={15} height={15} />
        {t('Upload')}
      </div>
    </div>
  );
};

export default GalleryUploadImage;
