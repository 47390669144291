import * as ReactDOM from 'react-dom/client';

import { I18nextProvider } from 'react-i18next';

import { ThemeProvider } from '@mui/material/styles';

import i18n, { configuration, resources as defaultResources, options } from '@admin8/locale';
import { getLocalStorageItem } from '@admin8/shared/utils';
import { AppContext } from '@admin8/store';

import App from './app/app';
import { appContext, theme } from './configuration';
import translationCZ from './locales/cz/translation.json';
// import translationEN from './locales/en/translation.json';

// Languages
const languages = appContext(() => undefined).selects.languages.map((l) => l.code);

const resources = {
  ...defaultResources,
};

i18n.init({
  // we init with resources
  resources,
  detection: options,
  supportedLngs: languages,
  ...configuration,
});

getLocalStorageItem('i18nextLng')
  ? i18n.changeLanguage(getLocalStorageItem('i18nextLng'))
  : i18n.changeLanguage('cs');
i18n.addResources('cs', 'translations', translationCZ);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={appContext(i18n.t)}>
        <App />
      </AppContext.Provider>
    </ThemeProvider>
  </I18nextProvider>,
);
