import { useContext } from 'react';

import { TextField } from '@mui/material';

import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NotPublishedText } from '@admin8/components';
import { InputEmailProps } from '@admin8/shared/types';
import { MainContext } from '@admin8/store';

export default function InputEmail({
  control,
  hasChanged,
  isRequired = false,
  defaultValue,
  error,
  disabled = false,
  css = '',
}: InputEmailProps) {
  const {
    mainContext: { userLoggedIn },
  } = useContext(MainContext);

  const { t } = useTranslation();

  const inputWrapperCss = css && { className: css };
  const isNotPublishedTextEnabled = typeof hasChanged === 'boolean';

  const controllerRules = {
    required: {
      value: isRequired,
      message: userLoggedIn ? t('User must have an email') : t('Enter your email'),
    },
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: t('Invalid email address'),
    },
  };

  const inputProps = (onChange, onBlur, value, ref) => ({
    variant: 'outlined' as const,
    type: 'email' as const,
    label: t('Email'),
    className: `input${hasChanged ? ' notPublishedOneLine' : ''}`,
    error: !!error,
    value,
    onChange: (e) => onChange(e),
    onBlur: (e) => onBlur(e),
    inputRef: ref,
    'data-testid': 'email',
    ...(disabled && { inputProps: { readOnly: true, disabled: true } }),
  });

  const errorMs = error && error?.message;

  return (
    <div {...inputWrapperCss}>
      {isNotPublishedTextEnabled && <NotPublishedText condition={hasChanged} />}

      <Controller
        name="email"
        control={control}
        rules={controllerRules}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField {...inputProps(onChange, onBlur, value, ref)} />
        )}
      />

      {errorMs && <div className="error">{t(error.message)}</div>}
    </div>
  );
}
