import { ReactNode } from 'react';

import { Navigate, Outlet } from 'react-router-dom';

export default function ProtectedRoute({
  isAllowed,
  redirectPath = '/login',
  children,
}: {
  isAllowed: boolean;
  redirectPath?: string;
  children?: ReactNode;
}) {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
}
