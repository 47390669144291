import { useContext, useEffect } from 'react';

import { getLocalStorageItem } from '@admin8/shared/utils';
import { MainContext } from '@admin8/store';

export default function useShowLogoutModal() {
  const {
    mainContext: { userLoggedIn },
  } = useContext(MainContext);

  useEffect(() => {
    if (userLoggedIn && !getLocalStorageItem('permissions')) {
      document.body.classList.add('displayLogoutModal');
    }
  }, [userLoggedIn]);

  return;
}
