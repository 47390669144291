import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  createRef,
  memo,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { Icon, NotPublishedText } from '@admin8/components';
import { TermsProps, TermsReducerType } from '@admin8/shared/types';
import {
  TermsContext,
  TermsReducer,
  addTermsDate,
  checkTerms,
  createTermsSchema,
  editTerms,
  removeTermsDate,
  setAvailableTerms,
  setTermsDates,
  setTermsDefault,
  termsState,
} from '@admin8/store';

import Term from './Term';
import './Terms.scss';

const Terms = ({
  initialTerms = [],
  onChange,
  hasChangedDates,
  isArchivedDates = false,
}: TermsProps) => {
  const { t } = useTranslation();
  const [termsContext, dispatchTerms] = useReducer<TermsReducerType>(TermsReducer, termsState);
  const { terms, schema } = termsContext;
  const [loaded, setLoaded] = useState(false);
  const [focus, setFocus] = useState<boolean>(false);
  const startAtRef = createRef<HTMLInputElement>();
  const finishAtRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (initialTerms?.length && terms?.length === 0) {
      dispatchTerms(setTermsDefault(initialTerms));
      dispatchTerms(setTermsDates(initialTerms));
      setLoaded(true);
    } else if (terms?.length > 0) {
      setLoaded(true);
    }
  }, [initialTerms, terms?.length, isArchivedDates]);

  useEffect(() => {
    if (schema && terms && loaded) {
      onChange(!isArchivedDates ? 'dates' : 'archivedDates', terms, schema);
    }
  }, [schema, terms, isArchivedDates, loaded]);

  const addDates = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatchTerms(addTermsDate());
    setFocus(true);
    dispatchTerms(createTermsSchema());
  };

  const removeDates = (e: MouseEvent<HTMLButtonElement>, id: string): void => {
    e.preventDefault();
    dispatchTerms(removeTermsDate(id));
    dispatchTerms(createTermsSchema());
  };

  const editDates = (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    name: string,
    customValue = '',
  ) => {
    e.preventDefault();
    dispatchTerms(editTerms(e, id, name, customValue));
    dispatchTerms(createTermsSchema());
  };

  const checkDate = (e: KeyboardEvent<HTMLInputElement>, id: string, name: string) => {
    dispatchTerms(checkTerms(e, id, name, { startRef: startAtRef, finishRef: finishAtRef }));
    dispatchTerms(createTermsSchema());
  };

  const setAvailableHandler = (e: ChangeEvent<HTMLInputElement>, availableId: string) => {
    dispatchTerms(setAvailableTerms(availableId, e.target.value));
    dispatchTerms(createTermsSchema());
  };

  return (
    <TermsContext.Provider value={{ termsContext, dispatchTerms }}>
      {terms.length > 0 && (
        <ul className="terms flex column">
          <NotPublishedText condition={!!hasChangedDates} />
          {terms.map((item, index) => (
            <Term
              key={item.id}
              index={index}
              focus={focus}
              dateStartAt={item.start_at}
              dateFinishAt={item.finish_at}
              id={item.id}
              removeInputs={removeDates}
              editDates={editDates}
              checkDate={checkDate}
              refs={{ ref1: startAtRef, ref2: finishAtRef }}
              hasChangedDate={!!hasChangedDates}
              isArchivedDates={isArchivedDates}
              {...(!isArchivedDates && {
                available: item.available,
                setAvailable: setAvailableHandler,
              })}
            />
          ))}
        </ul>
      )}

      {!isArchivedDates && (
        <button
          className={`button filled${terms.length > 0 ? ' addTerms' : ''}`}
          onClick={addDates}
          data-testid="addTerms"
        >
          <Icon id="plus" width={15} height={15} /> {t('Add new date')}
        </button>
      )}
    </TermsContext.Provider>
  );
};

export default memo(Terms);
