import { createContext } from 'react';

import constants from '@admin8/shared/constants';
import { InitialStateType, MainContextType } from '@admin8/shared/types';
import { getLocalStorageItem } from '@admin8/shared/utils';

const { LANGUAGE_EN, USER_ROLES, USER_PROFILE } = constants;

export const initialState: InitialStateType = {
  userLoggedIn: !!getLocalStorageItem('token'),
  userProfile: JSON.parse(getLocalStorageItem(USER_PROFILE) || '{}'),
  userRoles: getLocalStorageItem(USER_ROLES) ? JSON.parse(getLocalStorageItem(USER_ROLES)) : null,
  permissions: getLocalStorageItem('permissions')
    ? JSON.parse(getLocalStorageItem('permissions'))
    : null,
  modal: {
    open: false,
    id: null,
    data: null,
    returnFunction: () => null,
    size: null,
    imageId: null,
    category: null,
    categoryEditorCurrent: null,
  },
  isOpenNav: false,
  editLang: getLocalStorageItem('editLang')
    ? parseInt(String(getLocalStorageItem('editLang')))
    : LANGUAGE_EN,
  isEditing: false,
  chonkyFolderId: null,
  toast: {
    isOpen: false,
    message: '',
    severity: undefined,
  },
  editor: null,
  tableRef: null,
};

const MainContext = createContext<MainContextType>({
  mainContext: initialState,
  dispatch: () => null,
});

export default MainContext;
