import ReactPlayer from 'react-player';

interface VideoProps {
  url: string;
  poster: string;
}

export default function Video({ url, poster }: VideoProps) {
  return (
    <div className="videoWrapper">
      <ReactPlayer
        url={url}
        className="video"
        width="100%"
        height="100%"
        controls
        muted
        loop
        key={poster}
        config={{
          file: {
            attributes: {
              poster: poster,
            },
            forceVideo: true,
          },
        }}
      ></ReactPlayer>
    </div>
  );
}
