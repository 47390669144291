import { QueryFunctionContext } from 'react-query';

import axios from './axios';

export default async function defaultQueryFn({ queryKey }: QueryFunctionContext) {
  try {
    const response = await axios.post(``, queryKey[1]);
    if (response.status === 200 && typeof response?.data?.errors === 'undefined') {
      return response;
    } else {
      if (response?.data?.errors?.[0].message === 'This action is unauthorized.') {
        throw new Error(response?.data?.errors?.[0].message);
      } else {
        if (response?.data?.errors.length === 1) {
          throw new Error(response?.data?.errors?.[0].message, { cause: response?.data?.errors });
        } else {
          throw new Error(response?.data?.errors, { cause: response?.data?.errors });
        }
      }
    }
  } catch (error: any) {
    if (typeof error === 'string') {
      throw new Error(error);
    }
    throw new Error(error?.message, { cause: error?.cause });
  }
}
