import { Input, InputAdornment } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { SearchFieldProps } from '@admin8/shared/types';

import Icon from '../icon';
import './SearchField.scss';

export default function SearchField({ onChange, children }: SearchFieldProps) {
  const { t } = useTranslation();
  return (
    <div className="searchField">
      <Input
        className="search"
        placeholder={t('Search')}
        onChange={onChange}
        startAdornment={
          <InputAdornment position="start">
            <Icon id="magnifyingglass" width={15} height={15} />
          </InputAdornment>
        }
      />
      {children}
    </div>
  );
}
