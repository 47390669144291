import { useEffect, useState } from 'react';

import Slider from '@mui/material/Slider';

import { useWindowWidth } from '@admin8/shared/hooks';
import { GallerySliderProps } from '@admin8/shared/types';

import { getMark, getWidth } from './helpers';

// marks
const marksOptions = {
  largeScreenMarks: [{ value: 25 }, { value: 50 }, { value: 75 }, { value: 100 }],
  largeScreenMarksMaxThreeItems: [{ value: 50 }, { value: 75 }, { value: 100 }],
  middleScreenMarks: [{ value: 74 }, { value: 100 }],
  almostTabletMarks: [{ value: 100 }],
  classicScreenMarks: [{ value: 50 }, { value: 75 }, { value: 100 }],
  smallerScreenMarks: [{ value: 75 }, { value: 100 }],
};

const GallerySlider = ({ setWidth, imagesLength }: GallerySliderProps) => {
  const windowWidth = useWindowWidth();
  const widthText = (value: number) => `${value}%`;
  const valueLabelFormat = (value: number) =>
    value === 100 ? 1 : value === 75 ? 2 : value === 50 ? 3 : value === 25 ? 4 : 2;
  const [marks, setMarks] = useState<{ value: number }[]>(
    marksOptions[getMark(windowWidth, imagesLength)],
  );
  const handleChange = (_event: Event, newValue: number | number[]) => setWidth(getWidth(newValue));

  useEffect(() => {
    if (windowWidth > 0) {
      const breakpoint = getMark(windowWidth, imagesLength);
      setMarks(marksOptions[breakpoint]);
    }
  }, [imagesLength, windowWidth]);

  return (
    <Slider
      getAriaValueText={widthText}
      aria-labelledby="discrete-slider"
      valueLabelDisplay="auto"
      valueLabelFormat={valueLabelFormat}
      step={null}
      marks={marks}
      min={25}
      max={100}
      key={JSON.stringify(marks)}
      defaultValue={imagesLength === 0 || marks.length === 1 ? 100 : 75}
      onChange={handleChange}
    />
  );
};

export default GallerySlider;
