import { MouseEvent, useContext, useState } from 'react';

import { Menu, MenuItem } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { Icon } from '@admin8/components';
import constants from '@admin8/shared/constants';
import { getLocalStorageItem, setLocalStorageItem } from '@admin8/shared/utils';
import { AppContext, MainContext, setEditLang, setIsEditing } from '@admin8/store';

import { promiseModal } from './LoseChangesModal';

interface SelectLanguageProps {
  isInTopBar?: boolean;
}

const { LANGUAGE_EN } = constants;

export default function SelectLanguage({ isInTopBar = false }: SelectLanguageProps) {
  const {
    mainContext: { isEditing, editLang },
    dispatch,
  } = useContext(MainContext);
  const {
    selects: { languages },
  } = useContext(AppContext);

  const { t } = useTranslation();
  const [openMenu, setopenMenu] = useState<HTMLButtonElement | null>(null);
  const [languageLabel, setlanguageLabel] = useState<string>(
    parseInt(getLocalStorageItem('editLang') ?? '') === LANGUAGE_EN ? t('English') : t('Czech'),
  );

  // return if there are only one language
  if (languages.length === 1) return null;

  const openMenuHandler = (event: MouseEvent) => {
    if (isEditing) {
      const eventTarget = event.currentTarget as HTMLButtonElement;
      promiseModal()
        .then(() => {
          setopenMenu(eventTarget);
        })
        .catch((error: Error) => {
          return error;
        });
    } else {
      setopenMenu(event.currentTarget as HTMLButtonElement);
    }
  };
  const openMenuCloseHandler = () => setopenMenu(null);

  const menuItemHandler = (e: MouseEvent<HTMLLIElement>) => {
    dispatch(setEditLang((e.target as HTMLLIElement).value));
    openMenuCloseHandler();
    setlanguageLabel((e.target as HTMLElement).textContent as string);
    setLocalStorageItem('editLang', (e.target as HTMLLIElement).value.toString());
    if (isEditing) dispatch(setIsEditing(false));
  };

  return (
    <>
      <button
        className={`button filled language${isInTopBar ? ' languageInTopBar' : ''}`}
        aria-controls={`language-menu${isInTopBar ? '-topBar' : ''}`}
        aria-haspopup="true"
        onClick={openMenuHandler}
      >
        <Icon id="character-bubble" width={15} height={15} /> {languageLabel}{' '}
        <Icon id="chevron-down" width={15} height={15} />
      </button>
      <Menu
        id={`language-menu${isInTopBar ? '-topBar' : ''}`}
        anchorEl={openMenu}
        keepMounted
        open={Boolean(openMenu)}
        onClose={openMenuCloseHandler}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {languages.map((item) => (
          <MenuItem
            className={editLang === item.value ? 'selected' : ''}
            key={item.label}
            onClick={menuItemHandler}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
