import { ChangeEvent, KeyboardEvent, MouseEvent, memo, useEffect } from 'react';

import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { TermProps, TermsButtonsProps } from '@admin8/shared/types';

import Icon from '../icon';

const TermButtons = ({ removeInput, id }: TermsButtonsProps) => {
  const removeHandler = (e: MouseEvent<HTMLButtonElement>) => removeInput(e, id);
  return (
    <div className="deleteTerm">
      <button className="onlyIcon" onClick={removeHandler}>
        <Icon id="trash" width={15} height={15} />
      </button>
    </div>
  );
};

const Term = memo<TermProps>(
  ({
    dateStartAt,
    dateFinishAt,
    id,
    removeInputs,
    index,
    focus,
    editDates,
    checkDate,
    hasChangedDate,
    isArchivedDates,
    refs,
    available,
    setAvailable,
  }) => {
    const { t } = useTranslation();

    const { ref1, ref2 } = refs;
    useEffect(() => {
      if (focus) ref1.current?.focus();
      // eslint-disable-next-line
    }, [focus]);

    const addNumIfNeeded = index === 0 ? '' : `${index}`;

    const onChangeStartHandler = (e: ChangeEvent<HTMLInputElement>) => editDates(e, id, 'start_at');

    const onKeyDownStartHandler = (e: KeyboardEvent<HTMLInputElement>) =>
      checkDate(e, id, 'start_at');

    const onChangeFinishHandler = (e: ChangeEvent<HTMLInputElement>) =>
      editDates(e, id, 'finish_at');

    const onKeyDownFinishHandler = (e: KeyboardEvent<HTMLInputElement>) =>
      checkDate(e, id, 'finish_at');

    const availableHandler = (e: ChangeEvent<HTMLInputElement>) =>
      setAvailable && setAvailable(e, id);

    return (
      <li className="flex alignCenter column">
        <div className="flex alignCenter">
          <TextField
            inputRef={ref1}
            className={`spaceRight input inputDate${hasChangedDate ? ' notPublishedOneLine' : ''}`}
            name={'startAt' + addNumIfNeeded}
            label={t('From')}
            type="datetime-local"
            defaultValue={dateStartAt}
            onChange={onChangeStartHandler}
            onKeyDown={onKeyDownStartHandler}
            {...(isArchivedDates && { disabled: true })}
            data-testid={'startAt' + addNumIfNeeded}
          />
          <TextField
            inputRef={ref2}
            className={`input inputDate${hasChangedDate ? ' notPublishedOneLine' : ''}`}
            name={'finishAt' + addNumIfNeeded}
            label={t('To')}
            type="datetime-local"
            defaultValue={dateFinishAt}
            onChange={onChangeFinishHandler}
            onKeyDown={onKeyDownFinishHandler}
            {...(isArchivedDates && { disabled: true })}
            data-testid={'finishAt' + addNumIfNeeded}
          />
          <TermButtons removeInput={removeInputs} id={id} />
        </div>

        {!isArchivedDates && (
          <FormControl className="flex available" component="fieldset">
            <p>{t('Available')}</p>
            <RadioGroup
              className="radios"
              aria-label="available"
              name="available"
              value={available ? 'true' : 'false'}
              onChange={availableHandler}
            >
              <FormControlLabel value="true" control={<Radio />} label={t('Yes')} />
              <FormControlLabel value="false" control={<Radio />} label={t('No')} />
            </RadioGroup>
          </FormControl>
        )}
      </li>
    );
  },
);

export default Term;
