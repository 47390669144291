export const GALLERY_ONE_ITEM = 100;
export const GALLERY_TWO_ITEM = 49;
export const GALLERY_TWO_ITEM_1 = 48;
export const GALLERY_THREE_ITEM = 32;
export const GALLERY_FOUR_ITEM = 23;

export const getWidth = (value: number | number[]) => {
  if (value === 100) return GALLERY_ONE_ITEM;
  if (value === 75) return GALLERY_TWO_ITEM;
  if (value === 74) return GALLERY_TWO_ITEM_1;
  if (value === 50) return GALLERY_THREE_ITEM;
  if (value === 25) return GALLERY_FOUR_ITEM;
  return GALLERY_TWO_ITEM;
};

export const getBreakpoint = (width: number) => {
  switch (true) {
    case width >= 1920:
      return 'islargeScreen';
    case width <= 1440 && width >= 1125:
      return 'isMiddleScreen';
    case width <= 1125 && width >= 1024:
      return 'isAlmostTablet';
    case width <= 580:
      return 'isSmallScreen';
    case width <= 860:
      return 'isSmallerScreen';
    case width <= 1024:
      return 'isTablet';
    default:
      return '';
  }
};

export const getBreakpointValue = (width: number) => {
  const breakpoint = getBreakpoint(width);
  switch (true) {
    case breakpoint === 'isMiddleScreen':
      return 74;
    case breakpoint === 'isAlmostTablet':
      return 100;
    case breakpoint === 'isTablet':
      return 75;
    case breakpoint === 'isSmallerScreen':
      return 75;
    case breakpoint === 'isSmallScreen':
      return 100;
    case breakpoint === 'islargeScreen':
      return 75;
    default:
      return 75;
  }
};

export const getMark = (width: number, imagesLength: number) => {
  const breakpoint = getBreakpoint(width);
  if (breakpoint === 'islargeScreen') {
    if (imagesLength === 0) return 'almostTabletMarks';
    if (imagesLength === 1) return 'middleScreenMarks';
    if (imagesLength === 2) return 'largeScreenMarksMaxThreeItems';
    if (imagesLength > 2) return 'largeScreenMarks';
  }
  if (breakpoint === 'isMiddleScreen') return 'middleScreenMarks';
  if (['isAlmostTablet', 'isSmallScreen'].includes(breakpoint)) return 'almostTabletMarks';
  if (breakpoint === 'isSmallerScreen') return 'smallerScreenMarks';
  if (imagesLength === 1) return 'middleScreenMarks';
  return 'classicScreenMarks';
};
