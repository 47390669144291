import { TextField } from '@mui/material';

import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NotPublishedText } from '@admin8/components';
import { InputSelectProps } from '@admin8/shared/types';

export default function InputSelect({
  control,
  hasChanged,
  name,
  label,
  isRequired = false,
  defaultValue,
  options,
  optionEmptyValue = false,
  error,
  errorMessage = '',
  css = '',
  isDisabled = false,
  customOptions = false,
  valueAsNumber = false,
  onChange: customOnChange = undefined,
  byLabel = false,
  children,
}: InputSelectProps) {
  const { t } = useTranslation();

  const isNotPublishedTextEnabled = typeof hasChanged === 'boolean';
  const inputWrapperCss = css && { className: css };
  const isErrorMsgEnabled = isRequired && error && errorMessage;

  const onChangeHandler = (e, onChange) => {
    customOnChange && customOnChange(e);
    onChange(valueAsNumber ? +e.target.value : e.target.value);
  };

  const inputProps = (onChange, onBlur, value, ref) => ({
    variant: 'outlined' as const,
    label: t(label),
    select: true,
    className: `input${hasChanged ? ' notPublishedOneLine' : ''}`,
    value,
    InputLabelProps: { shrink: true },
    SelectProps: { native: true },
    ...(isRequired && { error: !!error }),
    ...(isDisabled && { disabled: isDisabled }),
    'data-testid': name,
    onBlur,
    inputRef: ref,
    onChange: (e) => onChangeHandler(e, onChange),
  });

  const valueOptionEmptyValue =
    typeof optionEmptyValue === 'string' && optionEmptyValue?.length > 0;

  return (
    <div {...inputWrapperCss}>
      {isNotPublishedTextEnabled && <NotPublishedText condition={hasChanged} />}

      <Controller
        name={name}
        control={control}
        rules={{ required: isRequired }}
        {...(defaultValue && { defaultValue })}
        render={({ field: { onChange, onBlur, ref, value } }) => (
          <TextField {...inputProps(onChange, onBlur, value, ref)}>
            {optionEmptyValue && (
              <option value={valueAsNumber ? 0 : ''}>
                {valueOptionEmptyValue ? optionEmptyValue : t(`select ${name}`)}
              </option>
            )}

            {options?.map((item, index) => (
              <option
                value={byLabel ? item.label : item?.value || item.id || Number(item?.value)}
                key={index}
              >
                {item?.label || item?.value || item.name}
              </option>
            ))}

            {customOptions}
          </TextField>
        )}
      />

      {children}

      {isErrorMsgEnabled && <div className="error">{t(errorMessage)}</div>}
    </div>
  );
}
