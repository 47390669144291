import { CategoryActionType, CategoryContextActions, CategoryType } from '@admin8/shared/types';

export const setCategory = (category: null | CategoryType): CategoryContextActions => ({
  type: CategoryActionType.SET_CATEGORY,
  payload: category,
});
export const setTextsDescriptionCZ = (description: string): CategoryContextActions => ({
  type: CategoryActionType.SET_TEXTS_DESCRIPTION_CZ,
  payload: description,
});
export const setTextsDescriptionEN = (description: string): CategoryContextActions => ({
  type: CategoryActionType.SET_TEXTS_DESCRIPTION_EN,
  payload: description,
});
export const setImage = (image: null | { url: string; id: string }): CategoryContextActions => ({
  type: CategoryActionType.SET_IMAGE,
  payload: image,
});
export const setTexts = (text: { value: string; key: string }): CategoryContextActions => ({
  type: CategoryActionType.SET_TEXTS,
  payload: text,
});
export const setParentId = (parentid: string): CategoryContextActions => ({
  type: CategoryActionType.SET_PARENT_ID,
  payload: parentid,
});
