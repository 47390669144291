import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';

import { getCategorySlugs } from '@admin8/graphql';
import { DataCategorySlugExist } from '@admin8/shared/types';
import { slugify } from '@admin8/shared/utils';

const useCreateAndCheckSlugsCategory = () => {
  const [slug, setslug] = useState<string>('');

  const GET_SLUGS = getCategorySlugs(slug);
  const [slugKey, setslugKey] = useState<null | string>(null);
  const [slugExist, setslugExist] = useState<boolean>(false);
  const { data } = useQuery<DataCategorySlugExist, Error>(['slug', GET_SLUGS], {
    enabled: slug.length > 0,
  });

  const setSlug = (text: string) => setslug(slugify(text));

  useEffect(() => {
    const slugExist = data?.data?.data?.categorySlug?.exists;
    if (slugExist) return setslugExist(true);
    return setslugExist(false);
  }, [data]);

  return { slug, setSlug, slugExist, slugKey, setslugKey };
};

export default useCreateAndCheckSlugsCategory;
