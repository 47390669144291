import { Input, InputAdornment } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { SearchProps } from '@admin8/shared/types';

import Icon from '../icon';
import './Base.scss';

export default function Search({ onChange, value }: SearchProps) {
  const { t } = useTranslation();
  return (
    <Input
      className="search"
      placeholder={t('Search')}
      startAdornment={
        <InputAdornment position="start">
          <Icon id="magnifyingglass" width={15} height={15} />
        </InputAdornment>
      }
      value={value}
      onChange={onChange}
    />
  );
}
