import { useEffect, useState } from 'react';

export default function useLogoutObserver(): boolean {
  const [isDisplayingLogoutModal, setIsDisplayingLogoutModal] = useState(false);

  useEffect(() => {
    const bodyElement = document.querySelector('body');
    if (!bodyElement) return;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.target === bodyElement && mutation.type === 'attributes') {
          const hasClass = bodyElement.classList.contains('displayLogoutModal');
          setIsDisplayingLogoutModal(hasClass);
        }
      });
    });

    observer.observe(bodyElement, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return isDisplayingLogoutModal;
}
