import {
  AtomicBlockUtils,
  ContentState,
  EditorState,
  Entity,
  Modifier,
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { ToolbarImageProps } from '@admin8/shared/types';
import { setEditor } from '@admin8/store';

import ToolbarImage from '../components/ToolbarImage';
import { branchToolbar, imageOptions, noOptionsToolbar, toolbar } from '../utils/toolbars';

// Toolbar
const CustomToolbarImage = (props: ToolbarImageProps) => <ToolbarImage {...props} />;

export const getToolbar = (addImage: ((data: { url: string; name: string }) => void) | string) => {
  const pathname = window.location.pathname;
  let selectedToolbar;

  switch (true) {
    case pathname.includes('notification'):
      selectedToolbar = noOptionsToolbar;
      break;

    case pathname.includes('branch'):
      selectedToolbar = branchToolbar;
      break;

    default: {
      const toolbarImageWithComponent = { ...imageOptions };
      Object.assign(toolbarImageWithComponent.image, {
        component: () =>
          CustomToolbarImage({
            addImage,
          }),
      });
      selectedToolbar = { ...toolbar, ...toolbarImageWithComponent };
      break;
    }
  }
  return selectedToolbar;
};

// Utils Functions
export const convertFromHTML = (html: string) => {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  return contentState;
};

export const convertToHTML = (value: EditorState) =>
  draftToHtml(convertToRaw(value.getCurrentContent()));

export const emptyHtml = '<p></p>\n';

// Image

export const createAndAddImage = ({
  editor,
  dispatch,
  imgCallback,
  setImgCallback,
  onEditorStateChange,
}) => {
  const currentContent = editor.getCurrentContent();
  const selection = editor.getSelection();

  const entityKey = Entity.create('IMAGE', 'MUTABLE', {
    height: 'auto',
    src: imgCallback.url,
    width: '100%',
    alt: imgCallback.name,
  });
  const textWithEntity = Modifier.replaceText(currentContent, selection, '', null, entityKey);
  const newEditorState = EditorState.push(editor, textWithEntity, 'insert-characters');
  setImgCallback(null);
  dispatch(setEditor(null));

  onEditorStateChange(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '), true);
};
