import constants from '@admin8/shared/constants';
import { escapeString } from '@admin8/shared/utils';

const { RECORD_STATUS_NEW, RECORD_STATUS_VALID } = constants;

export const getPages = (first, page, filter, sort) => {
  return {
    query: `
    {
      pages(first: ${first}, page: ${page}, where: {
        AND: [
          {
            OR: [
            { column: STATUS_ID, value: ${RECORD_STATUS_NEW} }
            { column: STATUS_ID, value: ${RECORD_STATUS_VALID} }
          ]
        }
        ${
          filter?.name === 'TITLE' && filter?.value
            ? `{ column: TITLE, operator: LIKE, value: "%${filter?.value}%" }`
            : ''
        }
      ]
        }
        ${sort ? `orderBy: { column: ${sort.fieldName}, order: ${sort.orderDirection} }` : ''}
      ) {
        paginatorInfo {
          total
          lastPage
        }
        data {
          id
          type {
            id
            name
          }
          status {
            id
            name
          }
          language {
            id
            name
          }
          author {
            id
            name
          }
          name
          title
          slug
          position
          blocks {
            id
            class_name
            position
            components {
              id
              type {
                id
                name
              }
              position
              data {
                key
                value
              }
            }
          }
        }
      }
    }    
     `,
  };
};

export const removePages = (idsArr: string[]) => {
  return {
    query: `
    mutation($ids: [ID!]!) {
      pageDeleteMultiple(id: $ids) {
        id
      }
    }    
      `,
    variables: {
      ids: idsArr,
    },
  };
};

export const getPage = (id) => {
  return {
    query: `
    {
      page(id: "${id}") {
        id
        name
        title
        slug
        status {
          id
        }
        language {
          id
        }
        description
        position
        image {
          id
          name
          url
        }
        seo_title
        seo_description
        seo_keywords
        og_title
        og_type
        og_url
        og_image
        blocks {
          id
          class_name
          position
          components {
            id
            type {
              id
              name
            }
            position
            data {
              key
              value
            }
          }
        }
        token
      }
    }
    `,
  };
};

export const createPage = ({ data, lang, image, blocks }) => {
  return {
    query: `
        mutation${data.blocks.length > 0 ? `($blocks: [UpsertPageBlockInput!]!)` : ''} {
            pageCreate(
              input: {
                type_id: 1810
                language_id: ${lang}
                name: "${escapeString(data.title)}"
                ${data.title ? `title: "${escapeString(data.title)}"` : ''}
                slug: "${escapeString(data.slug)}"
                ${data.status ? `status_id: ${data.status}` : ''}
                ${typeof data.position === 'number' ? `position: ${data.position}` : ''}
                ${data.description ? `description: """${escapeString(data.description)}"""` : ''}
                ${image?.id ? `image_id: "${image?.id}"` : ''}
                ${data.blocks.length > 0 ? `blocks: { upsert: $blocks }` : ''}
                ${data.seoTitle ? `seo_title: "${escapeString(data.seoTitle)}"` : ''}
                ${
                  data.seoDescription
                    ? `seo_description: "${escapeString(data.seoDescription)}"`
                    : ''
                }
                ${data.seoKeywords ? `seo_keywords: "${escapeString(data.seoKeywords)}"` : ''} 
                ${data.og_title ? `og_title: "${escapeString(data.og_title)}"` : ''}
                ${data.og_type ? `og_type: "${escapeString(data.og_type)}"` : ''}
                ${data.og_url ? `og_url: "${escapeString(data.og_url)}"` : ''}
                ${data.og_image ? `og_image: "${escapeString(data.og_image)}"` : ''}
              }
            ) {
              id
              name
              ${data.title ? `title` : ''}
              slug
            }
          }
        `.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, ''),
    ...(blocks.length > 0 && {
      variables: {
        blocks,
      },
    }),
  };
};

export const updatePage = ({ id, data, lang, image, blocks }) => {
  return {
    query: `
        mutation($blocks: UpsertPageBlockHasMany!) {
            pageUpdate(
              input: {
                id: "${id}"
                language_id: ${lang}
                name: "${escapeString(data.title)}"
                ${data.title ? `title: "${escapeString(data.title)}"` : ''}
                slug: "${escapeString(data.slug)}"
                ${data.status ? `status_id: ${data.status}` : ''}
                ${typeof data.position === 'number' ? `position: ${data.position}` : ''}
                ${data.description ? `description: """${escapeString(data.description)}"""` : ''}
                ${image?.id ? `image_id: "${image?.id}"` : ''}
                ${data.blocks ? `blocks: $blocks` : ''}
                ${data.seoTitle ? `seo_title: "${escapeString(data.seoTitle)}"` : 'seo_title: null'}
                
                ${
                  data.seoKeywords
                    ? `seo_keywords: "${escapeString(data.seoKeywords)}"`
                    : 'seo_keywords: null'
                } 
                ${data.og_title ? `og_title: "${escapeString(data.og_title)}"` : 'og_title: null'}
                ${data.og_type ? `og_type: "${escapeString(data.og_type)}"` : 'og_type: null'}
                ${data.og_url ? `og_url: "${escapeString(data.og_url)}"` : 'og_url: null'}
                ${data.og_image ? `og_image: "${escapeString(data.og_image)}"` : 'og_image: null'}
              }
            ) {
              id
              name
              ${data.title ? `title` : ''}
              slug
            }
          }
        `.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, ''),
    ...(blocks && {
      variables: {
        blocks,
      },
    }),
  };
};

export const hidePages = (idsArr: string[]) => ({
  query: `
    mutation {
      ${idsArr.map(
        (item, index) => `page${index}: pageUpdate(
          input: {
          id: "${item}"
          status_id: ${RECORD_STATUS_NEW}
      }) {
        status {
          id
        }
        id
      }`,
      )}
    }
      `,
});

export const displayPages = (idsArr: string[]) => {
  return {
    query: `
    mutation {
      ${idsArr.map(
        (item, index) => `page${index}: pageUpdate(
          input: {
          id: "${item}"
          status_id: ${RECORD_STATUS_VALID}
      }) {
        status {
          id
        }
        id
      }`,
      )}
    }
      `,
  };
};
