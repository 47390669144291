export const logIn = (email: string, password: string) => ({
  query: `mutation {
    login(input: { email: "${email}", password: "${password}" }) {
      access_token
      expires_in
      token_type
      refresh_token
      user {
        type_id
        roles {
          id
        }
      }
    }
  }`,
});

export const userPermissions = () => ({
  query: `{ userPermissions { name } }`,
});
