import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  Reducer,
  RefObject,
} from 'react';

import { EditorState } from 'draft-js';

import { FieldError } from 'react-hook-form';

import constants from '@admin8/shared/constants';

import { FormValuesProductSpecificType } from './appSpecific';
import {
  FormDefaultsBillingEditType,
  FormDefaultsEmployeeType,
  FormDefaultsServiceType,
} from './billing';
import { FormDefaultsBranchType } from './branch';
import { CategoryType, FormDefaultsCategoryType, TextsType } from './category';
import { ConsultantsList, ConsultantsType, FormDefaultsConsultantType } from './consultant';
import { CountriesType, CoutriesListType, FormDefaultsCountryType } from './country';
import { CustomersListType, CustomersType } from './customer';
import { DiscountList, DiscountsType, FormValuesDicountType } from './discount';
import { EventDatesType, EventsListType, EventsType, FormValuesEventType } from './event';
import { ResponseDataDataAnd } from './helpers';
import { FormValuesNotificationType, NotificationsList, NotificationsType } from './notification';
import { OrderListType, OrdersType } from './order';
import { FormDefaultsPage } from './page';
import { FormValuesPostType, PostsLists, PostsType } from './post';
import {
  FormValuesProductType,
  ProductListsType,
  ProductsObjectType,
  ProductsType,
} from './product';
import { ProductList, SalesGetProductsType } from './sales';
import { FormDefaultsProfileType, FormDefaultsUserType, UsersListType, UsersType } from './user';
import { FormDefaultsPackageType, FormDefaultsVoucherType } from './vouchers';

export * from './components/Gallery';
export * from './components/Variants';
export * from './components/Values';
export * from './components/Category';
export * from './components/Product';
export * from './sales';
export * from './discount';
export * from './order';
export * from './customer';
export * from './post';
export * from './consultant';
export * from './event';
export * from './user';
export * from './category';
export * from './product';
export * from './notification';
export * from './country';
export * from './appSpecific';
export * from './props';
export * from './helpers';
export * from './branch';
export * from './page';
export * from './vouchers';
export * from './billing';

const {
  LANGUAGE_EN,
  LANGUAGE_CZ,
  RECORD_STATUS_NEW,
  RECORD_STATUS_VALID,
  RECORD_STATUS_DELETED,
  PAYMENT_CASH_ON_DELIVERY,
  PAYMENT_ONLINE,
  PAYMENT_CASH,
} = constants;

// Commons
export type Lookup = {
  id: number;
  parent_id: number | null;
  name: string;
};

export type QueryLookups = ResponseDataDataAnd<'lookups', Lookup[]>;

// Context

export type ToastSeverityType = 'success' | 'info' | 'warning' | 'error' | undefined;

export enum ModalId {
  FILE_MANAGER = 1,
  ADD_FIELD = 2,
  COMBINATIONS_SEARCH = 3,
  CATEGORY = 4,
  REMOVE_CATEGORY = 5,
  REMOVE_FROM_LISTS = 6,
  BEFORE_MOVE_CATEGORY = 7,
  DELETE_IMAGE_ERROR = 8,
  SET_HIDDEN = 9,
  SET_VISIBLE = 10,
  PAGES_MODAL = 20,
}

export type InitialStateType = {
  userLoggedIn: boolean;
  userProfile: {
    name: string;
    employee_id?: string;
    id: number;
  };
  permissions: string[] | null;
  userRoles: any;
  modal: {
    open: boolean | undefined;
    id: null | ModalId;
    data: null | any | { [key: string]: string };
    returnFunction: null | any | undefined;
    size: null | string | undefined;
    imageId: null | string | undefined;
    category: any;
    categoryEditorCurrent: null | string;
    constraintImages?: any;
  };
  isOpenNav: boolean;
  editLang: number;
  isEditing: boolean;
  chonkyFolderId: null | string;
  toast: {
    isOpen: boolean;
    message: string;
    severity: ToastSeverityType;
  };
  editor: EditorState | null;
  tableRef: null | any;
};

export type AppStateType = {
  // Table background
  theme: {
    white: string;
    secondaryLight: string;
  };
  selects: {
    languages: { label: string; value: number; code: string }[];
    currencies: { value: string }[];
    taxRates: { value: number; label: string }[];
    addressTypes: { label: string; value: number }[];
    discountTypes: { label: string; value: number }[];
    eventDifficulty: { label: string; value: string }[];
    eventTypes: { label: string; value: number }[];
    postTypes: { label: string; value: number }[];
    tags: { label: string }[];
    units: { label: string; value: string }[];
    eventLocationAddress: { label: string }[];
    orderCurrencySelect: { label: string }[];
  };
  enabledPages: string[];
  chonkyIconFolder: boolean;
  website: string;
};

export type AppContextType = {
  state: Partial<AppStateType>;
};

export enum MainActionType {
  SET_LOGIN_STATUS = 'SET_LOGIN_STATUS',
  SET_PERMISSIONS = 'SET_PERMISSIONS',
  SET_MODAL = 'SET_MODAL',
  SET_MODAL_CATEGORY = 'SET_MODAL_CATEGORY',
  SET_CATEGORY_EDITOR_CURRENT = 'SET_CATEGORY_EDITOR_CURRENT',
  SET_OPEN_NAV = 'SET_OPEN_NAV',
  SET_EDIT_LANG = 'SET_EDIT_LANG',
  SET_IS_EDITING = 'SET_IS_EDITING',
  SET_CHONKY_FOLDER = 'SET_CHONKY_FOLDER',
  SET_TOAST = 'SET_TOAST',
  SET_CLOSE_TOAST = 'SET_CLOSE_TOAST',
  SET_EDITOR = 'SET_EDITOR',
  SET_USER_TYPE = 'SET_USER_TYPE',
  SET_USER_ROLES = 'SET_USER_ROLES',
  SET_TABLE_REF = 'SET_TABLE_REF',
  SET_USER_PROFILE = 'SET_USER_PROFILE',
}

interface setEditor {
  type: MainActionType.SET_EDITOR;
  payload: EditorState | null;
}

interface setLoginStatus {
  type: MainActionType.SET_LOGIN_STATUS;
  payload: boolean;
}

interface setPermissions {
  type: MainActionType.SET_PERMISSIONS;
  payload: string[];
}

interface setUserProfile {
  type: MainActionType.SET_USER_PROFILE;
  payload: {
    name: string;
    id: number;
    employee_id?: string;
  };
}

interface setModal {
  type: MainActionType.SET_MODAL;
  payload: {
    open?: boolean;
    id?: null | number;
    data?: null | {};
    returnFunction?: null | any;
    size?: null | string;
    imageId?: null | string;
    category?: null | CategoryType | undefined;
  };
}

interface setModalCategory {
  type: MainActionType.SET_MODAL_CATEGORY;
  payload: {
    data?: null | {};
    category?: null | CategoryType | undefined;
  };
}
interface setModalCategoryEditorCurrent {
  type: MainActionType.SET_CATEGORY_EDITOR_CURRENT;
  payload: null | string;
}

interface setOpenNav {
  type: MainActionType.SET_OPEN_NAV;
  payload: boolean;
}

interface setEditLang {
  type: MainActionType.SET_EDIT_LANG;
  payload: number;
}

interface setIsEditing {
  type: MainActionType.SET_IS_EDITING;
  payload: boolean;
}

export interface setChonkyFolder {
  type: MainActionType.SET_CHONKY_FOLDER;
  payload: string;
}

interface setToast {
  type: MainActionType.SET_TOAST;
  payload: {
    message: string;
    severity: ToastSeverityType;
  };
}
interface setCloaseToast {
  type: MainActionType.SET_CLOSE_TOAST;
}

export interface setUserType {
  type: MainActionType.SET_USER_TYPE;
  payload: number;
}

export interface setUserRoles {
  type: MainActionType.SET_USER_ROLES;
  payload: number;
}

export interface setTableRef {
  type: MainActionType.SET_TABLE_REF;
  payload: any;
}

export type MainAction =
  | setLoginStatus
  | setPermissions
  | setModal
  | setOpenNav
  | setEditLang
  | setIsEditing
  | setChonkyFolder
  | setToast
  | setCloaseToast
  | setModalCategory
  | setModalCategoryEditorCurrent
  | setUserType
  | setEditor
  | setUserRoles
  | setTableRef
  | setUserProfile;

export type MainContextType = {
  mainContext: InitialStateType;
  dispatch: Dispatch<MainAction>;
};

// Category context

export enum CategoryActionType {
  SET_CATEGORY = 'SET_CATEGORY',
  SET_TEXTS_DESCRIPTION_CZ = 'SET_TEXTS_DESCRIPTION_CZ',
  SET_TEXTS_DESCRIPTION_EN = 'SET_TEXTS_DESCRIPTION_EN',
  SET_IMAGE = 'SET_IMAGE',
  SET_TEXTS = 'SET_TEXTS',
  SET_PARENT_ID = 'SET_PARENT_ID',
}

interface CategoryContextSetCategory {
  type: CategoryActionType.SET_CATEGORY;
  payload: null | CategoryType;
}
interface CategoryContextSetDescriptionCz {
  type: CategoryActionType.SET_TEXTS_DESCRIPTION_CZ;
  payload: string;
}
interface CategoryContextSetDescriptionEn {
  type: CategoryActionType.SET_TEXTS_DESCRIPTION_EN;
  payload: string;
}
interface CategoryContextSetImage {
  type: CategoryActionType.SET_IMAGE;
  payload: null | { url: string; id: string };
}
interface CategoryContextTexts {
  type: CategoryActionType.SET_TEXTS;
  payload: { value: string; key: string };
}
interface CategoryContextParentId {
  type: CategoryActionType.SET_PARENT_ID;
  payload: string;
}

export type CategoryContextActions =
  | CategoryContextSetCategory
  | CategoryContextSetDescriptionCz
  | CategoryContextSetDescriptionEn
  | CategoryContextSetImage
  | CategoryContextTexts
  | CategoryContextParentId;

export type CategoryContextType = {
  categoryContext: {
    category: null | CategoryType;
    parent_id: string;
    texts: TextsType;
    image: null | { url: string; id: string };
  };
  dispatchCategory: Dispatch<CategoryContextActions>;
};

export type CategoryReducerType = Reducer<
  CategoryContextType['categoryContext'],
  CategoryContextActions
>;

// Dashboard context

export enum DashboardActionType {
  SET_ORDERS = 'SET_ORDERS',
}

interface DashboardSetOrders {
  type: DashboardActionType.SET_ORDERS;
  payload: {
    dataOrders: { [key: number]: { [key: number]: OrdersType } };
  };
}

export type DashboardContextActions = DashboardSetOrders;

export type DashboardContextType = {
  dashboardContext: {
    dataOrders: { [key: number]: { [key: number]: OrdersType | any } };
  };
  dispatchDashboard: Dispatch<DashboardContextActions>;
};

export type DashboardReducerType = Reducer<
  DashboardContextType['dashboardContext'],
  DashboardContextActions
>;

// Dashboard ChartSlider context

export enum ChartSliderActionType {
  SET_LAST_YEAR = 'SET_LAST_YEAR',
  SET_MONTH = 'SET_MONTH',
  SET_MONTH_TURNOVER = 'SET_MONTH_TURNOVER',
  // SET_DAY = 'SET_DAY',
  SET_MARKS = 'SET_MARKS',
  SET_MARKS_MONTH = 'SET_MARKS_MONTH',
  // SET_MARKS_DAY = 'SET_MARKS_DAY',
  SET_SLIDER_VALUE = 'SET_SLIDER_VALUE',
  SET_SLIDER_VALUE_TURNOVER = 'SET_SLIDER_VALUE_TURNOVER',
}

interface ChartSliderSetMarks {
  type: ChartSliderActionType.SET_MARKS;
}
interface ChartSliderSetMarksMonth {
  type: ChartSliderActionType.SET_MARKS_MONTH;
}
/* interface ChartSliderSetMarksDay {
  type: ChartSliderActionType.SET_MARKS_DAY;
} */
interface ChartSliderSetLastYear {
  type: ChartSliderActionType.SET_LAST_YEAR;
  payload: number;
}
interface ChartSliderSetMonth {
  type: ChartSliderActionType.SET_MONTH;
  payload: number;
}
interface ChartSliderSetMonthTurnover {
  type: ChartSliderActionType.SET_MONTH_TURNOVER;
  payload: number;
}
/* interface ChartSliderSetDay {
  type: ChartSliderActionType.SET_DAY;
  payload: number;
} */
interface ChartSliderSetSliderValue {
  type: ChartSliderActionType.SET_SLIDER_VALUE;
  payload: number;
}

interface ChartSliderSetSliderValueTurnover {
  type: ChartSliderActionType.SET_SLIDER_VALUE_TURNOVER;
  payload: number;
}

export type ChartSliderContextActions =
  | ChartSliderSetMarks
  | ChartSliderSetMarksMonth
  // | ChartSliderSetMarksDay
  | ChartSliderSetLastYear
  | ChartSliderSetMonth
  | ChartSliderSetMonthTurnover
  // | ChartSliderSetDay
  | ChartSliderSetSliderValue
  | ChartSliderSetSliderValueTurnover;

export type ChartSliderContextType = {
  chartSliderContext: {
    lastYear: number;
    month: number;
    monthTurnover: number;
    // day: number;
    marks: any[];
    marksMonth: any[];
    // marksDay: any[];
    sliderValue: number;
    sliderValueTurnover: number;
  };
  dispatchChartSlider: Dispatch<ChartSliderContextActions>;
};

export type ChartSliderReducerType = Reducer<
  ChartSliderContextType['chartSliderContext'],
  ChartSliderContextActions
>;

// Table context

export type PagesType =
  | 'products'
  | 'consultants'
  | 'posts'
  | 'events'
  | 'countries'
  | 'notifications'
  | 'discounts'
  | 'sales'
  | 'orders'
  | 'users'
  | 'customers';

export enum TableActionType {
  SET_TABLE_FILTERS = 'SET_TABLE_FILTERS',
  SET_TABLE_FILTERS_PRODUCTS = 'SET_TABLE_FILTERS_PRODUCTS',
  SET_TABLE_FILTERS_EVENTS_ACTIVE = 'SET_TABLE_FILTERS_EVENTS_ACTIVE',
  SET_TABLE_FILTERS_EVENTS_TYPE = 'SET_TABLE_FILTERS_EVENTS_TYPE',
  SET_TABLE_FILTERS_EVENTS_DIFFICULTY = 'SET_TABLE_FILTERS_EVENTS_DIFFICULTY',
  SET_TABLE_FILTERS_EVENTS_LOCATION = 'SET_TABLE_FILTERS_EVENTS_LOCATION',
  SET_TABLE_FILTERS_POSTS_LANGUAGE = 'SET_TABLE_FILTERS_POSTS_LANGUAGE',
  SET_TABLE_FILTERS_POSTS_TYPE = 'SET_TABLE_FILTERS_POSTS_TYPE',
  SET_TABLE_FILTERS_COUNTRIES_STATUS = 'SET_TABLE_FILTERS_COUNTRIES_STATUS',
  SET_TABLE_FILTERS_ORDER_CURRENCY = 'SET_TABLE_FILTERS_ORDER_CURRENCY',
}

interface setTableFiltersProducts {
  type: TableActionType.SET_TABLE_FILTERS_PRODUCTS;
  payload: {
    id: string;
    isParentCategory: boolean;
    name: string;
  };
}
interface setTableFiltersEventsActive {
  type: TableActionType.SET_TABLE_FILTERS_EVENTS_ACTIVE;
  payload: string;
}
interface setTableFiltersEventsType {
  type: TableActionType.SET_TABLE_FILTERS_EVENTS_TYPE;
  payload: string;
}
interface setTableFiltersEventsDifficulty {
  type: TableActionType.SET_TABLE_FILTERS_EVENTS_DIFFICULTY;
  payload: string;
}
interface setTableFiltersEventsLocation {
  type: TableActionType.SET_TABLE_FILTERS_EVENTS_LOCATION;
  payload: string;
}
interface setTableFiltersPostsLanguage {
  type: TableActionType.SET_TABLE_FILTERS_POSTS_LANGUAGE;
  payload: number | string;
}
interface setTableFiltersPostsType {
  type: TableActionType.SET_TABLE_FILTERS_POSTS_TYPE;
  payload: number | string;
}
interface setTableFiltersCountriesStatus {
  type: TableActionType.SET_TABLE_FILTERS_COUNTRIES_STATUS;
  payload: string | boolean;
}
interface setTableFiltersOrderCurrency {
  type: TableActionType.SET_TABLE_FILTERS_ORDER_CURRENCY;
  payload: string;
}

export type TableContextActions =
  | setTableFiltersProducts
  | setTableFiltersEventsActive
  | setTableFiltersEventsType
  | setTableFiltersEventsDifficulty
  | setTableFiltersEventsLocation
  | setTableFiltersPostsLanguage
  | setTableFiltersPostsType
  | setTableFiltersCountriesStatus
  | setTableFiltersOrderCurrency;

export type TableContextType = {
  tableContext: {
    customFilters: {
      [key: string]: any;
      products: {
        category: {
          id: string;
          isParentCategory: boolean;
          name: string;
        };
      };
      posts: {
        language: number | string;
        type: number | string;
      };
      events: {
        active: string;
        type: string;
        difficulty: string;
        location: string;
      };
      countries: {
        status: string | boolean;
      };
      orders: {
        currency: string;
      };
    };
  };
  dispatchTable: Dispatch<TableContextActions>;
};

export type TableReducerType = Reducer<TableContextType['tableContext'], any>;

// Terms context

export enum TermsActionType {
  SET_DEFAULT = 'SET_DEFAULT',
  SET_DATES = 'SET_DATES',
  ADD_DATES = 'ADD_DATES',
  REMOVE_DATES = 'REMOVE_DATES',
  SET_AVAILABLE = 'SET_AVAILABLE',
  EDIT_DATES = 'EDIT_DATES',
  CHECK_DATES = 'CHECK_DATES',
  CREATE_SCHEMA = 'CREATE_SCHEMA',
}

interface TermsSetDefault {
  type: TermsActionType.SET_DEFAULT;
  payload: EventDatesType;
}
interface TermsSetDates {
  type: TermsActionType.SET_DATES;
  payload: EventDatesType;
}
interface TermsAddDates {
  type: TermsActionType.ADD_DATES;
}
interface TermsRemoveDates {
  type: TermsActionType.REMOVE_DATES;
  payload: string;
}
interface TermsSetAvailable {
  type: TermsActionType.SET_AVAILABLE;
  payload: { id: string; value: string };
}
interface TermsEditDates {
  type: TermsActionType.EDIT_DATES;
  payload: { event: ChangeEvent<HTMLInputElement>; id: string; name: string; customValue: string };
}
interface TermsCheckDates {
  type: TermsActionType.CHECK_DATES;
  payload: {
    event: KeyboardEvent<HTMLInputElement>;
    id: string;
    name: string;
    refs: { startRef: RefObject<HTMLInputElement>; finishRef: RefObject<HTMLInputElement> };
  };
}
interface TermsCreateSchema {
  type: TermsActionType.CREATE_SCHEMA;
}

export type TermsContextActions =
  | TermsSetDefault
  | TermsSetDates
  | TermsAddDates
  | TermsRemoveDates
  | TermsSetAvailable
  | TermsEditDates
  | TermsCheckDates
  | TermsCreateSchema;

export type TermsContextType = {
  termsContext: {
    defaultTerms: EventDatesType;
    terms: EventDatesType;
    deleted: string[];
    schema: SchemaObjectForDatesType;
  };
  dispatchTerms: Dispatch<TermsContextActions>;
};

export type TermsReducerType = Reducer<TermsContextType['termsContext'], TermsContextActions>;

// Custom types

export type SetTimeoutType = ReturnType<typeof setTimeout>;

export type RefsCollection = {
  current: HTMLInputElement[];
};
export type RefsCollectionDiv = {
  current: HTMLDivElement[];
};

export type GetValuesType = (name: string | number) => string | number;

export type DispatchType = (action: MainAction) => void;

export enum LanguageTypes {
  language_en = LANGUAGE_EN,
  language_cz = LANGUAGE_CZ,
}

export enum StatusTypes {
  new = RECORD_STATUS_NEW,
  valid = RECORD_STATUS_VALID,
  deleted = RECORD_STATUS_DELETED,
}

export enum PaymentTypes {
  delivery = PAYMENT_CASH_ON_DELIVERY,
  online = PAYMENT_ONLINE,
  cash = PAYMENT_CASH,
}

export type indexedObject = {
  [key: string]: string;
};

export type IndexedObjectNumber = {
  [key: string]: number;
};

export type MouseEventModalHandlerType = MouseEvent<HTMLButtonElement | HTMLDivElement>;

export type SchemaObjectForDatesType = {
  create?: { name?: string; start_at?: string; finish_at?: string; available: boolean }[];
  update?: { name?: string; start_at?: string; finish_at?: string; available: boolean }[];
  delete?: string[];
};

export type newTreeArr = {
  name: string;
  element: ReactElement;
}[];

export interface HierarchizedTree {
  title: string;
  id: string;
  is_visible: boolean;
  texts_cz: {
    title: string;
    slug: string;
    description: string;
  };
  texts_en: {
    title: string;
    slug: string;
    description: string;
  };
  cover_image: {
    id: string;
    name: string;
    url: string;
    file_path: string;
    file_name: string;
  } | null;
  parent_id: string | null;
  children: HierarchizedTree[];
  expanded: boolean;
  isDisabled?: boolean;
}

export type FormDefaults =
  | FormDefaultsConsultantType
  | FormValuesDicountType
  | FormValuesEventType
  | FormValuesNotificationType
  | FormValuesPostType
  | FormDefaultsUserType
  | FormDefaultsProfileType
  | FormValuesProductSpecificType
  | FormValuesProductType
  | FormDefaultsCategoryType
  | FormDefaultsCountryType
  | FormDefaultsBranchType
  | FormDefaultsVoucherType
  | FormDefaultsPackageType
  | FormDefaultsEmployeeType
  | FormDefaultsServiceType
  | FormDefaultsBillingEditType
  | FormValuesBillingType
  | FormDefaultsPage;

export type PageFiltersType = {
  [key: string]: string[];
};

// TopBar
export type ProductSubmitFunctionType = (data: FormDefaults, opt?: string, verID?: string) => void;
export type SubmitFunctionType = (data: FormDefaults) => void;

// Wysiwyg editor
export type DynamicWysiwygName = `valueTextValue${number}`;
export type DynamicUIWysiwygName = `blocks.${number}.${string}`;
export type DynamicVariantsWysiwygName = `variants[${number}].texts.description`;
export type DynamicValuesWysiwygName = `values[${number}].value`;
export type Wysiwygs =
  | 'introduction'
  | 'description'
  | 'footnote'
  | 'annotation'
  | 'content'
  | 'description_en'
  | 'description_cz'
  | DynamicWysiwygName
  | DynamicUIWysiwygName
  | DynamicVariantsWysiwygName
  | DynamicValuesWysiwygName;

// React query
export type GraphQueryType = {
  query: string;
};

export type GraphQueryError = {
  errors: string[];
};

// Form types
export type FormValuesLogIn = {
  email: string;
  password: string;
};

// Table
export type TableDataTypes =
  | UsersListType
  | ProductListsType
  | ProductList
  | PostsLists
  | OrderListType
  | NotificationsList
  | EventsListType
  | DiscountList
  | CustomersListType
  | ConsultantsList
  | CoutriesListType;

export type TableDataRowsTypes = TableDataTypes & { tableData: { id: number; checked: boolean } };

export type TablePageTypes =
  | 'events'
  | 'posts'
  | 'consultants'
  | 'products'
  | 'users'
  | 'sales'
  | 'discounts'
  | 'orders'
  | 'customers'
  | 'notifications'
  | 'countries';

export type updatedtableData = {
  id: string;
  section: string;
  tableData: {
    id: number;
  };
};
export type deletetableData = {
  id: string;
  section: string;
  tableData: {
    editing: undefined;
    id: number;
  };
};

export interface PageDataType {
  products: ProductsType;
  consultants: ConsultantsType;
  posts: PostsType;
  events: EventsType;
  countries: CountriesType;
  notifications: NotificationsType;
  discounts: DiscountsType;
  sales: SalesGetProductsType;
  orders: OrdersType;
  users: UsersType;
  customers: CustomersType;
  branches: any;
  pages: any;
}

// GraphQL types

// File Upload
export interface fileUploadQueryType {
  query: string;
}

export interface fileUploadType {
  maxUpladFileSize: {
    size: number;
  };
}

// Asset Category
export interface AssetCategoryType {
  created_at: string;
  deleted_at: null | string;
  id: string;
  name: string;
  parent_id: string | null;
  updated_at: string;
}

export type DataAssetCategory = {
  data: AssetCategoryType[];
};

export interface AssetResponseType {
  fileMap: {
    [key: string]: {
      id: string;
      modDate: string;
      name: string;
      parentId: string | null;
      size: null;
      thumbnailUrl: string;
    };
  }[];
  rootFolderId: string;
}

export interface GraphQLErrorType {
  extensions: { validation: { [key: string]: string[] }; category: string };
  locations: { line: number; column: number }[];
  message: string;
  path: string[];
  trace: unknown[];
}

export interface GraphQLPageSchemasObjType {
  [key: string]: { query: string; variables?: { ids: string[] } };
}

export interface QueryObjectType {
  query: string;
}

export interface QueryObjectWithVariablesType {
  query: string;
  variables?: { ids: string[] };
}

// Saloon
export type FormValuesProductsType = {
  product_id: string;
  internal_price?: number;
  client_price: number;
  quantity: number;
};

export type FormValuesServicesType = {
  service_id: string;
  internal_price: number;
  client_price: number;
};

export type PostBillingType = {
  client_id: string;
  employee_id: string;
  beverage_amount: number;
  beverage_note: string;
  is_overtime: boolean;
  is_missing_work: boolean;
  is_future_work: boolean;
  products: FormValuesProductsType[];
  services: FormValuesServicesType[];
  issued: string;
  booking_event_id?: string;
};

export type PostServiceType = {
  name: string;
  duration: number;
  type_id: number;
  prices: ServicePriceType[];
};

export interface IService {
  serviceName: string;
  serviceInternalPrice: string;
  serviceClientPrice: string;
}

export interface IProduct {
  productClientPrice: string;
  productInternalPrice: string;
  productName: string;
  productQuantity: string;
}

export interface Vouchers {
  code: string;
  amount: number;
  voucher_id: string;
  id: string;
  billing_id: string;
}

export interface FormValuesBillingType {
  event_id?: string;
  event_date: string;
  booking_id?: string;
  booking_event_id?: string;
  beverage_amount: number | string;
  beverage_note: string;
  client_id: string;
  client_name?: string;
  service_package_id?: string;
  employee_id: string;
  is_overtime: boolean;
  payment_type?: string;
  payed_amount?: string;
  order_note?: string;
  start?: string;
  end?: string;
  employee_name?: string;
  employee_desc?: string;
  experience_level_id?: string;
  note?: string;
  state?: string;
  services: IService[];
  products: IProduct[];
  vouchers: Vouchers[];
}

export type ServicePriceType = {
  experience_level_id: string;
  price: number;
  service_id?: string;
  type_id?: number;
};

export type ServiceLelelPricesType = {
  3310: number;
  3320: number;
  3330: number;
  experience_level_id: string;
};

export type FormValuesServiceType = {
  name: string;
  duration: number;
  serviceType: number;
  eyebrowColorPrice?: number;
  eyebrowHennaPrice?: number;
  levels: ServiceLelelPricesType[];
};

export interface AutocompleteOptions {
  value: string;
  label: string;
}

export interface AutocompleteProps {
  options: AutocompleteOptions[];
  name: string;
  css?: string;
  label: string;
  defaultValue?: AutocompleteOptions | undefined;
  hasChanged: boolean;
  isRequired?: boolean;
  error?: undefined | FieldError | any;
  errorMessage?: string;
  onAddNewItem?: any;
  onChange?: any;
  onInput?: any;
}

export type PackagesServiceType = {
  id: string;
  name: string;
  duration: number;
  prices?: ServicePriceType;
};
export type FormValuesClient = {
  email: string;
  lastname: string;
  name: string;
  phone: string;
};

export type FormValuesCancelBilling = {
  note: string;
};

export type FormValuesBillingClosures = {
  note: string;
  amount: number;
};
