import { useEffect, useState } from 'react';

import { getLocalStorageItem, removeLocalStorageItem } from '@admin8/shared/utils';

export default function useBackButtonURL(defaultPath: string): string {
  const [backButtonURL, setbackButtonURL] = useState(defaultPath);

  useEffect(() => {
    const pathname = getLocalStorageItem('pathname');

    if (pathname) setbackButtonURL(pathname);

    return () => removeLocalStorageItem('pathname');
  }, []);

  return backButtonURL;
}
