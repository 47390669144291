import constants from '@admin8/shared/constants';
import { FormDefaultsProfileType, FormDefaultsUserType } from '@admin8/shared/types';
const { USER_TYPE_ADMINISTRATOR } = constants;

export const getAdministrators = (
  first: number,
  page: number,
  text = { value: '', name: '' },
  sort?: any,
) => {
  return {
    query: `
    {
      users(first: ${first}, page: ${page}, where: {
        AND: [
          { column: TYPE_ID, value: ${USER_TYPE_ADMINISTRATOR} }
          ${
            text.name === 'NAME' && text.value
              ? `{ column: NAME, operator: LIKE, value: "%${text.value}%" }`
              : ''
          }
          ${
            text.name === 'EMAIL' && text.value
              ? `{ column: EMAIL, operator: LIKE, value: "%${text.value}%" }`
              : ''
          }
        ]
      }
      ${sort ? `orderBy: { column: ${sort.fieldName}, order: ${sort.orderDirection} }` : ''}) {
        paginatorInfo { total, lastPage }
        data {
          id
          name
          email
          roles {
            name
            id
          }
        }
      }
    }    
    `,
  };
};

export const getUser = (id: string) => {
  return {
    query: `
    {
      user(id: "${id}") {
        id
        name
        email
        roles {
          id
          name
          guard_name
        }
      }
    }    
    `,
  };
};

export const getUserByEmail = (email: string) => {
  return {
    query: `
    {
      user(email: "${email}") {
        id
        name
      }
    }    
    `,
  };
};

export const getUserRoles = () => {
  return {
    query: `
    {
      roles {
        id
        name
        permissions {
          id
        }
      }
    }     
    `,
  };
};

export const getUserPermissions = () => {
  return {
    query: `
    {
      permissions {
        id
        name
      }
    }    
    `,
  };
};

export const updateUserRole = (id: string, roles: string) => {
  return {
    query: `mutation {
    roleUpdate(id: ${id}, input: { permissions: { sync: [${roles}] }}) { id name permissions { id name }}
  }`,
  };
};

export const createUserPermission = (arr: { section: string }[]) => {
  return {
    query: `mutation {
      ${arr.map(
        (
          item,
          index,
        ) => `permissionCreate${index}: permissionCreate(input: { name: "${item.section}", guard_name: "web" }) {
          id
          name
          guard_name
          created_at
        }`,
      )}
    }
    `,
  };
};

export const updateUserPermission = (arr: { id: string; section: string }[]) => {
  return {
    query: `mutation {
      ${arr.map(
        (
          item,
          index,
        ) => `permissionUpdate${index}:  permissionUpdate(id: ${item.id}, input: { name: "${item.section}", guard_name: "web" }) {
          id
          name
          guard_name
          created_at
        }`,
      )}
    }
    `,
  };
};

export const deleteUserPermission = (arr: { id: string }[]) => {
  return {
    query: `mutation {
      ${arr.map(
        (item, index) => `permissionDelete${index}:  permissionDelete(id: ${item.id}) {
          id
          name
        }`,
      )}
    }
    `,
  };
};

export const createUser = (data: FormDefaultsUserType) => {
  return {
    query: `
    mutation {
      userCreate(input: {
        name: "${data.name}"
        email: "${data.email}"
        password: "${data.userPassword}"
        roles: {
          sync: [${data.role}]
        } 
      }) {
        id
        name
        email
        roles {
          id
          name
        }
      }
    }
    `,
  };
};

export const updateUser = (id: string, data: FormDefaultsUserType) => {
  return {
    query: `
    mutation {
      userUpdate(id: ${id}, input: {
        name: "${data.name}"
        email: "${data.email}"
        ${data.userPassword ? `password: "${data.userPassword}"` : ''}
        roles: {
          sync: [${data.role}]
        } 
      }) {
        id
        name
        email
      }
    }
    `,
  };
};

export const updateUserToAdministrator = (
  id: string | undefined,
  role: FormDefaultsUserType['role'],
) => {
  return {
    query: `
    mutation {
      userUpdate(id: ${id}, input: {
        type_id: ${USER_TYPE_ADMINISTRATOR}
        roles: {
          sync: [${role}]
        } 
      }) {
        id
        name
        email
      }
    }
    `,
  };
};

export const updateProfile = (id: string, data: FormDefaultsProfileType) => {
  return {
    query: `
    mutation {
      userUpdate(id: ${id}, input: {
        name: "${data.name}"
        email: "${data.email}"
        ${data.userPassword ? `password: "${data.userPassword}"` : ''}
      }) {
        id
        name
        email
      }
    }
    `,
  };
};

export const deleteUser = (idsArr: string[]) => {
  return {
    query: `
    mutation {
      ${idsArr.map(
        (item, index) => `user${index}: userDelete(id: ${item}) {
        id
      }`,
      )}
    }
    `,
  };
};

export const profile = () => {
  return {
    query: `{
      profile {
        id
        name
        email
        type_id
      }
    }
    `,
  };
};
