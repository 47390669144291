import { useEffect, useState } from 'react';

import { useWindowWidth } from '@admin8/shared/hooks';

interface UseSectionHighlighter {
  current: HTMLElement[];
}

export default function useSectionHighlighter(sections: UseSectionHighlighter): number {
  const [currentSection, setcurrentSection] = useState<number>(0);
  const width: number = useWindowWidth();
  const [treshold, settreshold] = useState(0.75);
  const isConsultantOrPost =
    window.location.href.includes('consultant') || window.location.href.includes('post');

  useEffect(() => {
    const options = {
      rootMargin: '0px 0px 0px 0px',
      threshold:
        width >= 1920
          ? treshold
          : isConsultantOrPost
          ? treshold
          : width > 1700
          ? 0.4
          : width > 1440
          ? 0.5
          : 0.2,
    };
    const callback = (observerObject: IntersectionObserverEntry[]) => {
      observerObject.forEach((entry) => {
        if (entry.isIntersecting) {
          const foundSec = sections.current.find((item) => item === entry.target);
          const hasIndex = foundSec && sections.current.indexOf(foundSec);
          if (hasIndex || hasIndex === 0) setcurrentSection(hasIndex);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    if (sections.current.length > 0) sections.current.forEach((sec) => observer?.observe(sec));

    if (isConsultantOrPost) settreshold(width > 1700 ? 0.4 : 0.2);
    if (window.location.href.includes('admin8')) settreshold(0.2);

    return () => observer?.disconnect();
    // eslint-disable-next-line
  }, [sections]);

  return currentSection;
}
