import { QueryFunctionContext } from 'react-query';

import axios from './axios';

export default async function defaultInfiniteQueryFn(query: QueryFunctionContext) {
  try {
    const response = await axios.post(``, query);
    if (response.status === 200 && typeof response?.data?.errors === 'undefined') {
      return response;
    } else {
      if (response?.data?.errors?.[0].message === 'This action is unauthorized.') {
        throw response?.data?.errors?.[0].message;
      } else {
        if (response?.data?.errors.length === 1) {
          throw response?.data?.errors?.[0].message;
        } else {
          throw response?.data?.errors;
        }
      }
    }
  } catch (error: unknown) {
    if (typeof error === 'string') {
      throw new Error(error);
    }

    throw new Error('error try again');
  }
}
