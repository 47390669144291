import { useCallback, useEffect, useState } from 'react';

import { Backdrop, Fade, Modal } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { Icon } from '@admin8/components';

type LoseChangesRouteProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const LoseChangesRoute = ({ isOpen, onCancel, onConfirm }: LoseChangesRouteProps) => {
  const { t } = useTranslation();
  const [modalRef, setmodalRef] = useState<null | HTMLDivElement>(null);
  const keyHandler = (event: KeyboardEvent) => {
    const { keyCode, code } = event;
    if (keyCode === 13 || code === 'Enter') onConfirm();
    if (keyCode === 27 || code === 'Escape') onCancel();
  };
  const setRef = useCallback(
    (node: HTMLDivElement | null) => node !== null && setmodalRef(node),
    [],
  );

  useEffect(() => {
    if (modalRef) modalRef.addEventListener('keydown', keyHandler);
    return () => {
      if (modalRef) modalRef.removeEventListener('keydown', keyHandler);
    };
    // eslint-disable-next-line
  }, [modalRef]);

  return (
    <Modal
      open={isOpen}
      onClose={onCancel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      className="modal sm"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="loseChangesRouteModal"
      ref={setRef}
    >
      <Fade in={isOpen}>
        <div className="modalWrapper">
          <div className="baseModal">
            <div className="top">
              <div>{t('You have unsaved changes.')}</div>
              <button className="onlyIcon" onClick={onCancel}>
                <Icon id="x-mark" width={15} height={15} />
              </button>
            </div>
            <div className="body">
              <p className="question">{t('Do you really want to leave and lose your changes?')}</p>
              <div className="withQuestion flex spaceEvenly">
                <button className="button" onClick={onConfirm}>
                  {t('Yes')}
                </button>
                <button className="button" onClick={onCancel}>
                  {t('No')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
export default LoseChangesRoute;
