import { useEffect, useState } from 'react';

import { XYCoord, useDragDropManager, useDragLayer } from 'react-dnd';

const getDragLayerStyles = (initialOffset: XYCoord | null, currentOffset: XYCoord | null) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
};

const GalleryCustomDragLayer = () => {
  const manager = useDragDropManager();

  const { isDragging, item, initialOffset, currentOffset, ids } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    ids: (() => {
      const _monitor = manager.getMonitor();
      const targetIds = _monitor.getTargetIds() || [];
      return targetIds;
    })(),
  }));

  const [isOver, setIsOver] = useState(false);

  useEffect(() => {
    if (ids.length > 0 && item?.imageIndex !== undefined) {
      const indexDnD = ids[0].toString();
      const index = parseInt(indexDnD?.replace('T', ''));
      if (item.imageIndex !== index) return setIsOver(true);
      return setIsOver(false);
    }
  }, [item, ids, currentOffset]);

  if (!isDragging) return null;

  return (
    <div className="draglayer">
      <div style={getDragLayerStyles(initialOffset, currentOffset)}>
        <div className={`dragitem${isOver ? ' animate' : ''}`}>
          <img src={item.imageSrc} alt={item.imageSrc} />
        </div>
      </div>
    </div>
  );
};

export default GalleryCustomDragLayer;
