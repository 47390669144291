import { useContext, useEffect } from 'react';

import { useBlocker } from 'react-router-dom';

import { MainContext, setIsEditing } from '@admin8/store';

export default function useBlocking() {
  const {
    mainContext: { isEditing },
    dispatch,
  } = useContext(MainContext);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isEditing && currentLocation.pathname !== nextLocation.pathname,
  );

  const isBlocked = blocker.state === 'blocked';

  const cancelNavigationHandler = () => {
    blocker.reset();
  };

  const confirmNavigationHandler = () => {
    dispatch(setIsEditing(false));
    blocker.proceed();
  };

  useEffect(() => {
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (isEditing) {
        const e = event || window.event;
        e.preventDefault();
        if (e) e.returnValue = '';
        return '';
      }
      return null;
    };
  }, [isEditing]);

  return {
    isBlocked,
    cancelNavigationHandler,
    confirmNavigationHandler,
  };
}
