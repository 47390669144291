import { forwardRef, useCallback, useContext, useEffect, useState } from 'react';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';

import { MainContext, setCloaseToast } from '@admin8/store';
import './Toast.scss';

const SlideTransition = (props: SlideProps) => (
  <Slide {...props} direction="down" className="slide" />
);

const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export default function Toast() {
  const [isToastOpen, setIsToastOpen] = useState(false);
  const {
    mainContext: {
      toast: { isOpen, message, severity },
    },
    dispatch,
  } = useContext(MainContext);

  const handleClose = useCallback(() => {
    setTimeout(() => {
      setIsToastOpen(false);
      dispatch(setCloaseToast());
    }, 6000);
  }, [dispatch]);

  useEffect(() => {
    if (isOpen && !isToastOpen) {
      setIsToastOpen(true);
      handleClose();
    }
  }, [isOpen, isToastOpen, handleClose]);

  return (
    <Snackbar
      open={isToastOpen}
      TransitionComponent={SlideTransition}
      message={message}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
