import dayjs from 'dayjs';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { t } from 'i18next';

import constants from '@admin8/shared/constants';
import { PaymentTypes } from '@admin8/shared/types';

import { dates } from './date';
import { setLocalStorageItem } from './localStorage';

const {
  RECORD_STATUS_DELETED,
  RECORD_STATUS_VALID,
  RECORD_STATUS_NEW,
  ORDER_PAYMENT_INITIATED,
  ORDER_PAYMENT_CANCELED,
  ORDER_PAYMENT_CONFIRMED,
  ORDER_PAYMENT_REJECTED,
  ORDER_PAYMENT_EXPIRED,
} = constants;

export const imageTypes = ['jpg', 'jpeg', 'gif', 'png', 'webp'];

export const stripTags = (text: string) => text?.replace(/<\/?[^>]+(>|$)/g, '');
export const serializeForWysiwyg = (text: string) =>
  text?.replace(/(?:\\[rn]|[\r\n]+)+/g, '')?.replace(/&nbsp;/g, ' ');

export const convertFromHTML = (html: string) => {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  return contentState;
};

export const convertToHTML = (value: EditorState) =>
  draftToHtml(convertToRaw(value.getCurrentContent()));

export const convertStringToHTML = (text: string) =>
  convertToHTML(EditorState.createWithContent(convertFromHTML(text)));

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const isValidHttpUrl = (url: string) => {
  try {
    const newUrl = new URL(url);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch (err) {
    return false;
  }
};

export const getOrderStatus = (paymentID: PaymentTypes, payments: any): string => {
  const getStatus = (id: number) => {
    switch (id) {
      case ORDER_PAYMENT_INITIATED:
        return t('In process');
      case ORDER_PAYMENT_CANCELED:
        return t('Canceled');
      case ORDER_PAYMENT_CONFIRMED:
        return t('Paid');
      case ORDER_PAYMENT_REJECTED:
        return t('Denied');
      case ORDER_PAYMENT_EXPIRED:
        return t('Expired');
      default:
        return t('Payment error');
    }
  };

  if (paymentID === PaymentTypes.delivery) return t('Cash');

  if (paymentID === PaymentTypes.online) {
    if (payments.length === 0) return t('Payment error');
    if (payments.length === 1) return getStatus(payments[0].status_id);
    if (payments.length > 1) {
      let status: string;
      payments.find((item: any, _index: number, original: any) =>
        item.status_id === ORDER_PAYMENT_CONFIRMED
          ? (status = t('Paid'))
          : (status = getStatus(original[payments.length - 1].status_id)),
      );
      return status!;
    }
  }

  if (paymentID === PaymentTypes.cash) return t('Cash');

  return '';
};

export const getDiscountStatus = (discount: any) =>
  discount
    ? dayjs(discount.valid_from).isAfter(dates.NOW) || dayjs(discount.valid_to).isAfter(dates.NOW)
      ? RECORD_STATUS_VALID
      : RECORD_STATUS_NEW
    : RECORD_STATUS_DELETED;

export const setPathnameHome = () => setLocalStorageItem('pathname', '/');

export const debounce = (callback: any, wait: number) => {
  let timeoutId: number;
  return (...args: any[]) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

export const mapAssets = (assetArr: any[]) =>
  assetArr.map((item, index) => ({
    id: item.id,
    url: item.url,
    file_name: item.file_name,
    mimetype: item.mimetype,
    position: item.pivot.position,
  }));

export const findTextsByLanguage = (texts: any[], language: number) =>
  texts.find(({ language_id }) => language_id === language);

export const sortByPosition = (arr: any[]) => arr.sort((a, b) => a.position - b.position);

export const getSortOrder = ({ orderDirection, orderBy }) => {
  const direction = orderDirection;
  const sortBy = orderBy?.orderByField || orderBy?.field;

  const sort = !!orderDirection && {
    orderDirection: direction.toUpperCase(),
    fieldName: sortBy.toUpperCase(),
  };

  return sort;
};

// Helpers
export const stringify = (value) => JSON.stringify(value);
export const parse = (value) => JSON.parse(value);
export const isObject = (variable) => typeof variable === 'object' && variable !== null;
export const isObjectDefined = (object) => Object.keys(object).length > 0;
export const isArray = (variable) => Array.isArray(variable);
export const isFunction = (variable) => typeof variable === 'function';
export const onKeysPress = ({ key, preventDefault }, callback) => {
  const keys = ['Enter', ' '];
  if (keys.includes(key)) {
    preventDefault();
    callback();
  }
};

export const roundNumberToTwoDecimals = (num: number) => Number(num.toFixed(2));
