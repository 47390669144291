import constants from '@admin8/shared/constants';
import { FormValuesPostType, TableContextType } from '@admin8/shared/types';
import { escapeString, parseDate } from '@admin8/shared/utils';

const { RECORD_STATUS_NEW, RECORD_STATUS_VALID } = constants;

export const getPosts = (
  first: number,
  page: number,
  text = { name: '', value: '' },
  filters?: TableContextType['tableContext']['customFilters']['posts'],
  sort?: any,
) => {
  return {
    query: `
    {
      posts(
        first: ${first}
        page: ${page}
        where: {
          AND: [
            ${
              filters && filters?.language
                ? `{ OR: { column: LANGUAGE_ID, value: ${filters.language} } }`
                : ''
            }
            ${filters && filters?.type ? `{ OR: { column: TYPE_ID, value: ${filters.type} } }` : ''}
            ${
              text.name === 'TITLE'
                ? `{ OR: [{ column: TITLE, operator: LIKE, value: "%${text.value}%" }] }`
                : ''
            }
            {
              OR: [
                { column: STATUS_ID, value: ${RECORD_STATUS_NEW} }
                { column: STATUS_ID, value: ${RECORD_STATUS_VALID} }
              ]
            }
          ]
        }
        ${sort ? `orderBy: { column: ${sort.fieldName}, order: ${sort.orderDirection} }` : ''}
      ) {
        paginatorInfo { total, lastPage }
        data {
          id
          type {
            id
            name
          }
          language_id
          title
          slug
          source
          published
          status {
            name
            id
          }
          is_visible
          updated_at
          created_at
        }
      }
    } 
  `,
  };
};

export const getPost = (id: string) => {
  return {
    query: `
    {
      post(id: "${id}") {
        id
        language_id
        status {
          id
        }
        type {
          id
          name
        }
        cover_image {
          file_name
          mimetype
          id
          url
        }
        title
        slug
        source
        published
        content
        annotation
        assets {
          id
          url
          file_name
          mimetype
          pivot { 
            position
           }
        }
      }
    }    
        `,
  };
};

export const updatePost = (
  id: string,
  data: FormValuesPostType,
  imgIDs: { id: string; position: number }[],
  type: number,
  language: number,
) => {
  return {
    query: `
    mutation${imgIDs.length > 0 ? `($assets: [ConnectPostAssetInput!])` : ''} {
        postUpdate(
          id: "${id}"
          input: {
            id: "${id}"
            status_id: 120
            language_id: ${language}
            title: "${escapeString(data.title)}"
            slug: "${escapeString(data.slug)}"
            type_id: ${type}
            annotation: ${JSON.stringify(data.annotation)}
            published: "${escapeString(parseDate(data.published))}"
            content: ${JSON.stringify(data.content)}
            ${imgIDs.length > 0 ? `assets: { sync: $assets }` : ''}
          }
        ) {
          status { id }
          id
          title
          slug
          language_id
          annotation
          published
          content
          ${imgIDs.length > 0 ? `assets { id }` : ''}
        }
      }
        `,
    ...(imgIDs.length > 0 && {
      variables: {
        assets: imgIDs,
      },
    }),
  };
};

export const createPost = (
  data: FormValuesPostType,
  imgIDs: { id: string; position: number }[],
  type: number,
  language: number,
) => {
  return {
    query: `
    mutation${imgIDs.length > 0 ? `($assets: [ConnectPostAssetInput!])` : ''} {
        postCreate( input: {
          status_id: 120
          author_id: 1
          ${language ? `language_id: ${language}` : ''}
          title: "${escapeString(data.title)}"
          slug: "${escapeString(data.slug)}"
          type_id: ${type}
          ${data.annotation ? `annotation: ${JSON.stringify(data.annotation)}` : ''}
          ${data.published ? `published: "${escapeString(parseDate(data.published))}"` : ''}
          ${data.content ? `content: ${JSON.stringify(data.content)}` : ''}
          ${imgIDs.length > 0 ? `assets: { sync: $assets }` : ''}
        }) {
          status { id }
          id
          title
          slug
          ${language ? `language_id` : ''}
          ${data.annotation ? `annotation` : ''}
          ${data.published ? `published` : ''}
          ${data.content ? `content` : ''}
          ${imgIDs.length > 0 ? `assets { id }` : ''}
        }
      }
        `,
    ...(imgIDs.length > 0 && {
      variables: {
        assets: imgIDs,
      },
    }),
  };
};

export const removePosts = (idsArr: string[]) => {
  return {
    query: `
    mutation($ids: [ID!]!) {
      postDeleteMultiple(id: $ids) {
        id
      }
    }    
      `,
    variables: {
      ids: idsArr,
    },
  };
};

export const hidePosts = (idsArr: string[]) => {
  return {
    query: `
    mutation {
      ${idsArr.map(
        (item, index) => `post${index}: postUpdate(
        id: "${item}"
        input: {
          id: "${item}"
          is_visible: false
      }) {
        is_visible
        id
      }`,
      )}
    }
      `,
  };
};

export const displayPosts = (idsArr: string[]) => {
  return {
    query: `
    mutation {
      ${idsArr.map(
        (item, index) => `post${index}: postUpdate(
        id: "${item}"
        input: {
          id: "${item}"
          is_visible: true
      }) {
        is_visible
        id
      }`,
      )}
    }
      `,
  };
};
