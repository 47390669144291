import { createTheme } from '@mui/material/styles';

import constants from '@admin8/shared/constants';

import colors from './sass/colors.module.scss';
import './sass/saloon.scss';

// Material UI colors
export const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    error: {
      main: colors.alert,
    },
  },
});

// App context setup
export const appContext = (t) => ({
  // Table background
  theme: {
    white: colors.white,
    secondaryLight: colors.secondaryLight,
  },
  selects: {
    languages: [
      { label: t('Czech'), value: constants.LANGUAGE_CZ, code: 'cs' },
      { label: t('English'), value: constants.LANGUAGE_EN, code: 'en' },
    ],
    currencies: [{ value: constants.CURRENCY_EUR }],
    taxRates: [
      { value: constants.TAX_RATE_0, label: t('Without VAT') },
      { value: constants.TAX_RATE_10, label: constants.TAX_RATE_10.toString() },
      { value: constants.TAX_RATE_15, label: constants.TAX_RATE_15.toString() },
      { value: constants.TAX_RATE_21, label: constants.TAX_RATE_21.toString() },
    ],
    addressTypes: [
      { label: t('Invoice'), value: constants.ADDRESS_TYPE_INVOICE },
      { label: t('Deliverys'), value: constants.ADDRESS_TYPE_DELIVERY },
    ],
    discountTypes: [
      { label: t('AmountType'), value: constants.DISCOUNT_TYPE_AMOUNT },
      { label: t('Percentage'), value: constants.DISCOUNT_TYPE_PERCENTAGE },
    ],
    eventDifficulty: [
      { label: t('Z1 - Basic level'), value: constants.EVENT_DIFFICULTY_Z1 },
      { label: t('Z2 - Basic level'), value: constants.EVENT_DIFFICULTY_Z2 },
      { label: t('Z3 - Basic level'), value: constants.EVENT_DIFFICULTY_Z3 },
      { label: t('S1 - Intermediate level'), value: constants.EVENT_DIFFICULTY_S1 },
      { label: t('S2 - Intermediate level'), value: constants.EVENT_DIFFICULTY_S2 },
      { label: t('S3 - Intermediate level'), value: constants.EVENT_DIFFICULTY_S3 },
      { label: t('S4 - Intermediate level'), value: constants.EVENT_DIFFICULTY_S4 },
      { label: t('SV - Intermediate and high level'), value: constants.EVENT_DIFFICULTY_SV },
      { label: t('V - High level'), value: constants.EVENT_DIFFICULTY_V },
    ],
    eventTypes: [
      { label: t('Seminars'), value: constants.EVENT_TYPE_SEMINARS },
      { label: t('Conference days'), value: constants.EVENT_TYPE_CONFERENCE_DAYS },
      { label: t('Public lectures'), value: constants.EVENT_TYPE_PUBLIC_LECTURES },
      { label: t('Summer school'), value: constants.EVENT_TYPE_SUMMER_SCHOOL },
      { label: t('Other events'), value: constants.EVENT_TYPE_OTHER_EVENTS },
    ],
    postTypes: [
      { label: t('Propagation'), value: constants.POST_TYPE_1 },
      { label: t('General'), value: constants.POST_TYPE_2 },
    ],
    tags: [
      { label: t('treatment/drops') },
      { label: t('oils') },
      { label: t('syrups') },
      { label: t('alcohol drops') },
      { label: t('capsule') },
      { label: t('tablets') },
      { label: t('vitamins') },
    ],
    units: [
      { label: t('Centimeters'), value: 'cm' },
      { label: t('Milimeters'), value: 'mm' },
      { label: t('Meters'), value: 'm' },
      { label: t('Liter'), value: 'l' },
      { label: t('Kilogram'), value: 'kg' },
      { label: t('Gramme'), value: 'g' },
    ],
    eventLocationAddress: [{ label: 'Praha' }, { label: 'Brno' }, { label: 'Ostrava' }],
    orderCurrencySelect: [{ label: 'EUR' }],
  },
  enabledPages: [
    'agenda',
    'dashboard',
    'users',
    'resources',
    'services',
    'service',
    'billings',
    'products',
    'settings',
    'help',
    'pages',
    'reports',
    'employee-reports',
    'vouchers',
  ],
  chonkyIconFolder: false,
  website: 'https://www.spell.cz/',
});
