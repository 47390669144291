import { useTranslation } from 'react-i18next';

import { SectionBoxProps } from '@admin8/shared/types';
import './SectionBox.scss';

export default function SectionBox({
  headlineText,
  setSectionRef,
  children,
  css = 'section sectionBox',
}: SectionBoxProps) {
  const { t } = useTranslation();
  return (
    <>
      {headlineText && <h2 className="secondHeadline">{t(headlineText)}</h2>}
      <section className={css} {...(setSectionRef && { ref: setSectionRef })}>
        {children}
      </section>
    </>
  );
}
