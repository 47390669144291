import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon, NotPublishedText } from '@admin8/components';
import { CategoryImageProps, FormDefaultsCategoryType } from '@admin8/shared/types';
import './MainImage.scss';

const MainImageCategory = ({
  image,
  hasChanged,
  deleteHandler,
  replaceOrAddHandler,
}: CategoryImageProps) => {
  const { t } = useTranslation();
  const { register } = useFormContext<Pick<FormDefaultsCategoryType, 'image'>>();

  return (
    <div className="spaceBottom fullWidth">
      <div className="info grey">{t('Category image')}</div>
      <input
        {...register('image')}
        type="hidden"
        value={image ? JSON.stringify(image) : ''}
        readOnly={true}
      />
      {image ? (
        <div className={`imageBox fullWidth${hasChanged ? ' notPublishedOneLine' : ''}`}>
          <NotPublishedText condition={hasChanged} css="withWysiwyg" />
          <img src={image?.url} alt="categoryImage" className="categoryImage" />
          <div className="flex">
            <button className="button m0" onClick={deleteHandler}>
              <Icon id="trash" width={15} height={15} /> {t('delete')}
            </button>
            <button className="button m0" onClick={replaceOrAddHandler}>
              <Icon id="arrow-uturn-forward" width={15} height={15} /> {t('replace')}
            </button>
          </div>
        </div>
      ) : (
        <>
          <NotPublishedText condition={hasChanged} css="withWysiwyg" />
          <div
            className={`emptyImage${hasChanged ? ' notPublishedOneLine' : ''}`}
            onClick={replaceOrAddHandler}
            tabIndex={0}
          >
            <Icon id="upload" width={15} height={15} />
            {t('upload')}
          </div>
        </>
      )}
    </div>
  );
};

export default MainImageCategory;
