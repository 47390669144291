import { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import './NotFound.scss';

import { NotFoundProps } from '@admin8/shared/types';
import { getLocalStorageItem, removeStoredData } from '@admin8/shared/utils';
import { MainContext, setLoginStatus } from '@admin8/store';

export default function NotFound({ owner, link }: NotFoundProps) {
  const { t } = useTranslation();
  const {
    mainContext: { userLoggedIn },
    dispatch,
  } = useContext(MainContext);
  const navigate = useNavigate();
  const backButton = () => navigate(link);

  useEffect(() => {
    if (userLoggedIn && !getLocalStorageItem('permissions')) {
      dispatch(setLoginStatus(false));
      removeStoredData();
      navigate('/login', { replace: true });
    }
  }, [userLoggedIn, dispatch, navigate]);

  return (
    <div className="notFoundDetail">
      <p className="notFoundNumber">404</p>
      <h2>
        {`${owner} `}
        {t('does not exist')}
      </h2>
      <button className="button" onClick={backButton}>
        {t('Back to') + ' ' + t(`${owner}s`)}
      </button>
    </div>
  );
}
