import { useTranslation } from 'react-i18next';

import { Icon } from '@admin8/components';

interface BaseModalProps {
  title: string;
  onSubmit: any;
  onClose: any;
}

export default function BaseModal({ title, onSubmit, onClose }: BaseModalProps) {
  const { t } = useTranslation();
  return (
    <div className="baseModal">
      <div className="top">
        <div>{t(title)}?</div>
        <button className="onlyIcon" onClick={onClose}>
          <Icon id="x-mark" width={15} height={15} />
        </button>
      </div>
      <div className="body">
        <div className="flex spaceEvenly">
          <button className="button" onClick={onSubmit} data-testid="submit">
            {t('yes')}
          </button>
          <button className="button" onClick={onClose}>
            {t('no')}
          </button>
        </div>
      </div>
    </div>
  );
}
