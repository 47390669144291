import { Dispatch, Reducer } from 'react';

import { ResponseDataDataAnd } from './helpers';

import { Lookup } from './index';

// GraphQL
type BranchText = {
  language_id: number;
  introduction: string;
  annotation: string;
  description: string;
};

type AssetTag = {
  asset_id: string;
  language_id: number;
  tag_name: string;
};

type BranchAssetPivot = {
  asset_id: string;
  position: number;
};

type BranchAsset = {
  id: string;
  type_id: number;
  name: string;
  url: string;
  file_path: string;
  file_name: string;
  mimetype: string;
  tags: [AssetTag];
  pivot: BranchAssetPivot;
};

export interface BranchType {
  id: string;
  status: Lookup;
  name: string;
  slug: string;
  latitude: number;
  longitude: number;
  is_visible: boolean;
  texts: [BranchText];
  assets: [BranchAsset];
  created_at: string;
  updated_at: string;
}
export interface BranchTypeForm extends Omit<BranchType, 'texts'> {
  texts: BranchText;
}

// Branch types
export type BranchesListType = Pick<BranchType, 'id' | 'status' | 'name' | 'is_visible'>[];

export interface FormDefaultsBranchType {
  name: string;
  introduction: string;
  annotation: string;
  description: string;
  latitude: string;
  longitude: string;
  assets: string;
  slug: string;
}

export type BranchDataType = ResponseDataDataAnd<'branch', BranchType>;

export type BranchWysiwygsType = Record<'introduction' | 'description' | 'annotation', string>;
export type BranchWysiwygsNames = ['introduction', 'description', 'annotation'];

// Context

export type GalleryAssets = {
  file_name: string;
  id: string;
  mimetype: string;
  position: null | number;
  url: string;
}[];

//TODO: add types to BranchContextState
type BranchContextState = {
  id: string;
  status: any;
  name: string;
  slug: string;
  latitude: null | number;
  longitude: null | number;
  is_visible: boolean;
  created_at: string;
  updated_at: string;
  assets: GalleryAssets | null;
  texts: BranchText | any;
};

export interface BranchContextType {
  branchContext: BranchContextState | null;
  dispatchBranch: Dispatch<BranchContextActions>;
}

export type BranchReducerType = Reducer<BranchContextType['branchContext'], BranchContextActions>;

export enum BranchActionType {
  SET_BRANCH = 'SET_BRANCH',
  SET_GALLERY = 'SET_GALLERY',
}

interface SetBranch {
  type: BranchActionType.SET_BRANCH;
  payload: { data: BranchType; editLang: number };
}
interface SetBranchGallery {
  type: BranchActionType.SET_GALLERY;
  payload: GalleryAssets;
}

export type BranchContextActions = SetBranch | SetBranchGallery;
