import { useContext, useEffect, useRef, useState } from 'react';

import { EditorState } from 'draft-js';

import { Editor } from 'react-draft-wysiwyg';
import { useLocation } from 'react-router';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ModalId, ProductCoverImageType, WysiwygEditorProps } from '@admin8/shared/types';
import { getLocalStorageItem } from '@admin8/shared/utils';
import { MainContext, setEditor } from '@admin8/store';

import ToolbarMailToLink from './components/ToolbarMailToLink';
import ToolbarTelToLink from './components/ToolbarTelToLink';
import editorLabels from './utils/translations';
import {
  convertFromHTML,
  convertToHTML,
  createAndAddImage,
  emptyHtml,
  getToolbar,
} from './utils/utils';
import './Editor.scss';

function WysiwygEditor({
  fieldName,
  initialContent,
  onChange,
  classes,
  onFocus,
  onBlur,
  customOnChange,
}: WysiwygEditorProps) {
  const {
    mainContext: { editor, modal, editLang },
    dispatch,
  } = useContext(MainContext);
  const location = useLocation();
  const [minHeight, setminHeight] = useState(false);
  const [isToolbarHidden, setisToolbarHidden] = useState(false);
  const [locales] = useState(getLocalStorageItem('i18nextLng'));
  const [editorState, setEditorState] = useState(
    initialContent
      ? EditorState.createWithContent(convertFromHTML(initialContent))
      : EditorState.createEmpty(),
  );
  const [updated, setUpdated] = useState(false);
  const [triggerByLang, setTriggerByLang] = useState(false);
  const [lang, setLang] = useState(editLang);
  const editorRef = useRef<Editor>(null);

  const setFocus = () => editorRef?.current?.focusEditor();

  const [imgCallback, setImgCallback] = useState<ProductCoverImageType | null>(null);

  const isCategory = location.pathname.includes('categories');

  const addImage = !isCategory ? (img: ProductCoverImageType) => setImgCallback(img) : fieldName;

  const editorToolbar = getToolbar(addImage);

  const onEditorStateChange = (value: EditorState, imageOnChange?: boolean) => {
    if (imageOnChange || !isToolbarHidden) {
      const html = convertToHTML(value);

      if ((!updated && html?.length > 0 && html !== emptyHtml) || (!updated && imageOnChange)) {
        setUpdated(true);
      }

      if (html === emptyHtml) {
        setminHeight(false);
      } else if (html?.length >= 62) {
        setminHeight(true);
      }

      onChange(html);
      customOnChange && customOnChange(html);
      setEditorState(value);
    }

    if (triggerByLang) {
      const html = convertToHTML(value);
      onChange(html);
      customOnChange && customOnChange(html);
      setEditorState(value);
      setTriggerByLang(false);
    }

    if (!isToolbarHidden && isCategory) {
      dispatch(setEditor(value));
    }
  };

  const setToolbarVisible = () => {
    setisToolbarHidden(false);
    typeof onFocus === 'function' && onFocus();
  };

  const setToolbarHidden = () => {
    setisToolbarHidden(true);
    if (modal.open && modal.id === ModalId.FILE_MANAGER) dispatch(setEditor(editorState));
    typeof onBlur === 'function' && onBlur();
  };

  const wrapperClass = `editor-wrapper${classes ? ` ${classes}` : ''}
    ${isToolbarHidden ? ' hideEditorToolbar' : ''}
    ${minHeight ? ' heightAuto' : ''}`;

  const localization = {
    locale: locales?.includes('cs') ? 'cs' : 'en',
    ...(locales?.includes('cs') && { translations: editorLabels }),
  };

  useEffect(() => {
    if (
      initialContent?.length &&
      /* initialContent !== emptyHtml &&
      initialContent.length > emptyHtml.length && */
      !updated
    ) {
      setEditorState(EditorState.createWithContent(convertFromHTML(initialContent)));
    }
  }, [initialContent, updated]);

  useEffect(() => {
    if (editLang !== lang && initialContent) {
      setLang(editLang);
      setUpdated(false);
      setTriggerByLang(true);
    }
  }, [editLang, lang, initialContent]);

  useEffect(() => {
    if (imgCallback) {
      createAndAddImage({ editor, dispatch, imgCallback, setImgCallback, onEditorStateChange });
    }
    if (modal?.category?.editor && modal?.category?.editor?.field === fieldName) {
      setTimeout(
        () =>
          createAndAddImage({
            editor,
            dispatch,
            imgCallback: modal?.category?.editor,
            setImgCallback,
            onEditorStateChange,
          }),
        200,
      );
    }

    // eslint-disable-next-line
  }, [imgCallback, dispatch]);

  return (
    <Editor
      ref={editorRef}
      toolbar={editorToolbar}
      onFocus={setToolbarVisible}
      onBlur={setToolbarHidden}
      toolbarStyle={{ visibility: isToolbarHidden ? 'hidden' : 'visible' }}
      wrapperClassName={wrapperClass}
      editorClassName="demo-editor"
      editorState={editorState}
      toolbarClassName={classes && 'withEditing'}
      toolbarCustomButtons={[
        <ToolbarMailToLink setFocus={setFocus} />,
        <ToolbarTelToLink setFocus={setFocus} />,
      ]}
      onEditorStateChange={onEditorStateChange}
      stripPastedStyles={true}
      localization={localization}
    />
  );
}

export default WysiwygEditor;
