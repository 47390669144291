export const escapeString = (text: string) => {
  //eslint-disable-next-line
  return (text + '')
    .replace(/[\\"']/g, '\\$&')
    .replace(/[\\']'/g, "'")
    .trim();
  // https://stackoverflow.com/questions/770523/escaping-strings-in-javascript
};
export const escapeStringToLowerCase = (text: string) => {
  return (text.toLowerCase() + '').replace(/[\\"']/g, '\\$&');
  // https://stackoverflow.com/questions/770523/escaping-strings-in-javascript
};
