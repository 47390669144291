export const getSalesProducts = (text = { name: '', value: '' }, sort?: any) => ({
  query: `
        {
          products(first: 1000${text.name === 'FULLTEXT' && text.value ? `, fulltext: "${text.value}"` : ''}${sort ? `, orderBy: { column: ${sort.fieldName}, order: ${sort.orderDirection} }` : ''}) {
            paginatorInfo { total, lastPage }
            data {
              id
              name
              versions {
                status { id, name }
                variants {
                  prices {
                    type_id
                    value
                    valid_from
                    valid_to
                  }
                }
              }
              actual_version {
                status { id, name }
                variants {
                  prices {
                    type_id
                    value
                    valid_from
                    valid_to
                  }
                }
              }
            }
          }
        }
      `,
});
