import { ChangeEvent, KeyboardEvent, RefObject } from 'react';

import { EventDatesType, TermsActionType, TermsContextActions } from '@admin8/shared/types';

export const setTermsDefault = (defaultDates: EventDatesType): TermsContextActions => ({
  type: TermsActionType.SET_DEFAULT,
  payload: defaultDates,
});
export const setTermsDates = (datesArr: EventDatesType): TermsContextActions => ({
  type: TermsActionType.SET_DATES,
  payload: datesArr,
});
export const addTermsDate = (): TermsContextActions => ({
  type: TermsActionType.ADD_DATES,
});
export const removeTermsDate = (id: string): TermsContextActions => ({
  type: TermsActionType.REMOVE_DATES,
  payload: id,
});
export const setAvailableTerms = (id: string, value: string): TermsContextActions => ({
  type: TermsActionType.SET_AVAILABLE,
  payload: { id, value },
});
export const editTerms = (
  event: ChangeEvent<HTMLInputElement>,
  id: string,
  name: string,
  customValue: string,
): TermsContextActions => ({
  type: TermsActionType.EDIT_DATES,
  payload: { event, id, name, customValue },
});
export const checkTerms = (
  event: KeyboardEvent<HTMLInputElement>,
  id: string,
  name: string,
  refs: { startRef: RefObject<HTMLInputElement>; finishRef: RefObject<HTMLInputElement> },
): TermsContextActions => ({
  type: TermsActionType.CHECK_DATES,
  payload: { event, id, name, refs },
});
export const createTermsSchema = (): TermsContextActions => ({
  type: TermsActionType.CREATE_SCHEMA,
});
