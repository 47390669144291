import { useContext, useState } from 'react';

import { TextField } from '@mui/material';

import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NotPublishedText } from '@admin8/components';
import constants from '@admin8/shared/constants';
import { ControlledInputSlugProps } from '@admin8/shared/types';
import { slugify } from '@admin8/shared/utils';
import { AppContext, MainContext } from '@admin8/store';
import '../base.scss';
import './inputSlug.scss';
const { LANGUAGE_EN } = constants;

export default function ControlledInputSlug({
  control,
  owner,
  error,
  disableHelperText,
  css = '',
  hasChanged = false,
  burtsFault = null,
  customOnChange,
  slugIsExist,
}: ControlledInputSlugProps) {
  const {
    mainContext: { editLang },
  } = useContext(MainContext);
  const { website } = useContext(AppContext);
  const { t } = useTranslation();

  const [focus, setFocus] = useState(false);
  const onFocus = () => setFocus(true);
  const onBlur = () => setFocus(false);

  const isEnInput = editLang === LANGUAGE_EN;

  const slugCss = `input inputSlug
  ${hasChanged ? ' notPublishedOneLine' : ''}
  ${isEnInput ? ' en' : ''}
  ${owner !== 'Product' ? ' noPlaceholder' : ''}`;

  const slugError = !!error || burtsFault === 'duplicatedSlug';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputProps: any = owner === 'Product' && {
    InputProps: { 'data-placeholder': `${website}${isEnInput ? 'en/' : ''}eshop/` },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const slugifyValue = (value: string, onChange: (...event: any[]) => void) => {
    const slug = slugify(value);
    customOnChange && customOnChange(slug);
    onChange(slug);
  };

  return (
    <div className={`spaceBottom${css ? ` ${css}` : ''}`}>
      <NotPublishedText condition={hasChanged} />
      <Controller
        name="slug"
        control={control}
        rules={{
          required: true,
          ...(customOnChange && {
            validate: () => !slugIsExist,
          }),
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              className={slugCss}
              label="Link"
              onChange={({ target: { value: onChangeValue } }) =>
                slugifyValue(onChangeValue, onChange)
              }
              value={value ? value : ''}
              variant="outlined"
              error={slugError}
              {...(disableHelperText && { helperText: t('helperTextSlugPost') })}
              {...(!value && { onFocus: onFocus })}
              {...(!value && { onBlur: onBlur })}
              {...((value || focus) && inputProps)}
              data-testid="slug"
            />
          );
        }}
      />

      {error && !slugIsExist && <div className="error">{t(`${owner} must have a slug`)}</div>}
      {(error?.message === 'duplicatedSlug' || burtsFault === 'duplicatedSlug') && (
        <div className="error">{t('This slug is already taken. Please try another one.')}</div>
      )}
      {slugIsExist && <div className="error">{t('Slug already exists')}</div>}
    </div>
  );
}
