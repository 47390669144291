import { TableActionType, TableContextActions } from '@admin8/shared/types';

export const setTableFiltersProducts = (filterObj: {
  id: string;
  isParentCategory: boolean;
  name: string;
}): TableContextActions => ({
  type: TableActionType.SET_TABLE_FILTERS_PRODUCTS,
  payload: filterObj,
});

export const setTableFiltersEventsActive = (filterObj: string): TableContextActions => ({
  type: TableActionType.SET_TABLE_FILTERS_EVENTS_ACTIVE,
  payload: filterObj,
});

export const setTableFiltersEventsType = (filterObj: string): TableContextActions => ({
  type: TableActionType.SET_TABLE_FILTERS_EVENTS_TYPE,
  payload: filterObj,
});

export const setTableFiltersEventsDifficulty = (filterObj: string): TableContextActions => ({
  type: TableActionType.SET_TABLE_FILTERS_EVENTS_DIFFICULTY,
  payload: filterObj,
});

export const setTableFiltersEventsLocation = (filterObj: string): TableContextActions => ({
  type: TableActionType.SET_TABLE_FILTERS_EVENTS_LOCATION,
  payload: filterObj,
});

export const setTableFiltersPostsLanguage = (filterObj: number | string): TableContextActions => ({
  type: TableActionType.SET_TABLE_FILTERS_POSTS_LANGUAGE,
  payload: filterObj,
});

export const setTableFiltersPostsType = (filterObj: number | string): TableContextActions => ({
  type: TableActionType.SET_TABLE_FILTERS_POSTS_TYPE,
  payload: filterObj,
});

export const setTableFiltersCountriesStatus = (status: string | boolean): TableContextActions => ({
  type: TableActionType.SET_TABLE_FILTERS_COUNTRIES_STATUS,
  payload: status,
});

export const setTableFiltersCurrency = (status: string): TableContextActions => ({
  type: TableActionType.SET_TABLE_FILTERS_ORDER_CURRENCY,
  payload: status,
});
