import { removeLocalStorageItem } from './localStorage';

export const removeStoredData = () => {
  removeLocalStorageItem('token');
  removeLocalStorageItem('permissions');
  removeLocalStorageItem('categories');
  removeLocalStorageItem('asset_root_folder');
  removeLocalStorageItem('user_typo');
  removeLocalStorageItem('user_roles');
  removeLocalStorageItem('refresh_token');
};
