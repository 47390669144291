import { TableContextActions, TableContextType } from '@admin8/shared/types';

export const TableReducer = (
  state: TableContextType['tableContext'],
  action: TableContextActions,
) => {
  switch (action.type) {
    case 'SET_TABLE_FILTERS_PRODUCTS':
      return {
        ...state,
        customFilters: {
          ...state.customFilters,
          products: {
            category: {
              id: action?.payload?.id,
              isParentCategory: action?.payload?.isParentCategory,
              name: action?.payload?.name,
            },
          },
        },
      };
    case 'SET_TABLE_FILTERS_EVENTS_ACTIVE':
      return {
        ...state,
        customFilters: {
          ...state.customFilters,
          events: {
            ...state.customFilters.events,
            active: action.payload,
          },
        },
      };
    case 'SET_TABLE_FILTERS_EVENTS_TYPE':
      return {
        ...state,
        customFilters: {
          ...state.customFilters,
          events: {
            ...state.customFilters.events,
            type: action.payload,
          },
        },
      };
    case 'SET_TABLE_FILTERS_EVENTS_DIFFICULTY':
      return {
        ...state,
        customFilters: {
          ...state.customFilters,
          events: {
            ...state.customFilters.events,
            difficulty: action.payload,
          },
        },
      };
    case 'SET_TABLE_FILTERS_EVENTS_LOCATION':
      return {
        ...state,
        customFilters: {
          ...state.customFilters,
          events: {
            ...state.customFilters.events,
            location: action.payload,
          },
        },
      };
    case 'SET_TABLE_FILTERS_POSTS_LANGUAGE':
      return {
        ...state,
        customFilters: {
          ...state.customFilters,
          posts: {
            ...state.customFilters.posts,
            language: action.payload,
          },
        },
      };
    case 'SET_TABLE_FILTERS_POSTS_TYPE':
      return {
        ...state,
        customFilters: {
          ...state.customFilters,
          posts: {
            ...state.customFilters.posts,
            type: action.payload,
          },
        },
      };
    case 'SET_TABLE_FILTERS_COUNTRIES_STATUS':
      return {
        ...state,
        customFilters: {
          ...state.customFilters,
          countries: {
            status: action.payload,
          },
        },
      };
    case 'SET_TABLE_FILTERS_ORDER_CURRENCY':
      return {
        ...state,
        customFilters: {
          ...state.customFilters,
          orders: {
            currency: action.payload,
          },
        },
      };
    default:
      return state;
  }
};
