import { TermsActionType, TermsContextActions, TermsContextType } from '@admin8/shared/types';
import {
  createDateTimeForInput,
  createSchemaForDateInputs,
  getDateTimeForInput,
} from '@admin8/shared/utils';

export const TermsReducer = (
  state: TermsContextType['termsContext'],
  action: TermsContextActions,
): TermsContextType['termsContext'] => {
  switch (action.type) {
    case TermsActionType.SET_DEFAULT:
      return { ...state, defaultTerms: JSON.parse(JSON.stringify(action.payload)) };
    case TermsActionType.SET_DATES:
      return { ...state, terms: JSON.parse(JSON.stringify(action.payload)) };
    case TermsActionType.ADD_DATES: {
      const newDate = {
        id: `date${state.terms.length + 1}`,
        name: 'date' + (state.terms.length + 1),
        start_at: createDateTimeForInput(),
        finish_at: createDateTimeForInput(),
        available: true,
      };
      return { ...state, terms: [...state.terms, newDate] };
    }
    case TermsActionType.REMOVE_DATES: {
      const newDatesState = [...state.terms];
      const indexOfObjectToBeRemoved = newDatesState.findIndex(
        (item) => action.payload === item.id,
      );
      const dateObjectToRemove = newDatesState.find((item) => action.payload === item.id);
      if (dateObjectToRemove?.id) {
        newDatesState.splice(indexOfObjectToBeRemoved, 1);
        return {
          ...state,
          terms: newDatesState,
          deleted: [...state.deleted, dateObjectToRemove.id],
        };
      }
      return state;
    }
    case TermsActionType.SET_AVAILABLE: {
      const copyOfTerms = [...state.terms];
      const indx = copyOfTerms.map((item: any) => item.id).indexOf(action.payload.id);
      copyOfTerms[indx].available = action.payload.value === 'false' ? false : true;
      return { ...state, terms: copyOfTerms };
    }
    case TermsActionType.EDIT_DATES: {
      const newDatesState = [...state.terms];
      const dateToEdit = newDatesState.find((item) => action.payload.id === item.id);
      if (dateToEdit) {
        const editedObject = { ...dateToEdit };
        if (action.payload.customValue !== '' && typeof action.payload.event === 'string') {
          editedObject[action.payload.name] = action.payload.event;
        } else if (typeof action.payload.event !== 'string') {
          editedObject[action.payload.name] = action.payload.event.target.value;
        }
        const dateToEditIndex = newDatesState.findIndex((item) => action.payload.id === item.id);
        newDatesState[dateToEditIndex] = editedObject;
        return { ...state, terms: newDatesState };
      }
      return state;
    }
    case TermsActionType.CHECK_DATES: {
      const dateFromInput = (action.payload.event.target as HTMLInputElement).value;

      if (action.payload.event.keyCode === 38) {
        const yearFromInput = parseInt(dateFromInput.slice(0, 4));
        const monthFromInput = parseInt(dateFromInput.slice(5, 7));
        const dayFromInput = new Date(dateFromInput).getDate() + 1;
        const daysInMonth = (month: number, year: number) => new Date(year, month, 0).getDate();
        if (dayFromInput > daysInMonth(monthFromInput, yearFromInput)) {
          action.payload.event.preventDefault();
          const d = new Date(dateFromInput);
          d.setDate(1);
          d.setMonth(d.getMonth() + 1);
          if (action.payload.name === 'start_at') {
            action.payload.refs.startRef.current &&
              (action.payload.refs.startRef.current.value = getDateTimeForInput(d));
          } else if (action.payload.name === 'finish_at') {
            action.payload.refs.finishRef.current &&
              ((
                action.payload.refs.finishRef.current.children[1].children[0] as HTMLInputElement
              ).value = getDateTimeForInput(d));
          }

          const newDatesState = [...state.terms];
          const dateToEdit = newDatesState.find((item) => action.payload.id === item.id);
          if (dateToEdit) {
            const editedObject = { ...dateToEdit };
            editedObject[action.payload.name] = d.toString();
            const dateToEditIndex = newDatesState.findIndex(
              (item) => action.payload.id === item.id,
            );
            newDatesState[dateToEditIndex] = editedObject;
            return { ...state, terms: newDatesState };
          }
          return state;
        }
        return state;
      }
      if (action.payload.event.keyCode === 40) {
        const dayFromInput = new Date(dateFromInput).getDate() - 1;
        if (dayFromInput === 0) {
          action.payload.event.preventDefault();
          const d = new Date(dateFromInput);
          const monthFromInput = d.getMonth();
          const yearFromInput = d.getUTCFullYear();
          const monthBefore = getDateTimeForInput(new Date(yearFromInput, monthFromInput, 0));
          if (action.payload.name === 'start_at') {
            action.payload.refs.startRef.current &&
              (action.payload.refs.startRef.current.value = monthBefore);
          } else if (action.payload.name === 'finish_at') {
            action.payload.refs.finishRef.current &&
              ((
                action.payload.refs.finishRef.current.children[1].children[0] as HTMLInputElement
              ).value = monthBefore);
          }

          const newDatesState = [...state.terms];
          const dateToEdit = newDatesState.find((item) => action.payload.id === item.id);
          if (dateToEdit) {
            const editedObject = { ...dateToEdit };
            editedObject[action.payload.name] = (
              action.payload.event.target as HTMLInputElement
            ).value;
            const dateToEditIndex = newDatesState.findIndex(
              (item) => action.payload.id === item.id,
            );
            newDatesState[dateToEditIndex] = editedObject;
            return { ...state, terms: newDatesState };
          }
          return state;
        }
      }
      return state;
    }
    case TermsActionType.CREATE_SCHEMA: {
      const defaultCopyArr = [...state.defaultTerms];
      const termstCopyArr = [...state.terms];
      const deletedCopyArr = [...state.deleted];
      const dateSchema = createSchemaForDateInputs(defaultCopyArr, termstCopyArr, deletedCopyArr);
      return { ...state, schema: dateSchema };
    }
    default:
      return state;
  }
};
