import constants from '@admin8/shared/constants';
import { FormDefaultsBranchType } from '@admin8/shared/types';
import { escapeString, slugify } from '@admin8/shared/utils';

const { RECORD_STATUS_VALID, RECORD_STATUS_DELETED } = constants;

export const getBranches = (first: number, page: number, text = { value: '', name: '' }, sort) => ({
  query: `{
    branches(
      first: ${first}
      page: ${page}
      where: { 
        AND: [ 
          { column: STATUS_ID, operator: NEQ, value: ${RECORD_STATUS_DELETED} }
          ${
            text.name === 'NAME' && text.value
              ? `{ column: NAME, operator: LIKE, value: "%${text.value}%" }`
              : ''
          }
         ]
       }
      ${sort ? `orderBy: { column: ${sort.fieldName}, order: ${sort.orderDirection} }` : ''}
      ) {
      paginatorInfo { total, lastPage }
      data {
        id
        name
        is_visible
        status {
          id
        }
      }
    }
  }`,
});

export const getBranch = (id: string) => ({
  query: `{
    branch(id: "${id}") {
      id
      status {
        name
      }
      name
      slug
      latitude
      longitude
      is_visible
      texts {
        language_id
        introduction
        annotation
        description
      }
      assets {
        id
        type_id
        name
        url
        file_name
        mimetype
        pivot {
          position
          asset_id
        }
      }
    }
  }
  `,
});

export const createBranch = (data: FormDefaultsBranchType, texts: any[], assets: any[]) => ({
  query: `mutation($texts: [CreateBranchTextInput!]!,${
    assets.length > 0 ? ' $assets: [ConnectBranchAssetInput!]' : ''
  }) {
    branchCreate(input: {
      status_id: ${RECORD_STATUS_VALID}
      name: "${escapeString(data.name)}"
      slug: "${slugify(data.name)}"
      is_visible: true
      texts: { create: $texts }
      ${data.latitude ? `latitude: ${data.latitude}` : ''}
      ${data.longitude ? `longitude: ${data.longitude}` : ''}
      ${assets.length > 0 ? `assets: { sync: $assets }` : ''}
    }) {
      id status { id name } name slug latitude longitude is_visible
    }
  }`,
  variables: {
    texts: texts,
    ...(assets.length > 0 && { assets: assets }),
  },
});

export const updateBranch = ({
  data,
  id,
  texts,
  assets,
}: {
  data: any;
  id: string;
  texts: any;
  assets: any[];
}) => ({
  query: `mutation($texts: [UpsertBranchTextInput!]${
    assets.length > 0 ? ', $assets: [ConnectBranchAssetInput!]!' : ''
  }) {
    branchUpdate(
      id: "${id}"
      input: {
        id: "${id}"
        name: "${escapeString(data.name)}"
        slug: "${escapeString(data.slug)}"
        latitude: ${data.latitude ? data.latitude : 'null'}
        longitude: ${data.longitude ? data.longitude : 'null'}
        texts: { upsert: $texts }
        assets: { sync: ${assets.length > 0 ? '$assets' : '[]'}}
      }
    ) {
      id
      status {
        name
      }
      name
      slug
      latitude
      longitude
      is_visible
      texts {
        language_id
        introduction
        annotation
        description
      }
      assets {
        id
        name
        url
        file_path
      }
    }
  }
  `,
  variables: {
    texts: texts,
    ...(assets.length > 0 && { assets: assets }),
  },
});

export const removeBranches = (idsArr: string[]) => ({
  query: `
    mutation($ids: [ID!]!) {
      branchDeleteMultiple(id: $ids) {
        id
      }
    }
      `,
  variables: {
    ids: idsArr,
  },
});

export const hideBranches = (idsArr: string[]) => ({
  query: `
    mutation {
      ${idsArr.map(
        (item, index) => `branch${index}: branchUpdate(
        id: "${item}"
        input: {
          is_visible: false
      }) {
        is_visible
        id
      }`,
      )}
    }
      `,
});

export const displayBranches = (idsArr: string[]) => {
  return {
    query: `
    mutation {
      ${idsArr.map(
        (item, index) => `branch${index}: branchUpdate(
        id: "${item}"
        input: {
          is_visible: true
      }) {
        is_visible
        id
      }`,
      )}
    }
      `,
  };
};
