import { CategoryContextActions, CategoryContextType } from '@admin8/shared/types';
export const CategoryReducer = (
  state: CategoryContextType['categoryContext'],
  action: CategoryContextActions,
) => {
  switch (action.type) {
    case 'SET_CATEGORY': {
      return {
        ...state,
        category: action.payload,
      };
    }
    case 'SET_TEXTS_DESCRIPTION_CZ': {
      return {
        ...state,
        texts: { ...state.texts, description_cz: action.payload },
      };
    }
    case 'SET_TEXTS_DESCRIPTION_EN': {
      return {
        ...state,
        texts: { ...state.texts, description_en: action.payload },
      };
    }
    case 'SET_IMAGE': {
      return {
        ...state,
        image: action.payload,
      };
    }
    case 'SET_TEXTS': {
      const { texts } = state;
      Object.entries(texts).forEach(
        ([key]) => key === action.payload.key && (texts[key] = action.payload.value),
      );
      return {
        ...state,
        texts: texts,
      };
    }
    case 'SET_PARENT_ID': {
      return {
        ...state,
        parent_id: action.payload,
      };
    }
    default:
      return state;
  }
};
