import constants from '@admin8/shared/constants';
import { LanguageTypes, ProductValuesDeletedType, ProductValuesType } from '@admin8/shared/types';

const { PRODUCT_VALUE_TYPE_UNIT } = constants;

export const createSchemaForProductValues = (
  newArray: ProductValuesType,
  slugsToBeRemoved: string[],
  versionID: string,
  language: LanguageTypes,
) => {
  const arrayOfNewValues = [...newArray];

  arrayOfNewValues.forEach((item) => {
    item.product_version_id = versionID;
    if (item.type_id === PRODUCT_VALUE_TYPE_UNIT && item.metum) {
      item.metum[0].product_version_id = versionID;
    } else {
      delete item.metum;
    }
  });

  const arrayOfDeletedValues = [...slugsToBeRemoved];

  const arrayOfMappedDeletedValues = arrayOfDeletedValues.map((item) => ({
    slug: item,
    product_version_id: versionID,
    language_id: language,
  }));

  const schemaObjectForValues: { default: ProductValuesType; deleted: ProductValuesDeletedType } = {
    default: [],
    deleted: [],
  };

  if (arrayOfNewValues.length > 0) schemaObjectForValues.default = arrayOfNewValues;
  if (arrayOfDeletedValues.length > 0) schemaObjectForValues.deleted = arrayOfMappedDeletedValues;

  return schemaObjectForValues;
};
