import { InitialStateType, MainAction, MainActionType } from '@admin8/shared/types';

export const mainReducer = (state: InitialStateType, action: MainAction): InitialStateType => {
  switch (action.type) {
    case MainActionType.SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
      };
    case MainActionType.SET_LOGIN_STATUS:
      return {
        ...state,
        userLoggedIn: action.payload,
      };
    case MainActionType.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    case MainActionType.SET_MODAL:
      return {
        ...state,
        modal: {
          open: action.payload.open,
          id: action.payload.id,
          data: action.payload.data,
          returnFunction: action.payload.returnFunction,
          size: action.payload.size,
          imageId: action.payload.imageId,
          category: action.payload.category,
          categoryEditorCurrent: null,
        },
      };
    case MainActionType.SET_MODAL_CATEGORY:
      return {
        ...state,
        modal: {
          ...state.modal,
          data: action.payload.data,
          category: action.payload.category,
        },
      };
    case MainActionType.SET_CATEGORY_EDITOR_CURRENT:
      return {
        ...state,
        modal: {
          ...state.modal,
          categoryEditorCurrent: action.payload,
        },
      };
    case MainActionType.SET_OPEN_NAV:
      return {
        ...state,
        isOpenNav: action.payload,
      };
    case MainActionType.SET_EDIT_LANG:
      return {
        ...state,
        editLang: action.payload,
      };
    case MainActionType.SET_IS_EDITING:
      return {
        ...state,
        isEditing: action.payload,
      };
    case MainActionType.SET_CHONKY_FOLDER:
      return {
        ...state,
        chonkyFolderId: action.payload,
      };
    case MainActionType.SET_TOAST:
      return {
        ...state,
        toast: {
          isOpen: true,
          ...action.payload,
        },
      };
    case MainActionType.SET_CLOSE_TOAST:
      return {
        ...state,
        toast: {
          ...state.toast,
          isOpen: false,
        },
      };
    case MainActionType.SET_EDITOR:
      return {
        ...state,
        editor: action.payload,
      };
    case MainActionType.SET_USER_ROLES:
      return {
        ...state,
        userRoles: action.payload,
      };
    case MainActionType.SET_TABLE_REF:
      return {
        ...state,
        tableRef: action.payload,
      };
    default:
      return state;
  }
};
