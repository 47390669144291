import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NotPublishedText } from '@admin8/components';
import { WysiwygProps } from '@admin8/shared/types';

import WysiwygEditor from './editor/Editor';
import './Wysiwyg.scss';

export default function Wysiwyg({
  headline = '',
  hasChanged,
  name,
  customOnChange,
  defaultValue = '',
  css = '',
  onFocus = undefined,
  onBlur = undefined,
  control,
}: WysiwygProps) {
  const { t } = useTranslation();
  const wysiwygEditorWrapperCss = css && { className: css };
  const isNotPublishedTextEnabled = typeof hasChanged === 'boolean';

  return (
    <div {...wysiwygEditorWrapperCss} data-testid={name}>
      {headline && <div className="wysiwygHeadline">{t(headline)}</div>}

      {isNotPublishedTextEnabled && <NotPublishedText condition={hasChanged} css="withWysiwyg" />}

      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <WysiwygEditor
            fieldName={name}
            initialContent={value}
            onChange={onChange}
            customOnChange={customOnChange}
            classes={hasChanged ? 'notPublishedOneLine' : ''}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )}
      />
    </div>
  );
}
