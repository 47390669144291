export const getCustomers = (first: number, page: number, text = { value: '', name: '' }, sort) => {
  return {
    query: `
    {
      users(first: ${first}, page: ${page} 
        ${text.name === 'NAME' && text.value ? `, where: { column: NAME, operator: LIKE, value: "%${text.value}%" }` : ''}
        ${text.name === 'EMAIL' && text.value ? `, where: { column: EMAIL, operator: LIKE, value: "%${text.value}%" } ` : ''}
        ${sort ? `orderBy: { column: ${sort.fieldName}, order: ${sort.orderDirection} }` : ''}) {
        paginatorInfo { total, lastPage }
        data {
          id
          name
          email
        }
      }
    }    
      `,
  };
};

export const getCustomer = (id: string) => {
  return {
    query: `
    {
      user(id: "${id}") {
        id
        name
        email
        addresses {
          id
          user {
            name
            email
          }
          status {
            id
            name
          }
          type {
            name
            id
          }
          first_name
          last_name
          street
          city
          county
          country
          postcode
          phone
        }
      }
    }       
      `,
  };
};

export const getOrdersByCustomers = (id: string) => {
  return {
    query: `
    {
      orders(first: 100, where: { column: USER_ID, value: ${id} }) {
        data {
          id
          created_at
          user_id
          user {
            name
            email
            id
          }
          delivery {
            id
            name
          }
          payment {
            id
            name
          }
          price_total
          currency
          addresses {
            id
            order_id
            type_id
            user_address_id
            street
            name
            city
            county
            country
            postcode
            phone
          }
        }
      }
    }
      `,
  };
};
