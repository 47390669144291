import { useTranslation } from 'react-i18next';

import { Icon, NotPublishedText } from '@admin8/components';
import { MainIconProps } from '@admin8/shared/types';
import { imageFallback } from '@admin8/shared/utils';

import EmptyImage from '../emptyImage/EmptyImage';
import './MainImage.scss';

const MainIcon = ({
  name,
  registerName = 'icon',
  icon,
  hasChangedImage,
  registerImage,
  deleteHandler,
  openModal,
  children,
}: MainIconProps) => {
  const { t } = useTranslation();
  return (
    <div className="mainImageBlock">
      <div className="leftBlock">{name}</div>
      <div className="rightBlock">
        <input
          {...registerImage(registerName)}
          type="hidden"
          defaultValue={JSON.stringify(icon)}
          readOnly={true}
        />
        {icon?.url ? (
          <>
            <div className={`imageBox${hasChangedImage ? ' notPublishedOneLine' : ''}`}>
              <NotPublishedText condition={hasChangedImage} />
              <a className="imageOpener" href={icon?.url} target="_blank" rel="noreferrer">
                <img
                  src={icon?.url}
                  width="280"
                  height="280"
                  onError={imageFallback}
                  className="image"
                  alt={icon?.name}
                />
              </a>
              <div className="imageBoxBottomLine">
                <button
                  type="button"
                  className="button"
                  onClick={(e) => {
                    e.preventDefault();
                    deleteHandler();
                  }}
                >
                  <Icon id="trash" width={15} height={15} /> {t('delete')}
                </button>
                <button type="button" className="button" onClick={(e) => openModal(e, 1, 'xxl')}>
                  <Icon id="arrow-uturn-forward" width={15} height={15} /> {t('replace')}
                </button>
              </div>
            </div>

            <div className="helperText">{icon?.id}</div>
          </>
        ) : (
          <EmptyImage hasChanged={hasChangedImage} openModal={openModal} name="emptyIcon" />
        )}
      </div>
      {children}
    </div>
  );
};

export default MainIcon;
