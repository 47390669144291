import constants from '@admin8/shared/constants';
import { ConsultantImageType, FormDefaultsConsultantType } from '@admin8/shared/types';
import { escapeString } from '@admin8/shared/utils';

const { RECORD_STATUS_NEW, RECORD_STATUS_VALID } = constants;

export const getConsultant = (id: string, langId: number) => {
  return {
    query: `{
      consultant(id: "${id}") {
        id
        title
        first_name
        last_name
        degree
        slug
        status {
          id
          name
        }
        company_name
        street
        city
        postcode
        latitude
        longitude
        cover_image {
          id
          url
          name
        }
        email
        phone
        website
        texts(where: { column: LANGUAGE, value: ${langId} }) {
          language_id
          description
          premise
        }
        assets {
          file_name
          mimetype
          id
          url
          pivot { 
            position
           }
        }
      }
    }
    `,
  };
};

export const createConsultant = (
  data: FormDefaultsConsultantType,
  image: ConsultantImageType,
  assets: { id: string; position: number }[],
  langId: number,
) => {
  return {
    query: `
      mutation${assets.length > 0 ? `($assets: [ConnectConsultantAssetInput!])` : ''} {
        consultantCreate(input: {
          status_id: ${RECORD_STATUS_VALID}
          title: "${escapeString(data.title)}"
          first_name: "${escapeString(data.first_name)}"
          last_name: "${escapeString(data.last_name)}"
          degree: "${escapeString(data.degree)}"
          slug: "${escapeString(data.slug)}"
          ${data.phone ? `phone: "${escapeString(data.phone)}"` : ''}
          ${data.company_name ? `company_name: "${escapeString(data.company_name)}"` : ''}
          ${data.email ? `email: "${escapeString(data.email)}"` : ''}
          ${data.website ? `website: "${escapeString(data.website)}"` : ''}
          ${data.city ? `city: "${escapeString(data.city)}"` : ''}
          ${data.street ? `street: "${escapeString(data.street)}"` : ''}
          ${data.postcode ? `postcode: "${escapeString(data.postcode)}"` : ''}
          ${data.latitude ? `latitude: "${escapeString(data.latitude)}"` : ''}
          ${data.longitude ? `longitude: "${escapeString(data.longitude)}"` : ''}
          ${image?.id ? `cover_image_id: "${image.id}"` : ''}
          ${
            data.description || langId || data.premise
              ? `texts: {
            create: [
              {
                ${langId ? `language_id: ${langId}` : ''}
                ${data.description ? `description: ${JSON.stringify(data.description)}` : ''}
                ${data.premise ? `premise: ${JSON.stringify(data.premise)}` : ''}
              }
            ]
          }`
              : ''
          }
          ${assets.length > 0 ? `assets: { sync: $assets }` : ''}
        }) {
          id
          status { id }
          title first_name last_name degree
          slug
          ${data.company_name ? `company_name` : ''}
          ${data.phone ? `phone` : ''}
          ${data.email ? `email` : ''}
          ${data.website ? `website` : ''}
          ${data.city ? `city` : ''}
          ${data.street ? `street` : ''}
          ${data.postcode ? `postcode` : ''}
          ${data.latitude ? `latitude` : ''}
          ${data.longitude ? `longitude` : ''}
          ${image?.id ? `cover_image { id url file_name }` : ''}
          ${
            data.description || langId || data.premise
              ? `texts {
            ${langId ? `language_id` : ''}
            ${data.description ? `description` : ''}
            ${data.premise ? `premise` : ''}
          }`
              : ''
          }
          ${assets.length > 0 ? `assets { file_name mimetype id url }` : ''}
        }
      }
      `,
    ...(assets.length > 0 && {
      variables: {
        assets: assets,
      },
    }),
  };
};

export const updateConsultant = (
  id: string,
  data: FormDefaultsConsultantType,
  image: ConsultantImageType,
  assets: { id: string; position: number }[],
  langId: number,
  texts: string,
) => {
  return {
    query: `
    mutation${assets.length > 0 ? `($assets: [ConnectConsultantAssetInput!])` : ''} {
      consultantUpdate(
        id: "${id}"
        input: {
        id: "${id}"
        status_id: ${RECORD_STATUS_VALID}
        title: "${escapeString(data.title)}"
        first_name: "${escapeString(data.first_name)}"
        last_name: "${escapeString(data.last_name)}"
        degree: "${escapeString(data.degree)}"
        slug: "${escapeString(data.slug)}"
        company_name: "${escapeString(data.company_name)}"
        phone: "${escapeString(data.phone)}"
        email: "${escapeString(data.email)}"
        website: "${escapeString(data.website)}"
        city: "${escapeString(data.city)}"
        street: "${escapeString(data.street)}"
        postcode: "${escapeString(data.postcode)}"
        ${data.latitude ? `latitude: "${data.latitude}"` : 'latitude: null'}
        ${data.longitude ? `longitude: "${data.longitude}"` : 'longitude: null'}
        ${image.id ? `cover_image_id: "${image.id}"` : 'cover_image_id: null'}
        texts: {
            ${
              texts === 'create'
                ? `create: [
              {
                ${langId ? `language_id: ${langId}` : ''}
                ${
                  data.description
                    ? `description: ${JSON.stringify(data.description)}`
                    : 'description: ""'
                }
                ${data.premise ? `premise: ${JSON.stringify(data.premise)}` : 'premise: ""'}
              }
            ]`
                : `update: [
              {
                consultant_id: "${id}"
                ${langId ? `language_id: ${langId}` : ''}
                ${
                  data.description
                    ? `description: ${JSON.stringify(data.description)}`
                    : 'description: ""'
                }
                ${data.premise ? `premise: ${JSON.stringify(data.premise)}` : 'premise: ""'}
              }
            ]`
            }
          }
        ${assets.length > 0 ? `assets: { sync: $assets }` : 'assets: { sync: [] }'}
      }) {
        id
        status { id }
        title first_name last_name degree
        slug
        company_name
        phone
        email
        website
        city
        street
        postcode
        latitude
        longitude
        cover_image { id url file_name }
        texts {
            ${langId ? `language_id` : ''}
            ${data.description ? `description` : ''}
            ${data.premise ? `premise` : ''}
          }
        ${assets.length > 0 ? `assets { file_name mimetype id url }` : ''}
      }
    }
    `,
    ...(assets.length > 0 && {
      variables: {
        assets: assets,
      },
    }),
  };
};

/* export const setPostValid = (id, status) => {
  return {
    query: `
    mutation {
      consultantUpdate(
        id: "${id}"
        input: {
        id: "${id}"
        status_id: ${status}
      }) {
        id
        status { id }
        title first_name last_name degree 
        slug
      }
    }
    `,
  };
}; */

export const getConsultants = (
  first: number,
  page: number,
  text = { value: '', name: '' },
  sort,
) => {
  return {
    query: `
    {
      consultants(
        first: ${first}
        page: ${page}
        where: {
          AND: [
            {
              OR: [
                { column: STATUS_ID, value: ${RECORD_STATUS_NEW} }
                { column: STATUS_ID, value: ${RECORD_STATUS_VALID} }
              ]
            }
            ${
              text.name === 'NAME' && text.value
                ? `{
              OR: [
                { column: FIRST_NAME, operator: LIKE, value: "%${text.value}%" }
                { column: LAST_NAME, operator: LIKE, value: "%${text.value}%" }
              ]
            }`
                : ''
            }
            ${
              text.name === 'ADDRESS' && text.value
                ? `{
              OR: [
                { column: CITY, operator: LIKE, value: "%${text.value}%" }
                { column: STREET, operator: LIKE, value: "%${text.value}%" }
              ]
            }`
                : ''
            }
            ${
              text.name === 'EMAIL'
                ? `{ column: EMAIL, operator: LIKE, value: "%${text.value}%" }`
                : ''
            }
          ]
        }
        ${sort ? `orderBy: { column: ${sort.fieldName}, order: ${sort.orderDirection} }` : ''}
      ) {
        paginatorInfo { total, lastPage }
        data {
          id
          title
          first_name
          last_name
          degree
          company_name
          email
          status {
            id
          }
          is_visible
          cover_image {
            url
            file_name
          }
          assets {
            file_name
          }
          city
          street
          updated_at
          created_at
        }
      }
    }    
        `,
  };
};

export const removeConsultants = (idsArr: string[]) => {
  return {
    query: `
    mutation($ids: [ID!]!) {
      consultantDeleteMultiple(id: $ids) {
        id
      }
    }
      `,
    variables: {
      ids: idsArr,
    },
  };
};

export const hideConsultants = (idsArr: string[]) => {
  return {
    query: `
    mutation {
      ${idsArr.map(
        (item, index) => `consultant${index}: consultantUpdate(
        id: "${item}"
        input: {
          id: "${item}"
          is_visible: false
      }) {
        is_visible
        id
      }`,
      )}
    }
      `,
  };
};

export const displayConsultants = (idsArr: string[]) => {
  return {
    query: `
    mutation {
      ${idsArr.map(
        (item, index) => `consultant${index}: consultantUpdate(
        id: "${item}"
        input: {
          id: "${item}"
          is_visible: true
      }) {
        is_visible
        id
      }`,
      )}
    }
      `,
  };
};
