export const mainContextStore: { isReady: boolean; dispatch: any } = {
  isReady: false,
  dispatch: () => null,
};

export const setMainContextStoreDispatch = (dispatch) => {
  mainContextStore.isReady = true;
  mainContextStore.dispatch = (params) => dispatch(params);
  Object.freeze(mainContextStore);
};
