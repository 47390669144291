import { sprite } from '@admin8/shared/assets';

console.log(sprite);

export default function Icon({ id, ...props }) {
  return (
    <svg {...props}>
      <use href={`${sprite}#${id}`} />
    </svg>
  );
}
