import { Reducer } from 'react';

import { FieldError } from 'react-hook-form';

import {
  ProductCategoriesType,
  ProductCombinationType,
  ProductCoverImageType,
  ProductSeoType,
  ProductTagsType,
  ProductTextsType,
  ProductValuesType,
} from '@admin8/shared/types';

// Product
export interface FormValuesProductSpecificType {
  name: string;
  slug: string;
  versionName: string;
  subname: string;
  introduction: string;
  description: string;
  annotation: string;
  footnote: string;
  combinations: string;
  category: { category: string; isMain: 'true' | 'false' }[];
  image: string;
  tags: string;
  icon: string;
  seoTitle: string;
  seoDescription: string;
  seoKeywords: string;
  seoOg_title: string;
  seoOg_type: string;
  seoOg_url: string;
  seoOg_image: string;
  values: ProductValuesType[];
  priceCZK: number;
  tax_rate: number;
  discountPriceCZK: number;
  validFromCZK: string;
  validToCZK: string;
  priceEUR: number;
  discountPriceEUR: number;
  validFromEUR: string;
  validToEUR: string;
}

export type HasChangedFormValuesSpecificType = {
  [key in keyof FormValuesProductSpecificType]: boolean;
};
export type ErrorsFormValuesSpecificType = {
  [key in keyof FormValuesProductSpecificType]?: FieldError | undefined;
};

export type ProductSpecificHasChangedVariants = {
  priceCZK: boolean;
  tax_rate: boolean;
  priceEUR: boolean;
  discountPriceCZK: { discount: boolean; validFrom: boolean; validTo: boolean };
  discountPriceEUR: { discount: boolean; validFrom: boolean; validTo: boolean };
};

export type ProductSpecificVariants = {
  id: string;
  name: string;
  prices: {
    currency: string;
    product_variant_id: string;
    type_id: number;
    valid_from: null | string;
    valid_to: null | string;
    value: null | number;
  }[];
  tax_rate: number;
  texts: {
    product_variant_id: string;
    language_id: number;
    name: string;
    description: string;
  }[];
}[];

export interface ProductSpecificType {
  id: string;
  status: {
    id: number;
    name: string;
  };
  name: string;
  cover_image: ProductCoverImageType;
  tags: ProductTagsType;
  texts: ProductTextsType[];
  combinations: ProductCombinationType;
  product: {
    id: string;
    category: {
      id: string;
      name: string;
    };
    categories: ProductCategoriesType;
    actual_version: {
      id: string;
    };
  };
  values: ProductValuesType;
  variants: ProductSpecificVariants;
  seo: ProductSeoType[];
}

export interface DataProductSpecificType {
  data: {
    data: {
      productVersion: ProductSpecificType;
    };
  };
}

export type ProductVariantsSpecificState = {
  id: string;
  priceCZK: { type_id: number; currency: string; value: null | number; tax_rate: number };
  priceEUR: { type_id: number; currency: string; value: null | number };
  priceDiscountCZK: null | Partial<{
    currency: string;
    type_id: number;
    valid_from: string;
    valid_to: string;
    value: number;
  }>;
  priceDiscountEUR: null | Partial<{
    currency: string;
    type_id: number;
    valid_from: string;
    valid_to: string;
    value: number;
  }>;
};

enum MergedPricesType {
  'CREATE' = 'create',
  'UPSERT' = 'upsert',
}

export type MergedVariantsSpecificType = {
  tax_rate: number;
  prices: {
    [key in MergedPricesType]?: {
      currency: string;
      value: number;
      valid_from?: string;
      valid_to?: string;
      product_variant_id: string;
      type_id: number;
    }[];
  };
  changeToCreate: boolean;
  id: string;
  name: string;
};

// Variants reducer

type variantsSet = {
  type: 'SET';
  payload: ProductVariantsSpecificState;
};
type variantsSetPriceCz = {
  type: 'SET_PRICE_CZ';
  payload: number;
};
type variantsSetTaxRate = {
  type: 'SET_TAX_RATE';
  payload: number;
};
type variantsSetPriceEur = {
  type: 'SET_PRICE_EUR';
  payload: number;
};
type variantsSetValidFromCz = {
  type: 'SET_VALID_FROM_CZ';
  payload: string;
};
type variantsSetValidToCz = {
  type: 'SET_VALID_TO_CZ';
  payload: string;
};
type variantsSetDiscountCz = {
  type: 'SET_DISCOUNT_CZ';
  payload: number;
};
type variantsSetValidFromEur = {
  type: 'SET_VALID_FROM_EUR';
  payload: string;
};
type variantsSetValidToEur = {
  type: 'SET_VALID_TO_EUR';
  payload: string;
};
type variantsSetDiscountEur = {
  type: 'SET_DISCOUNT_EUR';
  payload: number;
};

export type variantsReducerActions =
  | variantsSet
  | variantsSetPriceCz
  | variantsSetTaxRate
  | variantsSetValidFromCz
  | variantsSetPriceEur
  | variantsSetValidToCz
  | variantsSetDiscountCz
  | variantsSetValidFromEur
  | variantsSetValidToEur
  | variantsSetDiscountEur;

export type VariantsReducerType = Reducer<ProductVariantsSpecificState, variantsReducerActions>;

export interface variantsFormDefaultsType {
  priceCZK: string;
  tax_rate: string;
  discountPriceCZK: string;
  validFromCZK: string;
  validToCZK: string;
  priceEUR: string;
  discountPriceEUR: string;
  validFromEUR: string;
  validToEUR: string;
}
