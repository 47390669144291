import { UserType2, Vouchers } from '.';

export interface ReservioEvent {
  event_id: string;
  booking_id: string;
  client_id: string;
  service_package_id: string;
  employee_id: string;
  name: string;
  state: string;
  start: string;
  end: string;
  color: number;
  created_at: string;
  updated_at: string | null;
  client: {
    id: string;
    external_id: string;
    name: string;
    email: string;
    phone: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
  };
  service_package: {
    id: string;
    external_id: string;
    name: string;
    description: string;
    duration: number;
    created_at: string;
    updated_at: string;
  };
  employee: {
    id: string;
    external_id: string;
    user_id: number;
    department_id: string;
    experience_level_id: string;
    name: string;
    email: string;
    description: string;
    phone: string | null;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
  };
}

export interface BillingFormType {
  event_id: string;
  client_name: string;
  service_package_id: string;
  service_package_name?: string;
  employee_desc: string;
  issued: string;
  employee_name: string;
  experience_level_id: string;
}

interface Status {
  id: number;
  parent_id: number;
  name: string;
}

export interface BillingEmployee {
  id: string;
  external_id: string;
  user_id: number;
  department_id: string;
  experience_level_id: string;
  name: string;
  email: string;
  description: string;
  phone: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export interface BillingClient {
  id: string;
  external_id: string;
  name: string;
  email: string;
  phone: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export interface BillingService {
  id: string;
  name: string;
  duration?: number;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string | null;
  pivot?: {
    billing_id?: string;
    service_id?: string;
    original_price?: string;
    internal_price: string;
    client_price: string;
  };
}

export interface BillingProduct {
  id: string;
  external_id?: number;
  name: string;
  description?: string;
  price: number;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string | null;
  pivot: {
    billing_id: string;
    product_id: string;
    original_price: string;
    internal_price: string;
    client_price: string;
    quantity: number;
  };
}

export interface BillingAPIResponse {
  id: string;
  author_id: number;
  status_id: number;
  client_id: string;
  employee_id: string;
  booking_event_id: string | null;
  closure_id: string | null;
  issued: string;
  beverage_amount: number;
  beverage_note: string;
  total_amount: number;
  payed_amount: number | null;
  payment_type: string | null;
  note: string | null;
  order_note: string | null;
  is_overtime: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  status: Status;
  employee: BillingEmployee;
  client: BillingClient;
  services: BillingService[];
  products: BillingProduct[];
  vouchers: Vouchers[];
  // closure: ClosuresApiResponse | null;
}

export interface BillingsListData {
  id: string;
  author_id: number;
  status_id: number;
  client_id: string;
  employee_id: string;
  booking_event_id: string;
  closure_id: string;
  issued: string;
  beverage_amount: number;
  fee_amount: number;
  total_amount: number;
  payed_amount: number;
  payment_type: string;
  note: string | null;
  beverage_note: string | null;
  is_overtime: boolean;
  is_missing_work: boolean;
  is_future_work: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  client: BillingClient;
  employee: BillingEmployee;
  closure: ClosuresApiResponse[];
  vouchers: Vouchers[];
}

export interface ServicesAPIResponse {
  id: string;
  type_id: number;
  name: string;
  duration: number;
  position: number | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  prices: any[];
}

export interface ClosuresApiResponse {
  id: string;
  user_id: number;
  cash_amount: number;
  card_amount: number;
  note: string;
  created_at: string;
  user: UserType2;
}

export interface PackagesAPIResponse {
  id: string;
  name: string;
  description: string;
  duration: number;
  created_at: string;
  updated_at: string;
  position: number | null;
  deleted_at: string | null;
  services: any[];
}

export interface ResourceAPIResponse {
  name: string;
  email: string;
  phone: string;
  description: string;
  experience_level_id: string;
  department_id: string;
  updated_at: string;
  deleted_at: string | null;
}

export type FormDefaultsEmployeeType = {
  name: string;
  email: string;
  password?: string;
  repeat_password?: string;
  phone: string;
  level: string;
  description: string;
  department_id: string;
};

export type FormDefaultsBillingEditType = {
  order_note: string;
  is_overtime: boolean;
};

export type FormDefaultsServiceType = {
  name: string;
  duration: number;
  serviceType: number;
  eyebrowColorPrice: string;
  eyebrowHennaPrice: string;
  levels: {
    longHair: string;
    normalHair: string;
    shortHair: string;
    experience_level_id: string;
  }[];
};

export enum AgendaStatus {
  Created = 3210,
  Done = 3220,
  Canceled = 3230,
}
export enum BillingStatus {
  Created = 3110,
  Paid = 3120,
  Canceled = 3130,
}

export enum HairType {
  Short = 3310,
  Normal = 3320,
  Long = 3330,
}

export enum EyebrowColorType {
  Color = 3340,
  Henna = 3350,
}

export enum ServiceType {
  Hair = 3410,
  Eyebrow = 3420,
  EyebrowColor = 3430,
}

export type PriceEntry = {
  experience_level_id: string;
  type_id: number;
  price: number;
};

export type GroupedPrices = {
  [experienceLevelId: string]: {
    [typeId: number]: number;
  };
};

export type FormattedPrice = {
  experience_level_id: string;
  [key: number]: number;
};

export interface DataItem {
  id: string;
  name: string;
}

export interface EmployeeReportData {
  issued: string;
  beverage_amount: number;
  service_original_amount_with_fee: number;
  service_internal_amount_with_fee: number;
  service_client_amount_with_fee: number;
  service_internal_amount_regular_with_fee: number;
  service_internal_amount_overtime_with_fee: number;
  service_internal_amount_weekend_with_fee: number;
  service_internal_amount_regular_without_fee: number;
  service_internal_amount_overtime_without_fee: number;
  service_internal_amount_weekend_without_fee: number;
  product_original_amount: number;
  product_client_amount: number;
  fee_amount_all: number;
  fee_amount_regular: number;
  fee_amount_overtime: number;
  fee_amount_weekend: number;
  voucher_amount: number;
  total_amount: number;
  payed_amount: number;
  payed_amount_cash: number;
  payed_amount_card: number;
  tip_amount: number;
}

export interface MonthlyReportData extends EmployeeReportData {
  payed_amount_hairdress: number;
  payed_amount_eyebrow: number;
}

export interface ReportTotalAmounts {
  overtimeAmount: number;
  weekendsAmount: number;
  productsAmount: number;
  servicesAmount: number;
  voucherAmount: number;
  tipAmount: number;
  feeAmount: number;
  cafeAmount: number;
}

export enum PaymentType {
  CASH = 'CASH',
  CARD = 'CARD',
}
