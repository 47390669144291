import { t } from 'i18next';

import constants from '@admin8/shared/constants';
import {
  FormValuesEventType,
  SchemaObjectForDatesType,
  TableContextType,
} from '@admin8/shared/types';
import { escapeString } from '@admin8/shared/utils';

const { RECORD_STATUS_NEW, RECORD_STATUS_VALID, LANGUAGE_CZ } = constants;

export const getEvent = (id: string) => {
  return {
    query: `
    {
      event(id: "${id}") {
        id
        is_visible
        status {
          id
        }
        type {
          id
          name
        }
        name
        slug
        language_id
        difficulty
        lecturer
        location
        price
        annotation
        description
        email
        phone
        address
        location_address
        dates {
          start_at
          finish_at
          id
          available
          name
        }
      }
    }    
        `,
  };
};

export const createEvent = (data: FormValuesEventType, dateSchema: SchemaObjectForDatesType) => {
  const dateSchemaSize = Object.keys(dateSchema).length;
  return {
    query: `
    mutation${dateSchemaSize > 0 ? `($dates: CreateEventDateHasMany!)` : ''} {
        eventCreate(input: {
          type_id: ${+data?.type}
          status_id: ${RECORD_STATUS_VALID}
          author_id: 1
          language_id: ${LANGUAGE_CZ}
          name: "${escapeString(data.name)}"
          slug: "${escapeString(data.slug)}"
          ${data.lecturer ? `lecturer: "${escapeString(data.lecturer)}"` : ''}
          ${data.difficulty ? `difficulty: "${data.difficulty}"` : ''}
          ${data.location ? `location: "${escapeString(data.location)}"` : ''}
          ${
            data.locationAddressOther
              ? `location_address: "${escapeString(data.locationAddressOther)}"`
              : data.locationAddress
                ? `location_address: "${escapeString(data.locationAddress)}"`
                : ''
          }
          ${data.price ? `price: "${escapeString(data.price)}"` : ''}
          ${data.description ? `description: ${JSON.stringify(data.description)}` : ''}
          ${dateSchemaSize > 0 ? `dates: $dates` : ''}
        }) {
          id status { id } name slug ${data.difficulty ? `difficulty` : ''} ${
            data.location ? `location` : ''
          } ${data.locationAddress ? `location_address` : ''} ${data.price ? `price` : ''} ${
            data.description ? `description` : ''
          }
          ${dateSchemaSize > 0 ? `dates {  id name start_at finish_at }` : ''}
        }
      }`,
    ...(dateSchemaSize > 0 && {
      variables: {
        dates: dateSchema,
      },
    }),
  };
};

export const updateEvent = (
  id: string,
  data: FormValuesEventType,
  dateSchema: SchemaObjectForDatesType,
) => {
  const dateSchemaSize = Object.keys(dateSchema).length;
  return {
    query: `
    mutation${dateSchemaSize > 0 ? `($dates: UpdateEventDateHasMany!)` : ''} {
        eventUpdate(
          id: "${id}"
          input: {
          id: "${id}"
          status_id: ${RECORD_STATUS_VALID}
          name: "${escapeString(data.name)}"
          slug: "${escapeString(data.slug)}"
          type_id: ${data.type}
          lecturer: "${escapeString(data.lecturer)}"
          difficulty: "${data.difficulty}"
          location: "${escapeString(data.location)}"
          ${
            data.locationAddressOther
              ? `location_address: "${escapeString(data.locationAddressOther)}"`
              : `location_address: "${escapeString(data.locationAddress)}"`
          }
          price: "${escapeString(data.price)}"
          description: ${JSON.stringify(data.description)}
          ${dateSchemaSize > 0 ? `dates: $dates` : ''}
        }) {
          id status { id } name slug difficulty location location_address price description
          ${dateSchemaSize > 0 ? `dates {  id name start_at finish_at }` : ''}
        }
      }`,
    ...(dateSchemaSize > 0 && {
      variables: {
        dates: dateSchema,
      },
    }),
  };
};

const today = new Date();
const epoch = new Date(0);
const DATE_NOW = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
const DATE_EPOCH = `${epoch.getFullYear()}-${epoch.getMonth() + 1}-${epoch.getDate()}`;

export const getEvents = (
  first: number,
  page: number,
  text = { name: '', value: '' },
  filters?: TableContextType['tableContext']['customFilters']['events'],
  sort?: any,
) => {
  return {
    query: `
    {
      events(
        first: ${first}
        page: ${page}
        ${
          filters && filters?.active
            ? filters.active === t('Active')
              ? `hasDates: { column: START_AT, operator: GTE, value: "${DATE_NOW}" }`
              : `hasDates: {
              AND: [
                { column: START_AT, operator: BETWEEN, value: ["${DATE_EPOCH}", "${DATE_NOW}"] }
                { column: START_AT, operator: LT, value: "${DATE_NOW}" }
              ]
            }`
            : ''
        }
        where: {
          AND: [
            ${
              filters && filters.location
                ? `{ OR: { column: LOCATION_ADDRES, value: "${filters.location}" } }`
                : ''
            }
            ${
              filters && filters.type ? `{ OR: { column: TYPE_ID, value: "${filters.type}" } }` : ''
            }
            ${
              filters && filters.difficulty
                ? `{ OR: { column: DIFFICULTY, value: "${filters.difficulty}" } }`
                : ''
            }
            ${
              text.value
                ? `{
              OR: ${
                text.name === 'TITLE'
                  ? `{ column: NAME, operator: LIKE, value: "%${text.value}%" }`
                  : ''
              }
              ${
                text.name === 'LECTURER'
                  ? `{ column: LECTURER, operator: LIKE, value: "%${text.value}%" }`
                  : ''
              }
            }`
                : ''
            }
            {
              OR: [
                { column: STATUS_ID, value: ${RECORD_STATUS_NEW} }
                { column: STATUS_ID, value: ${RECORD_STATUS_VALID} }
              ]
            }
          ]
        }
        ${
          sort
            ? `orderBy: { column: ${sort.fieldName}, order: ${sort.orderDirection} }`
            : 'orderBy: { column: CREATED_AT, order: DESC }'
        }
      ) {
        paginatorInfo { total, lastPage }
        data {
          id
          type {
            id
            name
          } 
          name
          slug
          difficulty
          lecturer
          location
          location_address
          price
          annotation
          description
          email
          phone
          address
          latitude
          longitude
          dates {
            start_at
          }
          status {
            name
            id
          }
          is_visible
          updated_at
          created_at
        }
      }
    }    
      `,
  };
};

export const removeEvents = (idsArr: string[]) => {
  return {
    query: `
    mutation($ids: [ID!]!) {
      eventDeleteMultiple(id: $ids) {
        id
      }
    }
      `,
    variables: {
      ids: idsArr,
    },
  };
};

export const hideEvents = (idsArr: string[]) => {
  return {
    query: `
    mutation {
      ${idsArr.map(
        (item, index) => `event${index}: eventUpdate(
        id: "${item}"
        input: {
          id: "${item}"
          is_visible: false
      }) {
        is_visible
        id
      }`,
      )}
    }
      `,
  };
};

export const displayEvents = (idsArr: string[]) => {
  return {
    query: `
    mutation {
      ${idsArr.map(
        (item, index) => `event${index}: eventUpdate(
        id: "${item}"
        input: {
          id: "${item}"
          is_visible: true
      }) {
        is_visible
        id
      }`,
      )}
    }
      `,
  };
};
