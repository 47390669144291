import { useContext } from 'react';

import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';

import {
  displayBranches,
  displayCategory,
  displayConsultants,
  displayEvents,
  displayPages,
  displayPosts,
  displayProducts,
} from '@admin8/graphql';
import { useEventEnterListener } from '@admin8/shared/hooks';
import { GraphQLPageSchemasObjType, QueryObjectType } from '@admin8/shared/types';
import { defaultMutationFn } from '@admin8/shared/utils';
import { MainContext, setModal } from '@admin8/store';

import BaseModal from './BaseModal';

const SetVisible = () => {
  const {
    mainContext: { modal, tableRef },
    dispatch,
  } = useContext(MainContext);
  const { pathname } = useLocation();
  const pageName: string = pathname?.substring(1);

  const handleClose = () => {
    modal.returnFunction && modal.returnFunction();
    dispatch(setModal({ open: false, id: null, data: null, returnFunction: null, size: null }));
  };

  const { mutate } = useMutation((graphQuery: QueryObjectType) => defaultMutationFn(graphQuery));

  const onSubmit = () => {
    const idsFromTable = tableRef?.current?.state?.data
      ?.filter((item) => item?.tableData?.checked)
      .map((item) => item.id);

    const graphQLPageSchemas: GraphQLPageSchemasObjType = {
      products: displayProducts(idsFromTable),
      consultants: displayConsultants(idsFromTable),
      posts: displayPosts(idsFromTable),
      events: displayEvents(idsFromTable),
      branches: displayBranches(idsFromTable),
      pages: displayPages(idsFromTable),
      ...(modal?.data ? { categories: displayCategory(modal?.data) } : null),
    };

    const graphQLPageSchema = graphQLPageSchemas[pageName];
    const SET_VISIBLE_POST = graphQLPageSchema;
    mutate(SET_VISIBLE_POST);
    handleClose();
  };

  useEventEnterListener(onSubmit);

  return <BaseModal title="show" onSubmit={onSubmit} onClose={handleClose} />;
};

export default SetVisible;
