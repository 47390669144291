import constants from '@admin8/shared/constants';

const { RECORD_STATUS_NEW, RECORD_STATUS_VALID } = constants;

// TODO: Product filter by category id show only products that have actual category in it should show all nested categories: categoryId: "${category?.categoryId ?? ''}"
export const getProducts = (
  first: number,
  page: number,
  text = { name: '', value: '' },
  category?: { id: string },
  sort?: any,
) => {
  return {
    query: `
    {
      products(
        first: ${first}
        page: ${page}
        where: { column: STATUS_ID operator: IN value: [${RECORD_STATUS_NEW}, ${RECORD_STATUS_VALID}]}   
        ${text.value ? `fulltext: "${text.value}"` : ''}
        ${category?.id ? `categoryId: "${category.id}"` : ''}
        ${sort ? `orderBy: { column: ${sort.fieldName}, order: ${sort.orderDirection} }` : ''}
        ) {
        paginatorInfo { total, lastPage }
        data {
          id
          is_visible
          updated_at
          created_at
          category {
            id
            name
            parent_id
          }
          actual_version {
            status {
              id
              name
            }
            image_id
            cover_image {
              url
              name
            }
            texts {
              name
              language_id
            }
            variants {
              prices {
                value
                currency
              }
            }
          }
        }
      }
    }    
    `,
  };
};
export const getProductsForCombinations = () => {
  return {
    query: `
    {
      products(first: 1000) {
        data {
          id
          is_visible
          category {
            id
            name
            parent_id
          }
          actual_version {
            status {
              id
              name
            }
            image_id
            cover_image {
              url
              name
            }
            texts {
              name
              language_id
            }
          }
        }
      }
    }   
    `,
  };
};

export const removeProducts = (idsArr: string[]) => {
  return {
    query: `
    mutation($ids: [ID!]!) {
      productDeleteMultiple(id: $ids) {
        id
      }
    }
      `,
    variables: {
      ids: idsArr,
    },
  };
};

export const hideProducts = (idsArr: string[]) => {
  return {
    query: `
    mutation {
      ${idsArr.map(
        (item, index) => `product${index}: productUpdate(
        id: "${item}"
        input: {
          is_visible: false
      }) {
        is_visible
        id
      }`,
      )}
    }
      `,
  };
};

export const displayProducts = (idsArr: string[]) => {
  return {
    query: `
    mutation {
      ${idsArr.map(
        (item, index) => `product${index}: productUpdate(
        id: "${item}"
        input: {
          is_visible: true
      }) {
        is_visible
        id
      }`,
      )}
    }
      `,
  };
};

export const importProducts = (variables) => {
  return {
    query: `
    query importProducts(
      $first: Int!
      $page: Int
      $where: QueryImportProductsWhereWhereConditions
    ) {
      importProducts(first: $first, page: $page, where: $where) {
        paginatorInfo {
          total
          lastPage
        }
        data {
          isbn_code
          imprint_name
          reference_number
          keywords
          created_at
          updated_at
          status {
            id
            name
          }
          product {
            id
            categories {
              id
              name
              parent_id
            }
            actual_version {
              image_id
              cover_image {
                id
                url
              }
              variants {
                id
                name
                image {
                  id
                  name
                  url
                }
                tax_rate
                code
                prices {
                  product_variant_id
                  value
                  valid_from
                  valid_to
                  type_id
                  currency
                }
                texts {
                  product_variant_id
                  language_id
                  name
                  description
                }
              }
            }
          }
          publisher {
            id
            name
          }
          categories {
            id
            name
          }
          contributors {
            role
            name
            id
          }
          texts {
            language_id
            name
            subname
            annotation
            description
            footnote
          }
          prices {
            currency
            tax_rate
            amount
          }
          images {
            filename
            filepath
            id
          }
        }
      }
    }    
    `,
    variables,
  };
};

export const importProductImprints = (variables?: any) => {
  return {
    query: `
    query importProductImprints($publisher_id: ID) {
      importProductImprints(publisher_id: $publisher_id) {
        name
        product_count
      }
    }    
      `,
    variables,
  };
};

export const whitelistProduct = (isbn_code, product_id) => {
  return {
    query: `
    mutation {
      importProductWhitelist(
        isbn_code: ${isbn_code}
        product_id: "${product_id}"
      ) {
        isbn_code
        reference_number
        keywords
        created_at
        updated_at
        status {
          id
          name
        }
        product {
          id
        }
      }
    }
      `,
  };
};

export const blacklistProduct = (idsArr) => {
  return {
    query: `
    mutation {
      ${idsArr.map(
        (isbn_code, index) => `product${index}: importProductBlacklist(isbn_code: ${isbn_code}) {
        isbn_code
        reference_number
        keywords
        created_at
        updated_at
        status {
          id
          name
        }
        product {
          id
        }
      }`,
      )}
    }
      `,
  };
};

export const updateProductsStatus = (idsArr, statusId) => {
  return {
    query: `
    mutation {
      ${idsArr.map(
        (
          id,
          index,
        ) => `productUpdate${index}: productUpdate(id: "${id}", input: { status_id: ${statusId} }) {
          id
        }`,
      )}
    }
      `,
  };
};

export const updateProductStatusAndCategories = (id, statusId, categories) => {
  return {
    query: `
    mutation {
      productUpdate(id: "${id}", input: { 
        status_id: ${statusId}
        categories: { sync: ${JSON.stringify(categories)}
      } }) {
        id
      }
    }
      `,
  };
};

export const getImportProductPublishers = () => {
  return {
    query: `
    {
      importProductPublishers {
        id
        name
        position
        product_count
        
      }
    }
      `,
  };
};

export const importProductSaveContributors = (id, contributors) => {
  return {
    query: `
    mutation {
      importProductSaveContributors(
        product_id: "${id}"
        contributor_ids: ${contributors}
      ) {
        id
        name
      }
    }
      `,
  };
};

export const importProductSaveImage = (productId, imageId) => {
  return {
    query: `
    mutation {
      importProductSaveImage(
        image_id: "${imageId}"
        product_id: "${productId}"
      ) {
        id
        name
        file_name
        file_path
      }
    }
      `,
  };
};
