import axios, { AxiosInstance } from 'axios';

import constants from '@admin8/shared/constants';
import { MainActionType } from '@admin8/shared/types';

import { getRefreshTokenAndResend } from './graphql';
import { getLocalStorageItem } from './localStorage';
import { removeStoredData } from './login';
import { mainContextStore } from './store';

const { TOKEN, REFRESH_TOKEN } = constants;

const instance: AxiosInstance = axios.create({
  // .. where we make our configurations
  // baseURL: 'http://localhost:5000/data/api/v1.0/run/',
  baseURL: process.env['NX_REACT_APP_REST_API'],
});

// Where you would set stuff like your 'Authorization' header, etc ...
instance.defaults.headers.common['Authorization'] = 'Bearer ' + getLocalStorageItem(TOKEN);
// instance.defaults.headers.common["Accept"] = "application/json";
instance.defaults.headers.patch['Content-Type'] = 'application/json';
instance.defaults.headers.put['Content-Type'] = 'application/json';
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.delete['Content-Type'] = 'application/json';

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a 401 response interceptor
instance.interceptors.response.use(
  async (response) => {
    // are there any errors in the response?

    const responseError = response?.data?.errors;
    if (responseError?.length > 0) {
      if (
        responseError?.message === 'Token has expired' ||
        responseError[0]?.extensions.errors === 'This action is unauthorized.'
      ) {
        if (getLocalStorageItem(REFRESH_TOKEN)) {
          const isRefreshFailed = await getRefreshTokenAndResend(instance, response);
          if (isRefreshFailed) {
            throw new Error('try again');
          }
        } else {
          document.body.classList.add('displayLogoutModal');
          removeStoredData();
          mainContextStore.isReady &&
            mainContextStore.dispatch({
              type: MainActionType.SET_LOGIN_STATUS,
              payload: false,
            });
        }
      }

      return response;
    } else {
      return response;
    }
  },
  async (error) => {
    if (401 === error?.response?.status) {
      if (error?.response?.data?.message === 'UNAUTHORIZED') {
        if (getLocalStorageItem(REFRESH_TOKEN)) {
          const isRefreshFailed = await getRefreshTokenAndResend(instance, error);

          if (isRefreshFailed) {
            document.body.classList.add('displayLogoutModal');
            removeStoredData();
            mainContextStore.isReady &&
              mainContextStore.dispatch({
                type: MainActionType.SET_LOGIN_STATUS,
                payload: false,
              });

            throw new Error('try again');
          }
        }
      }
    }

    if (500 === error?.response?.status) {
      return Promise.resolve(error.response);
    } else {
      return Promise.reject(error);
    }
  },
);

export default instance;
