import { DiscountDateType, FormValuesDicountType } from '@admin8/shared/types';
import { escapeString } from '@admin8/shared/utils';

export const getDiscounts = (first: number, page: number, text = { value: '', name: '' }, sort) => {
  return {
    query: `
    {
      discounts(first: ${first}, page: ${page}${
        text.value
          ? `, where: {
          ${
            text.name === 'CURRENCY'
              ? `column: CURRENCY, operator: LIKE, value: "%${text.value}%"`
              : ''
          }
          ${text.name === 'NAME' ? `column: NAME, operator: LIKE, value: "%${text.value}%"` : ''}
          ${text.name === 'VALUE' ? `column: VALUE, operator: LIKE, value: "%${text.value}%"` : ''}
          ${
            text.name === 'VALID_FROM'
              ? `column: VALID_FROM, operator: LIKE, value: "%${text.value}%"`
              : ''
          }
          ${
            text.name === 'VALID_TO'
              ? `column: VALID_TO, operator: LIKE, value: "%${text.value}%"`
              : ''
          }
      }`
          : ''
      }${sort ? `, orderBy: { column: ${sort.fieldName}, order: ${sort.orderDirection} }` : ''}) {
        paginatorInfo { total, lastPage }
        data {
          id
          currency
          code
          name
          value
          valid_from
          valid_to
          type {
            id
            name
          }
          created_at
          updated_at
        }
      }
    }
        `,
  };
};

export const getDiscount = (id: string, langID: number) => {
  return {
    query: `
    {
      discount(id: "${id}") {
        id
        currency
        code
        name
        value
        created_at
        type {
          id
        }
        status {
          id
        }
        quantity
        valid_from
        valid_to
        minimal_amount
        texts(where: { column: LANGUAGE_ID, value: ${langID}}) {
          language_id
          name
          description
        }
      }
    }   
        `,
  };
};

export const createDiscount = (
  data: FormValuesDicountType,
  date: DiscountDateType,
  lang: number,
) => {
  return {
    query: `
    mutation {
      discountCreate(input: { 
        name: "${escapeString(data.name)}"
        code: "${data.code}"
        value: ${data.value}
        currency: "${data.currency}"
        type_id: ${data.type}
        ${data.quantity ? `quantity: ${data.quantity}` : ''}
        ${
          date.validFrom.length !== 0
            ? `valid_from: "${date.validFrom.length === 0 ? null : date.validFrom}"`
            : 'valid_from:  null'
        },
        ${
          date.validTo.length !== 0
            ? `valid_to: "${date.validTo.length === 0 ? null : date.validTo}"`
            : 'valid_to: null'
        },
        ${data.minimal_amount ? `minimal_amount: ${data.minimal_amount}` : ''}
        ${
          data.description && data.name
            ? `texts: {
          create: {
            language_id: ${lang}
          ${data.name ? `name: "${escapeString(data.name)}"` : ''}  
          ${data.description ? `description: ${JSON.stringify(data.description)}` : ''} 
          }
        }`
            : ''
        }
      }) {
        id
        name
        code
        value
        currency
        type { id }
        quantity
        valid_from
        valid_to
      }
    }
        `,
  };
};

export const updateDiscount = (
  id: string,
  data: FormValuesDicountType,
  date: DiscountDateType,
  lang: number,
) => {
  return {
    query: `
    mutation  {
        discountUpdate(id: "${id}", input: { 
          name: "${escapeString(data.name)}"
          code: "${data.code}"
          value: ${data.value}
          currency: "${data.currency}"
          type_id: ${data.type}
          ${data.quantity ? `quantity: ${data.quantity}` : ''}
          ${
            date.validFrom.length !== 0
              ? `valid_from: "${date.validFrom.length === 0 ? null : date.validFrom}"`
              : 'valid_from:  null'
          }
          ${
            date.validTo.length !== 0
              ? `valid_to: "${date.validTo.length === 0 ? null : date.validTo}"`
              : 'valid_to: null'
          }
          ${data.minimal_amount ? `minimal_amount: ${data.minimal_amount}` : ''}
          ${
            data.description && data.name
              ? `texts: {
            upsert: {
              language_id: ${lang}
              discount_id: "${id}"
            ${data.name ? `name: "${escapeString(data.name)}"` : ''}  
            ${data.description ? `description: ${JSON.stringify(data.description)}` : ''} 
            }
          }`
              : ''
          }  
        }) {
          id
          name
          code
          value
          currency
          type { id }
          quantity
          valid_from
          valid_to
        }
      }
        `,
  };
};

export const deleteDiscount = (idsArr: string[]) => {
  return {
    query: `
    mutation($ids: [ID!]!)  {
        discountDeleteMultiple(id: $ids) {
          id 
      }
    }
        `,
    variables: {
      ids: idsArr,
    },
  };
};
