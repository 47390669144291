import { Checkbox } from '@mui/material';

import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NotPublishedText } from '@admin8/components';
import { InputCheckboxProps } from '@admin8/shared/types';

export default function InputCheckbox({
  control,
  hasChanged,
  name,
  label,
  isRequired = false,
  defaultValue,
  error,
  errorMessage = '',
  css = '',
  isDisabled = false,
  onChange: customOnChange = undefined,
}: InputCheckboxProps) {
  const { t } = useTranslation();

  const isNotPublishedTextEnabled = typeof hasChanged === 'boolean';
  const inputWrapperCss = css && { className: css };
  const isErrorMsgEnabled = isRequired && error && errorMessage;

  const onChangeHandler = (checked, onChange) => {
    customOnChange && customOnChange(checked);
    onChange(checked);
  };

  return (
    <div {...inputWrapperCss}>
      {isNotPublishedTextEnabled && <NotPublishedText condition={hasChanged} />}

      <Controller
        name={name}
        control={control}
        rules={{ required: isRequired }}
        defaultValue={false}
        render={({ field: { onChange, value, name } }) => {
          value = !!value;
          return (
            <Checkbox
              disabled={isDisabled ? isDisabled : false}
              id={name}
              name={name}
              checked={value}
              onChange={(e) => {
                onChangeHandler(e.target.checked, onChange);
              }}
            />
          );
        }}
      />
      <label htmlFor={name} className={`${isDisabled ? 'disabledText' : ''}`}>
        {label}
      </label>

      {isErrorMsgEnabled && <div className="error">{t(errorMessage)}</div>}
    </div>
  );
}
