import { useTranslation } from 'react-i18next';

import { NotPublishedTextProps } from '@admin8/shared/types';
import './NotPublishedText.scss';

export default function NotPublishedText({ condition, css = '' }: NotPublishedTextProps) {
  const { t } = useTranslation();
  return (
    <div
      className={`${condition ? 'notPublishedOneLineTextShow' : 'notPublishedOneLineTextHidden'}${
        css ? ` ${css}` : ''
      }`}
      data-testid="NotPublishedText"
    >
      {t('Unpublished modified section')}
    </div>
  );
}
