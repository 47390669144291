import { FC, ReactNode, lazy, useContext, useReducer } from 'react';

import ModalContainer from 'react-modal-promise';
import { QueryClientProvider } from 'react-query';
import { Outlet } from 'react-router-dom';

import { CssTransition, SuspenseWithLoading, Toast } from '@admin8/components';
import Header from '@admin8/header';
import { LoseChangesRoute } from '@admin8/lose-changes-route';
import Nav from '@admin8/navigation';
import { useBlocking } from '@admin8/shared/hooks';
import { TableReducerType } from '@admin8/shared/types';
import { queryClient } from '@admin8/shared/utils';
import { MainContext, TableContext, TableReducer, initialTableState } from '@admin8/store';

import './sass/index.scss';

const LogoutModal = lazy(() =>
  import('@admin8/logout').then((module) => ({ default: module.LogoutModal })),
);

export const Layout: FC<{ modals?: ReactNode; children?: ReactNode }> = ({ children, modals }) => {
  const {
    mainContext: { userLoggedIn },
  } = useContext(MainContext);

  const [tableContext, dispatchTable] = useReducer<TableReducerType>(
    TableReducer,
    initialTableState,
  );

  const { cancelNavigationHandler, confirmNavigationHandler, isBlocked } = useBlocking();

  return (
    <QueryClientProvider client={queryClient}>
      <div className="app">
        <Header />

        {userLoggedIn ? (
          <>
            <Toast />

            <div className="layout">
              <ModalContainer />
              {modals}
              <LogoutModal />

              <Nav />

              <LoseChangesRoute
                isOpen={isBlocked}
                onCancel={cancelNavigationHandler}
                onConfirm={confirmNavigationHandler}
              />

              <div className="main">
                <CssTransition>
                  <TableContext.Provider value={{ tableContext, dispatchTable }}>
                    <SuspenseWithLoading>
                      <Outlet />
                    </SuspenseWithLoading>
                  </TableContext.Provider>
                </CssTransition>
              </div>
            </div>
          </>
        ) : (
          <SuspenseWithLoading>
            <Outlet />
          </SuspenseWithLoading>
        )}
      </div>
    </QueryClientProvider>
  );
};
