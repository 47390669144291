import constants from '@admin8/shared/constants';
import { CategoryCreateArr, FormDefaultsCategoryWithParentIDType } from '@admin8/shared/types';
import { stringify } from '@admin8/shared/utils';
const { RECORD_STATUS_VALID } = constants;

export const getProductsByCategories = (id: string) => {
  return {
    query: `
    {
      categoryProducts(where: { column: CATEGORY_ID, value: "${id}" }) {
        id
        name
        category {
          id
          name
        }
        is_visible
        actual_version {
          id
          status {
            id
            name
          }
          name
          texts {
            name
            slug
            annotation
            description
            language_id
          }
          cover_image {
            url
          }
          variants {
            id
            name
            tax_rate
            prices {
              product_variant_id
              value
              valid_from
              valid_to
              type_id
              currency
            }
          }
        }
        created_at
      }
    }    
    `,
  };
};

export const getCategories = () => {
  return {
    query: `{
      categories(
        where: {
          OR: [{ column: STATUS_ID, value: 110 }, { column: STATUS_ID, value: 120 }]
        }
      ) {
        is_visible
        updated_at
        created_at
        cover_image {
          id
          name
          url
          file_path
          file_name
        }
        name
        id
        parent_id
        texts {
          name
          slug
          description
          language_id
        }
        status {
          name
          id
          parent_id
        }
      }
    }    
    `,
  };
};

export const createCategory = (
  formData: FormDefaultsCategoryWithParentIDType,
  descriptionCz: string,
  descriptionEn: string,
  imageID: string,
) => {
  return {
    query: `
      mutation {
        categoryCreate(input: {
          name: "${formData.name_cz}"
          parent_id: ${!formData.parent_id ? null : `"${formData.parent_id}"`}
          status_id: ${RECORD_STATUS_VALID}
          texts: {
            create: [
              { language_id: 220,
                name: "${formData.name_cz}",
                slug: "${formData.slug_cz}",
                description: ${stringify(descriptionCz)} 
              }
              { language_id: 210,
                name: "${formData.name_en}",
                slug: "${formData.slug_en}",
                description: ${stringify(descriptionEn)}
              }
            ]
          }
          ${imageID ? `image_id: "${imageID === '' ? null : imageID}"` : ''}
        }) {
          id
          status {
            name
          }
          name
          parent_id
          texts {
            name
            slug
            language_id
            description
          }
          meta {
            language_id
            name
            value
          }
          cover_image { id, name, url, file_path, file_name }
        }
      }
      `,
  };
};

export const updateCategory = (
  id: string,
  data: FormDefaultsCategoryWithParentIDType,
  textsArr: CategoryCreateArr,
  image: null | { url: string; id: string },
  website,
) => {
  return {
    query: `
      mutation($texts: [UpdateCategoryTextInput!]) {
        categoryUpdate(
          id: "${id}"
          input: {
            name: "${data.name_cz || data.name_en}"
            parent_id: ${!data.parent_id ? null : `"${data.parent_id}"`}
            texts: { ${website.indexOf('joalis.cz') > -1 ? 'update' : 'upsert'}: $texts }
            image_id: ${!image ? null : `"${image.id}"`}
          }
        ) {
          id
          status {
            name
          }
          name
          parent_id
          texts {
            language_id
            name
            slug
            description
          }
        }
      }
    `,
    variables: { texts: textsArr },
  };
};

export const moveCategory = (id: string, parent_id: null | string) => {
  return {
    query: `
      mutation {
        categoryUpdate(
          id: "${id}"
          input: {
            parent_id: ${parent_id ? `"${parent_id}"` : null}
          }
        ) {
          id
          status {
            name
          }
          name
          parent_id
          texts {
            language_id
            name
            slug
          }
        }
      }
    `,
  };
};

export const removeCategory = (id: string) => {
  return {
    query: `
    mutation {
      categoryDelete(id: "${id}") {
        id
        name
      }
    }
    `,
  };
};

export const hideCategory = (category: { id: string }) => {
  return {
    query: `
    mutation {
      category: categoryUpdate(
        id: "${category.id}"
        input: {
          is_visible: false
      }) {
        is_visible
        id
      }
    }`,
  };
};

export const displayCategory = (category: { id: string }) => {
  return {
    query: `
    mutation {
        category: categoryUpdate(
        id: "${category.id}"
        input: {
          is_visible: true
      }) {
        is_visible
        id
      }
    }`,
  };
};

export const getCategorySlugs = (slug: string) => {
  return {
    query: `
    {
      categorySlug(slug: "${slug}") {
        exists
      }
    }
    `,
  };
};
