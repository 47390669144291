import { useContext } from 'react';

import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';

import {
  deleteDiscount,
  deleteUser,
  removeBranches,
  removeConsultants,
  removeEvents,
  removeNotifications,
  removeOrders,
  removePages,
  removePosts,
  removeProducts,
} from '@admin8/graphql';
import { useEventEnterListener } from '@admin8/shared/hooks';
import { axios, defaultMutationFn } from '@admin8/shared/utils';
import { MainContext, setModal } from '@admin8/store';

import BaseModal from './BaseModal';

const RemoveFromLists = () => {
  const {
    mainContext: { modal, tableRef },
    dispatch,
  } = useContext(MainContext);
  const { pathname } = useLocation();
  const pageName = pathname?.substring(1);

  const handleClose = (refresh?: boolean) => {
    modal.returnFunction && modal.returnFunction(refresh);
    dispatch(setModal({ open: false, id: null, data: null, returnFunction: null, size: null }));
  };

  const closeWithoutRefresh = () => handleClose(true);

  const deleteMultiple = async (endpoint: string, ids: string[]) => {
    const deletePromises = ids.map((id) => axios.delete(`/admin/${endpoint}/${id}`));

    try {
      const results = await Promise.all(deletePromises);
      console.log('Všechny položky byly úspěšně smazány', results);
    } catch (error) {
      console.error('Došlo k chybě při mazání položek', error);
    }
  };

  const onSubmit = () => {
    const idsFromTable =
      tableRef?.current?.state?.data
        ?.filter((item) => item?.tableData?.checked)
        ?.map((item) => item.id) || [];

    if (idsFromTable.length) {
      // TODO: all endpoints
      const endpointToRemove = {
        // events: removeEvents(idsFromTable),
        // posts: removePosts(idsFromTable),
        // consultants: removeConsultants(idsFromTable),
        // products: removeProducts(idsFromTable),
        // users: deleteUser(idsFromTable),
        // discounts: deleteDiscount(idsFromTable),
        // notifications: removeNotifications(idsFromTable),
        // orders: removeOrders(idsFromTable),
        // branches: removeBranches(idsFromTable),
        pages: 'pages',
      };

      const endpoint = pageName;
      deleteMultiple(endpoint, idsFromTable);
    }
    handleClose();
  };

  useEventEnterListener(onSubmit);

  return <BaseModal title="remove" onSubmit={onSubmit} onClose={closeWithoutRefresh} />;
};

export default RemoveFromLists;
