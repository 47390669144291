import { createContext } from 'react';

import { AppStateType } from '@admin8/shared/types';

const appInitialState = {
  theme: {
    white: '',
    secondaryLight: '',
  },
  selects: {
    languages: [],
    currencies: [],
    taxRates: [],
    addressTypes: [],
    discountTypes: [],
    eventDifficulty: [],
    eventTypes: [],
    postTypes: [],
    tags: [],
    units: [],
    eventLocationAddress: [],
    orderCurrencySelect: [],
  },
  enabledPages: [],
  chonkyIconFolder: false,
  website: '',
};

const AppContext = createContext<AppStateType>(appInitialState);

export default AppContext;
