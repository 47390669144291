import { GalleryImages, galleryActions, galleryNewImage } from '@admin8/shared/types';

export const galleryUpdate = (drag: number, hover: number): galleryActions => {
  return {
    type: 'UPDATE',
    payload: {
      drag: drag,
      hover: hover,
    },
  };
};

export const galleryAdd = (newImage: galleryNewImage): galleryActions => {
  return {
    type: 'ADD',
    payload: {
      newImg: newImage,
    },
  };
};

export const galleryReplace = (newImage: galleryNewImage, index: number): galleryActions => {
  return {
    type: 'REPLACE',
    payload: {
      newImg: newImage,
      index: index,
    },
  };
};

export const galleryRemove = (index: number): galleryActions => {
  return {
    type: 'REMOVE',
    payload: {
      index: index,
    },
  };
};

export const gallerySerialize = (arr: GalleryImages): galleryActions => {
  return {
    type: 'SERIALIZE',
    payload: arr,
  };
};

export const gallerySet = (arr: GalleryImages): galleryActions => {
  return {
    type: 'SET',
    payload: arr,
  };
};
