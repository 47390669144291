import {
  EventDatesOptionalType,
  EventDatesType,
  SchemaObjectForDatesType,
} from '@admin8/shared/types';

import { convertDateToTimestamp } from './date';

export const createSchemaForDateInputs = (
  oldArray: EventDatesType,
  newArray: EventDatesType,
  idToBeRemoved: string[],
): SchemaObjectForDatesType => {
  const oldArr: EventDatesType = JSON.parse(JSON.stringify(oldArray));
  const newArr: EventDatesOptionalType = JSON.parse(JSON.stringify(newArray));

  const arrayOfNewDates = newArr.filter((item) => {
    const foundObject = !oldArr.find((item2) => item2.id === item.id);
    return foundObject;
  });

  if (arrayOfNewDates) {
    for (const key in arrayOfNewDates) {
      if (Object.hasOwn(arrayOfNewDates, key)) {
        arrayOfNewDates[key].start_at = convertDateToTimestamp(arrayOfNewDates[key].start_at);
        arrayOfNewDates[key].finish_at = convertDateToTimestamp(arrayOfNewDates[key].finish_at);
        /*  if (arrayOfNewDates[key]?.available)
          arrayOfNewDates[key].available = arrayOfNewDates[key].available; */
        delete arrayOfNewDates[key].id;
      }
    }
  }

  const arrayOfChangedDates = newArr.filter((item) => {
    const objectFound = oldArr.find((item2) => item2.id === item.id);
    if (typeof objectFound === 'undefined') {
      return false;
    } else {
      const differs = JSON.stringify(item) !== JSON.stringify(objectFound);
      return differs;
    }
  });

  if (arrayOfChangedDates) {
    for (const key in arrayOfChangedDates) {
      if (Object.hasOwn(arrayOfChangedDates, key)) {
        arrayOfChangedDates[key].start_at = convertDateToTimestamp(
          arrayOfChangedDates[key].start_at,
        );
        arrayOfChangedDates[key].finish_at = convertDateToTimestamp(
          arrayOfChangedDates[key].finish_at,
        );
        /*   if (arrayOfChangedDates[key]?.available)
          arrayOfChangedDates[key].available = arrayOfChangedDates[key].available; */
      }
    }
  }

  const arrayOfDeletedDates = [...idToBeRemoved];

  const schemaObjectForDates: Partial<SchemaObjectForDatesType> = {};

  if (arrayOfNewDates.length > 0) schemaObjectForDates.create = arrayOfNewDates;
  if (arrayOfChangedDates.length > 0) schemaObjectForDates.update = arrayOfChangedDates;
  if (arrayOfDeletedDates.length > 0) schemaObjectForDates.delete = arrayOfDeletedDates;

  return schemaObjectForDates;
};
