import { TextField } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { InputDisabledProps } from '@admin8/shared/types';

import '../base.scss';

export default function InputDisabled({
  name,
  label,
  defaultValue,
  multiLine,
  css = '',
}: InputDisabledProps) {
  const { t } = useTranslation();
  return (
    <div {...(css && { className: css })}>
      <TextField
        className="input"
        name={name}
        label={t(label)}
        value={defaultValue ?? ''}
        variant="outlined"
        inputProps={{ readOnly: true, disabled: true }}
        {...(multiLine && { multiline: true })}
        data-testid={name}
      />
    </div>
  );
}
