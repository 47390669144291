import { ControlsProps } from '@admin8/shared/types';

import styles from './Controls.module.scss';
import Icon from '../icon';

export default function Controls({ deleteValue, index, optional }: ControlsProps) {
  return (
    <div className={styles['controls']}>
      <button
        type="button"
        className={styles['controlButton']}
        onClick={(e) => (optional ? deleteValue(e, index, optional) : deleteValue(e, index))}
      >
        <Icon id="trash" width={18} height={18} />
      </button>
    </div>
  );
}
