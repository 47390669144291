import { TextField } from '@mui/material';

import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NotPublishedText } from '@admin8/components';
import { InputDateProps } from '@admin8/shared/types';

export default function InputDate({
  hasChanged,
  name,
  label,
  isRequired = false,
  defaultValue,
  error,
  errorMessage = '',
  css = '',
  onChange: customOnChange,
  control,
}: InputDateProps) {
  const { t } = useTranslation();

  const onChangeHandler = (e, onChange) => {
    customOnChange && customOnChange(e);
    onChange(e);
  };

  return (
    <div className={css}>
      <NotPublishedText condition={hasChanged} />
      <Controller
        name={name}
        control={control}
        rules={{ required: isRequired }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            className={`input inputDate${hasChanged ? ' notPublishedOneLine' : ''}`}
            label={t(label)}
            value={value}
            inputRef={ref}
            onBlur={onBlur}
            type="date"
            variant="outlined"
            error={!!error}
            data-testid={name}
            onChange={(e) => onChangeHandler(e, onChange)}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />

      {isRequired && error && <div className="error">{t(errorMessage)}</div>}
    </div>
  );
}
