import { createContext } from 'react';

import { DashboardContextType } from '@admin8/shared/types';

export const initialDashboardState = {
  dataOrders: {},
};

export const DashboardContext = createContext<DashboardContextType>({
  dashboardContext: initialDashboardState,
  dispatchDashboard: () => null,
});
