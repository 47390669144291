import constants from '@admin8/shared/constants';

const { RECORD_STATUS_NEW, RECORD_STATUS_VALID } = constants;

export const getProductsTotal = () => {
  return {
    query: `
	  {
		products(first: 999999) {
		  paginatorInfo {
			total
		  }
		}
	  }  
	  `,
  };
};

export const getLatestProduct = () => {
  return {
    query: `
	{
		products(first: 1, orderBy: { column: CREATED_AT, order: DESC }, where: { column: STATUS_ID operator: IN value: [${RECORD_STATUS_NEW}, ${RECORD_STATUS_VALID}]} ) {
		  data {
			id
			is_visible
			updated_at
			created_at
			category {
			  id
			  name
			  parent_id
			}
			actual_version {
			  status {
				id
				name
			  }
			  image_id
			  cover_image {
				url
				name
			  }
			  texts {
				name
				language_id
			  }
			  variants {
				prices {
				  value
				  currency
				}
			  }
			}
		  }
		}
	  }	   
	  `,
  };
};

export const getLatestUpdatedProduct = () => {
  return {
    query: `
	{
		products(first: 1, orderBy: { column: UPDATED_AT, order: DESC }, where: { column: STATUS_ID operator: IN value: [${RECORD_STATUS_NEW}, ${RECORD_STATUS_VALID}]} ) {
		  data {
			id
			is_visible
			updated_at
			created_at
			category {
			  id
			  name
			  parent_id
			}
			actual_version {
			  status {
				id
				name
			  }
			  image_id
			  cover_image {
				url
				name
			  }
			  texts {
				name
				language_id
			  }
			  variants {
				prices {
				  value
				  currency
				}
			  }
			}
		  }
		}
	  }	   
	  `,
  };
};

export const getEventsTotal = () => {
  return {
    query: `
	{
		events(first: 99999) {
		  paginatorInfo {
			total
		  }
		}
	  }		
	  `,
  };
};

export const getLatestEvent = () => {
  return {
    query: `
	{
		events(first: 1, orderBy: { column: CREATED_AT, order: DESC }, where: { column: STATUS_ID operator: IN value: [${RECORD_STATUS_NEW}, ${RECORD_STATUS_VALID}]}) {
		  data {
			id
			type {
			  id
			  name
			}
			name
			slug
			difficulty
			lecturer
			location
			location_address
			price
			annotation
			description
			email
			phone
			address
			latitude
			longitude
			dates {
			  start_at
			}
			status {
			  name
			  id
			}
			is_visible
			updated_at
			created_at
		  }
		}
	  }		  
	  `,
  };
};

export const getLatestUpdatedEvent = () => {
  return {
    query: `
	{
		events(first: 1, orderBy: { column: UPDATED_AT, order: DESC }, where: { column: STATUS_ID operator: IN value: [${RECORD_STATUS_NEW}, ${RECORD_STATUS_VALID}]}) {
		  data {
			id
			type {
			  id
			  name
			}
			name
			slug
			difficulty
			lecturer
			location
			location_address
			price
			annotation
			description
			email
			phone
			address
			latitude
			longitude
			dates {
			  start_at
			}
			status {
			  name
			  id
			}
			is_visible
			updated_at
			created_at
		  }
		}
	  }		  
	  `,
  };
};

export const getPostsTotal = () => {
  return {
    query: `
	{
		posts(first: 99999) {
		  paginatorInfo {
			total
		  }
		}
	  }	  
	  `,
  };
};

export const getLatestPost = () => {
  return {
    query: `
	{
		posts(first: 1, orderBy: { column: CREATED_AT, order: DESC }, where: { column: STATUS_ID operator: IN value: [${RECORD_STATUS_NEW}, ${RECORD_STATUS_VALID}]}) {
			data {
				id
				type {
				  id
				  name
				}
				language_id
				title
				slug
				source
				published
				status {
				  name
				  id
				}
				is_visible
				updated_at
				created_at
			  }
		}
	  }	  
	  `,
  };
};

export const getLatestUpdatedPost = () => {
  return {
    query: `
	{
		posts(first: 1, orderBy: { column: UPDATED_AT, order: DESC }, where: { column: STATUS_ID operator: IN value: [${RECORD_STATUS_NEW}, ${RECORD_STATUS_VALID}]}) {
			data {
				id
				type {
				  id
				  name
				}
				language_id
				title
				slug
				source
				published
				status {
				  name
				  id
				}
				is_visible
				updated_at
				created_at
			  }
		}
	  }	  
	  `,
  };
};

export const getConsultantsTotal = () => {
  return {
    query: `
	{
		consultants(first: 99999) {
		  paginatorInfo {
			total
		  }
		}
	  }  
	  `,
  };
};

export const getLatestConsultant = () => {
  return {
    query: `
	{
		consultants(first: 1, orderBy: { column: CREATED_AT, order: DESC }, where: { column: STATUS_ID operator: IN value: [${RECORD_STATUS_NEW}, ${RECORD_STATUS_VALID}]}) {
			data {
				id
				title
				first_name
				last_name
				degree
				company_name
				email
				status {
				  id
				}
				is_visible
				cover_image {
				  url
				  file_name
				}
				assets {
				  file_name
				}
				city
				street
				updated_at
				created_at
			  }
		}
	  }  
	  `,
  };
};

export const getLatestUpdatedConsultant = () => {
  return {
    query: `
	{
		consultants(first: 1, orderBy: { column: UPDATED_AT, order: DESC }, where: { column: STATUS_ID operator: IN value: [${RECORD_STATUS_NEW}, ${RECORD_STATUS_VALID}]}) {
			data {
				id
				title
				first_name
				last_name
				degree
				company_name
				email
				status {
				  id
				}
				is_visible
				cover_image {
				  url
				  file_name
				}
				assets {
				  file_name
				}
				city
				street
				updated_at
				created_at
			  }
		}
	  }  
	  `,
  };
};

export const getCategoriesTotal = () => {
  return {
    query: `
	{
		categories(
		  where: {
			OR: [{ column: STATUS_ID, value: 110 }, { column: STATUS_ID, value: 120 }]
		  }
		) {
		  id
		}
	  } 
	  `,
  };
};

export const getDiscountsTotal = () => {
  return {
    query: `
	{
		discounts(first: 99999) {
		  paginatorInfo {
			total
		  }
		}
	  }	   
	  `,
  };
};

export const getLatestDiscounts = () => {
  return {
    query: `
	{
		discounts(first: 5, orderBy: { column: CREATED_AT, order: DESC }) {
		  data {
			name
			code
			currency
			valid_from
			valid_to
			value
			id
		  }
		}
	  }  
	  `,
  };
};

export const getLatestDiscount = () => {
  return {
    query: `
	{
		discounts(first: 1, orderBy: { column: CREATED_AT, order: DESC }) {
		  data {
			name
			code
			currency
			valid_from
			valid_to
			value
			id
		  }
		}
	  }  
	  `,
  };
};

export const getLatestUpdatedDiscount = () => {
  return {
    query: `
	  {
		  discounts(first: 1, orderBy: { column: UPDATED_AT, order: DESC }) {
			data {
			  name
			  code
			  currency
			  valid_from
			  valid_to
			  value
			  id
			}
		  }
		}  
		`,
  };
};

export const getReportOrders = () => {
  return {
    query: `
	{
		reportOrders {
		  issue_date
		  count
		  base_amount
		  tax_amount
		  discount_amount
		  delivery_amount
		  payment_amount
		  total_amount
		  currency
		}
	  }  
	  `,
  };
};

export const getLatestOrders = () => {
  return {
    query: `
	{
		orders(first: 5, orderBy: { column: CREATED_AT, order: DESC }) {
		  data {
			id
			delivery {
			  id
			  name
			}
			payment {
			  id
			  name
			}
			payments {
			  id
			  payment_id
			  status_id
			  dttm
			  pay_id
			}
			order_number
			price_total
			currency
			created_at
		  }
		}
	  }
	  `,
  };
};

export const getPagesTotal = () => {
  return {
    query: `
	  {
		pages(first: 99999) {
			paginatorInfo {
			  total
			}
		  }
		}	   
		`,
  };
};

export const getLatestPage = () => {
  return {
    query: `
		{
			pages(first: 1, where: {
			  AND: [
				{
				  OR: [
				  { column: STATUS_ID, value: ${RECORD_STATUS_NEW} }
				  { column: STATUS_ID, value: ${RECORD_STATUS_VALID} }
				]
			  }
			  ]
			}
			orderBy: { column: CREATED_AT, order: DESC }) {
			  paginatorInfo {
				total
				lastPage
			  }
			  data {
				id
				type {
				  id
				  name
				}
				status {
				  id
				  name
				}
				language {
				  id
				  name
				}
				author {
				  id
				  name
				}
				name
				title
				slug
				position
			  }
			}
		  }
		`,
  };
};

export const getLatestUpdatedPage = () => {
  return {
    query: `
		{
			pages(first: 1, where: {
			  AND: [
				{
				  OR: [
				  { column: STATUS_ID, value: ${RECORD_STATUS_NEW} }
				  { column: STATUS_ID, value: ${RECORD_STATUS_VALID} }
				]
			  }
			  ]
			}
			orderBy: { column: UPDATED_AT, order: DESC }) {
			  paginatorInfo {
				total
				lastPage
			  }
			  data {
				id
				type {
				  id
				  name
				}
				status {
				  id
				  name
				}
				language {
				  id
				  name
				}
				author {
				  id
				  name
				}
				name
				title
				slug
				position
			  }
			}
		  }
		`,
  };
};
