import { useContext } from 'react';

import { t } from 'i18next';

import { useLocation, useNavigate } from 'react-router-dom';

import { Icon } from '@admin8/components';
import { getLocalStorageItem, removeStoredData } from '@admin8/shared/utils';
import { MainContext, setIsEditing, setLoginStatus, setOpenNavigation } from '@admin8/store';

import './Header.scss';

export default function Header() {
  const {
    mainContext: { isOpenNav, userLoggedIn, isEditing },
    dispatch,
  } = useContext(MainContext);
  const location = useLocation();
  const navigate = useNavigate();

  const openHandler = () => dispatch(setOpenNavigation(!isOpenNav));

  const logOutHandler = () => {
    removeStoredData();
    dispatch(setLoginStatus(false));
    isEditing && dispatch(setIsEditing(false));
    navigate('/login');
  };

  return (
    <header className="header">
      <div className="headerInner">
        <div className="leftCol">
          <a href="/" className="pulse" title={t('Home')}>
            <img src="/assets/logo.png" width="100%" height="100%" className="logo" alt="Logo" />
          </a>
        </div>
        <div className="rightCol">
          <div
            className={`mobileMenu${isOpenNav ? ' open' : ''}${
              location.pathname.includes('login') ? ' hide' : ''
            }`}
            onClick={openHandler}
          >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
          {userLoggedIn && (
            <>
              <a href="/profile" className="profile pulse" title={t('Profile')}>
                {getLocalStorageItem('user_typo')}
              </a>
              <button className="logout pulse" onClick={logOutHandler} title={t('Log out')}>
                <Icon id="sign-out" width={25} height={25} />
              </button>
            </>
          )}
        </div>
      </div>
    </header>
  );
}
