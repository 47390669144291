export default {
  PAYMENT_CASH_ON_DELIVERY: 10,
  PAYMENT_ONLINE: 20,
  PAYMENT_CASH: 30,
  RECORD_STATUS_NEW: 110,
  RECORD_STATUS_VALID: 120,
  RECORD_STATUS_DELETED: 190,
  LANGUAGE_EN: 210,
  LANGUAGE_CZ: 220,
  PRODUCT_STATUS_TYPE_DRAFT: 310,
  PRODUCT_STATUS_TYPE_VALID: 350,
  USER_TYPE_ADMINISTRATOR: 410,
  USER_TYPE_SHOP_USER: 420,
  PRICE_TYPE_DEFAULT: 610,
  PRICE_TYPE_DISCOUNT: 620,
  ADDRESS_TYPE_INVOICE: 810,
  ADDRESS_TYPE_DELIVERY: 820,
  DISCOUNT_TYPE_AMOUNT: 910,
  DISCOUNT_TYPE_PERCENTAGE: 920,
  PRODUCT_VALUE_TYPE_ICON: 1101,
  PRODUCT_VALUE_TYPE_TAGS: 1102,
  PRODUCT_VALUE_TYPE_TEXT: 1110,
  PRODUCT_VALUE_TYPE_UNIT: 1121,
  EVENT_TYPE_SEMINARS: 1201,
  EVENT_TYPE_CONFERENCE_DAYS: 1202,
  EVENT_TYPE_PUBLIC_LECTURES: 1203,
  EVENT_TYPE_SUMMER_SCHOOL: 1204,
  EVENT_TYPE_OTHER_EVENTS: 1209,
  POST_TYPE_1: 1310,
  POST_TYPE_2: 1320,
  ORDER_PAYMENT_STATUS: 1500,
  ORDER_PAYMENT_INITIATED: 1510,
  ORDER_PAYMENT_CANCELED: 1530,
  ORDER_PAYMENT_CONFIRMED: 1540,
  ORDER_PAYMENT_REJECTED: 1560,
  ORDER_PAYMENT_EXPIRED: 1590,
  // Make next line uppercase
  PRODUCT_IMPORT_STATUS_WHITELISTED: 3420,
  PRODUCT_IMPORT_STATUS_BLACKLISTED: 3430,
  // Basic currency
  CURRENCY_EUR: 'EUR',
  CURRENCY_CZK: 'CZK',
  // Event diffuculty
  EVENT_DIFFICULTY_Z1: 'Z1',
  EVENT_DIFFICULTY_Z2: 'Z2',
  EVENT_DIFFICULTY_Z3: 'Z3',
  EVENT_DIFFICULTY_S1: 'S1',
  EVENT_DIFFICULTY_S2: 'S2',
  EVENT_DIFFICULTY_S3: 'S3',
  EVENT_DIFFICULTY_S4: 'S4',
  EVENT_DIFFICULTY_SV: 'SV',
  EVENT_DIFFICULTY_V: 'V',
  TAX_RATE_0: 0,
  TAX_RATE_10: 10,
  TAX_RATE_15: 15,
  TAX_RATE_21: 21,
  // Roles
  ROLE_ADMIN: '1',
  // Error
  ERROR_DUPLICATED_SLUG: 'The input.slug has already been taken.',
  ERROR_LOGIN_INVALID_CREDENTIALS: 'login.invalidCredentials',
  // LS
  PATHNAME: 'pathname',
  TOKEN: 'token',
  REFRESH_TOKEN: 'refresh_token',
  USER_TYPO: 'user_typo',
  USER_ROLES: 'user_roles',
  USER_PROFILE: 'user_profile',
  EDIT_LANG: 'editLang',
  I18NEXT_LANG: 'i18nextLng',
  ASSET_ROOT_FOLDER: 'asset_root_folder',
  PERMISSIONS: 'permissions',
  // Http
  HTTP_OK: 200,
  HTTP_CREATED: 201,
  HTTP_NO_CONTENT: 204,
};
