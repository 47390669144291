import { useTranslation } from 'react-i18next';
import './Error.scss';

export default function Error({ error }: { error: string | null }) {
  const { t } = useTranslation();
  return error && error !== 'duplicatedSlug' ? (
    <div className="burtsFault">
      <h5>{t('Error')}:</h5>
      <p>{error}</p>
    </div>
  ) : null;
}
