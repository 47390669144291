import constants from '@admin8/shared/constants';
import {
  CombinationGraphQLType,
  FormValuesProductType,
  MergedValuesType,
  MergedVariantsType,
  SeoArrType,
  TagsType,
  TextsArrType,
} from '@admin8/shared/types';
import { escapeString } from '@admin8/shared/utils';

const { RECORD_STATUS_DELETED, PRODUCT_STATUS_TYPE_VALID } = constants;

export const getProductVersion = (id: string) => {
  return {
    query: `
    {
      productVersion(id: "${id}") {
        id
        status {
          id
          name
        }
        name
        cover_image {
          id
          name
          url
          file_path
          file_name
          tags {
            tag_name
          }
        }
        tags {
          tag_name
          language_id
          product_version_id
        }
        texts {
          product_version_id
          language_id
          name
          slug
          introduction
          annotation
          description
          footnote
          subname
        }
        combinations {
          pivot {
            combination {
              id
              name
              actual_version {
                cover_image {
                  url
                  name
                }
                texts {
                  language_id
                  name
                }
              }
              category {
                name
              }
            }
            amount
          }
        }
        product {
          id
          category {
            id
            name
          }
          categories {
            id
            name
          }
          actual_version {
            id
          }
        }
        values {
          language_id
          name
          type_id
          slug
          value
          product_version_id
          metum {
            name
            slug
            value
            language_id
          }
        }
        variants {
          id
          name
          image {
            url
            id
            name
          }
          tax_rate
          texts {
            product_variant_id
            language_id
            name
            description
          }
          prices {
            product_variant_id
            value
            valid_from
            valid_to
            type_id
            currency
          }
        }
        seo {
          product_version_id
          language_id
          title
          description
          keywords
          og_title
          og_type
          og_url
          og_image
        }
      }
    }    
    `,
  };
};

export const getProductVersions = (id: string) => {
  return {
    query: `
    {
      productVersions(where: {
        AND: [
          { column: PRODUCT_ID, value: "${id}" }
          { column: STATUS_ID, operator: NEQ, value: ${RECORD_STATUS_DELETED} }
        ]
      }) {
        id
        name
        status {
          name
          id
        }
        product {
          actual_version {
            id
          }
        }
        updated_at
      }
    }    
    `,
  };
};

export const deleteProductVersion = (versionID: string) => {
  return {
    query: `
    mutation {
      productVersionDelete(id: "${versionID}") {
        id
      }
    }
  `,
  };
};

export const createProductVersion = (
  versionName: FormValuesProductType['versionName'],
  texts: TextsArrType[],
  seo: SeoArrType,
  combinations: CombinationGraphQLType,
  imgID: string | undefined,
  tags: TagsType,
  productID: string | undefined,
  publish: boolean,
  values: MergedValuesType = { default: [], deleted: [] },
  variants: MergedVariantsType,
  string = '',
) => {
  const textsSize = texts.length;
  const seoSize = seo.length;
  const valuesSchemaSize = values.default.length;
  const variantsSchemaSize = variants.create.length;
  const tagsLength = tags.length;
  const combinationslength = combinations.length;
  return {
    query: `
    mutation${
      valuesSchemaSize > 0 ||
      variantsSchemaSize > 0 ||
      textsSize > 0 ||
      seoSize > 0 ||
      tagsLength > 0 ||
      combinationslength > 0
        ? `(${textsSize > 0 ? '$texts: [CreateProductTextInput!]!,' : ''} ${
            seoSize > 0 ? '$seo: [CreateProductSeoInput!]!,' : ''
          } ${valuesSchemaSize > 0 ? '$values: [CreateProductValueInput!]!,' : ''} ${
            variantsSchemaSize > 0 ? '$variants: [CreateProductVariantInput!]!,' : ''
          } ${tagsLength > 0 ? '$tags: [CreateProductTagInput!]!' : ''} ${
            combinationslength > 0 ? `$combinations: [ConnectProductCombinationInput!]` : ''
          })`
        : ''
    } {
      productVersionCreate(input: {
        author_id: 1,
        name: "${escapeString(versionName)}"
        ${imgID ? `image_id: "${imgID}"` : ''}
        product_id: "${productID}"
        ${publish ? `status_id: ${PRODUCT_STATUS_TYPE_VALID}` : ''}
        texts: { create: $texts }
        ${combinationslength > 0 ? `combinations: { sync: $combinations }` : ''}
        ${valuesSchemaSize > 0 ? `values: { create: $values } ` : ''}
        ${variantsSchemaSize > 0 ? `variants: { create: $variants }` : ''}
        ${tagsLength > 0 ? `tags: { create: $tags } ` : ''}
        ${seoSize > 0 ? `seo: { create: $seo } ` : ''}
      }) {
        id
        name
        image_id
        cover_image {
          id
          url
          file_path
          file_name
          tags {
            tag_name
          }
        }
        texts {
          name
          slug
          introduction
          description
          annotation
          footnote
          subname
        }
        combinations {
          pivot {
            combination_id
            combination {
              id
              name
              actual_version {
                name
              }
            }
            amount
            position
          }
        }
    		values {
           language_id, name, type_id, slug, value, metum { name, slug, value, language_id }
          }
        variants {
            id name tax_rate prices { value valid_from valid_to type_id currency }
          }
          seo {
            product_version_id language_id title description keywords og_title og_type og_url og_image
          }
    		product {
      		id
      	category {
        	id
        }
   		 }
      }
      }
    `,
    ...((valuesSchemaSize > 0 ||
      variantsSchemaSize > 0 ||
      textsSize > 0 ||
      tagsLength > 0 ||
      seoSize > 0 ||
      combinationslength > 0) && {
      variables: {
        ...(textsSize > 0 && { texts: texts }),
        ...(combinationslength > 0 && { combinations: combinations }),
        ...(valuesSchemaSize > 0 && { values: values.default }),
        ...(variantsSchemaSize > 0 && { variants: variants.create }),
        ...(tagsLength > 0 && { tags: tags }),
        ...(seoSize > 0 && { seo: seo }),
      },
    }),
    ...(string === 'andSetVersionValid' && { andSetVersionValid: true }),
  };
};

export const updateProductVersion = (
  id: string,
  versionName: FormValuesProductType['versionName'],
  texts: TextsArrType[],
  seo: SeoArrType,
  combinations: CombinationGraphQLType,
  imgID: string | undefined,
  tags: TagsType,
  deletedTags: TagsType,
  setActualVersion: boolean,
  values: MergedValuesType = { default: [], deleted: [] },
  variants: MergedVariantsType,
) => {
  const textsSize = texts.length;
  const seoSize = seo.length;
  const valuesSchemaSize = values.default.length;
  const variantsCreateSchemaSize = variants.create.length;
  const variantsUpdateSchemaSize = variants.update.length;
  const variantsDeleteSchemaSize = variants.deleted.length;
  const tagsLength = tags.length;
  const combinationslength = combinations.length;

  return {
    query: `
    mutation${
      valuesSchemaSize > 0 ||
      variantsCreateSchemaSize > 0 ||
      variantsDeleteSchemaSize > 0 ||
      variantsUpdateSchemaSize > 0 ||
      tagsLength > 0 ||
      textsSize > 0 ||
      seoSize > 0 ||
      combinationslength > 0
        ? `(${textsSize > 0 ? '$texts: [UpsertProductTextInput!]!,' : ''} ${
            seoSize > 0 ? '$seo: [UpsertProductSeoInput!]!,' : ''
          } ${valuesSchemaSize > 0 ? '$values: [UpsertProductValueInput!]!,' : ''} ${
            valuesSchemaSize > 0 && values.deleted.length > 0
              ? '$deleteValues: [DeleteProductValueInput!]!,'
              : ''
          } ${variantsCreateSchemaSize > 0 ? `$createVariants: [CreateProductVariantInput!],` : ''}
          ${
            variantsUpdateSchemaSize > 0 ? `$updateVariants: [UpdateProductVariantInput!],` : ''
          }          
          ${variantsDeleteSchemaSize > 0 ? `$deleteVariants: [ID!],` : ''}
          ${tagsLength > 0 ? '$tags: [UpsertProductTagInput!], ' : ''} ${
            deletedTags.length > 0 ? '$deleteTags: [DeleteProductTagInput!] ' : ''
          } ${combinationslength > 0 ? `$combinations: [ConnectProductCombinationInput!],` : ''})`
        : ''
    } {
        productVersionUpdate(
            id: "${id}"
            input: {    
              name: "${escapeString(versionName)}"
              ${
                imgID || imgID === undefined
                  ? `image_id: ${imgID === undefined ? null : `"${imgID}"`}`
                  : ''
              }
              ${setActualVersion ? `status_id: ${PRODUCT_STATUS_TYPE_VALID}` : ''}
              texts: { upsert: $texts }
              ${combinationslength > 0 ? `combinations: { sync: $combinations }` : ''}
              ${
                valuesSchemaSize > 0
                  ? `values: { upsert: $values, ${
                      values.deleted.length > 0 ? 'delete: $deleteValues' : ''
                    }  } `
                  : ''
              }
              ${
                variantsCreateSchemaSize > 0 ||
                variantsDeleteSchemaSize > 0 ||
                variantsUpdateSchemaSize > 0
                  ? `variants: { ${
                      variantsCreateSchemaSize > 0 ? 'create: $createVariants,' : ''
                    } ${variantsUpdateSchemaSize > 0 ? 'update: $updateVariants,' : ''} ${
                      variantsDeleteSchemaSize > 0 ? 'delete: $deleteVariants,' : ''
                    } }`
                  : ''
              } 
              ${
                tagsLength > 0 || deletedTags.length > 0
                  ? `tags: { ${tagsLength > 0 ? 'upsert: $tags,' : ''} ${
                      deletedTags.length > 0 ? `delete: $deleteTags` : ''
                    } } `
                  : ''
              }
              ${seoSize > 0 ? `seo: { upsert: $seo } ` : ''}
            }) {
          id, name, updated_at
          texts {
            product_version_id, name, slug, introduction, annotation, description, footnote, subname
          }
          combinations {
            pivot {
              combination_id
              combination {
                id
                name
                actual_version {
                  name
                }
              }
              amount
              position
            }
          }
          values {
            language_id, name, type_id, slug, value, metum { name, slug, value, language_id }
          }
          variants {
            id name tax_rate prices { value valid_from valid_to type_id currency }
          }
          seo {
            product_version_id language_id title description keywords og_title og_type og_url og_image
          }
          product {
            id
            category {id,name}
            categories { id, status {name}, name}
            actual_version {id}
          }
          tags {
            product_version_id
            language_id
            tag_name
          }
        }
      }
    `,
    ...((valuesSchemaSize > 0 ||
      variantsCreateSchemaSize > 0 ||
      variantsUpdateSchemaSize > 0 ||
      variantsDeleteSchemaSize > 0 ||
      tagsLength > 0 ||
      textsSize > 0 ||
      seoSize > 0 ||
      combinationslength > 0 ||
      deletedTags.length > 0) && {
      variables: {
        ...(textsSize > 0 && { texts: texts }),
        ...(combinationslength > 0 && { combinations: combinations }),
        ...(valuesSchemaSize > 0 && { values: values.default }),
        ...(valuesSchemaSize > 0 && values.deleted.length > 0 && { deleteValues: values.deleted }),
        ...(variantsCreateSchemaSize > 0 && { createVariants: variants.create }),
        ...(variantsUpdateSchemaSize > 0 && { updateVariants: variants.update }),
        ...(variantsDeleteSchemaSize > 0 && { deleteVariants: variants.deleted }),
        ...(tagsLength > 0 && { tags: tags }),
        ...(deletedTags.length > 0 && { deleteTags: deletedTags }),
        ...(seoSize > 0 && { seo: seo }),
      },
    }),
  };
};

export const setActualVersionValid = (versionID: string) => {
  return {
    query: `
    mutation {
      productVersionUpdate(
          id: "${versionID}"
          input: {    
            status_id: ${PRODUCT_STATUS_TYPE_VALID}
      }) {
        id, name, updated_at, status {id, name}
      }
    }
    `,
  };
};

export const createProduct = (
  data: FormValuesProductType,
  lang: number,
  combinations: CombinationGraphQLType,
  category: string | null,
  imgID: string | undefined,
  tags: TagsType,
  categories: string[],
  values: MergedValuesType = { default: [], deleted: [] },
  variants: MergedVariantsType,
  string = '',
) => {
  const valuesSchemaSize = values.default.length;
  const variantsSchemaSize = variants.create.length;
  const tagsLength = tags.length;
  const combinationslength = combinations.length;

  return {
    query: `
    mutation${
      valuesSchemaSize > 0 || variantsSchemaSize > 0 || tagsLength > 0 || combinationslength > 0
        ? `(${valuesSchemaSize > 0 ? '$values: [CreateProductValueInput!]!,' : ''} ${
            variantsSchemaSize > 0 ? '$variants: [CreateProductVariantInput!]!,' : ''
          } ${tagsLength > 0 ? '$tags: [CreateProductTagInput!]!,' : ''} ${
            combinationslength > 0 ? `$combinations: [ConnectProductCombinationInput!]` : ''
          })`
        : ''
    } {
      productCreate(input: {
        name: "${escapeString(data.name)}",
        ${category ? `category_id: "${category}"` : ''}
        ${categories.length > 0 ? `categories: { sync: ${JSON.stringify(categories)} }` : ''}
        versions: {
          create: [
            {
              name: "${escapeString(data.name)}",
              author_id: 1,
              ${imgID ? `image_id: "${imgID}"` : ''}
              texts: { 
                create: { 
                  language_id: ${lang}
                  name: "${escapeString(data.name)}"            
                  slug: "${escapeString(data.slug)}"
                  ${data.description ? `description: ${JSON.stringify(data.description)}` : ''}
                  ${data.annotation ? `annotation: ${JSON.stringify(data.annotation)}` : ''}
                  ${data.footnote ? `footnote: ${JSON.stringify(data.footnote)}` : ''}
                  ${data.subname ? `subname: ${JSON.stringify(data.subname)}` : ''}
                  ${data.introduction ? `introduction: ${JSON.stringify(data.introduction)}` : ''}
                }
              }
              ${combinationslength > 0 ? `combinations: { sync: $combinations }` : ''}
              ${valuesSchemaSize > 0 ? `values: { create: $values } ` : ''}
              ${variantsSchemaSize > 0 ? `variants: { create: $variants } ` : ''}
              ${tagsLength > 0 ? `tags: { create: $tags } ` : ''}
              seo: {
                create: {
                  language_id: ${lang}
                  title: "${data?.seoTitle ?? ''}"
                  description: "${data?.seoDescription ?? ''}"
                  keywords: "${data?.seoKeywords ?? ''}"
                  og_title: "${data?.seoOg_title ?? ''}"
                  og_type: "${data?.seoOg_type ?? ''}"
                  og_url: "${data?.seoOg_url ?? ''}"
                  og_image: "${data?.seoOg_image ?? ''}"
                }
              }
            }
          ]
        }
      }) {
        id
        name
        actual_version { id }
        versions {
          id
          name
          product {
            id
            category { id }
            categories { id, status {name}, name}
          }
          cover_image {
            id
              url
              file_path
              file_name
              tags {
                tag_name
              }
          }
          texts {
            language_id
            name
            slug
            introduction
            description
            annotation
            footnote
            subname
          }
          combinations {
            pivot {
              combination_id
              combination {
                id
                name
                actual_version {
                  name
                }
              }
              amount
              position
            }
          }
          values {
            language_id
            name
            type_id
            slug
            value
            metum { name, slug, value, language_id }
          }
          variants {
            id
            name
            tax_rate
            prices {
              value
              valid_from
              valid_to
              type_id
              currency
            }
          }
          seo {
            product_version_id language_id title description keywords og_title og_type og_url og_image
          }
        }
      }
    }
    `,
    ...((valuesSchemaSize > 0 ||
      variantsSchemaSize > 0 ||
      tagsLength > 0 ||
      combinationslength > 0) && {
      variables: {
        ...(combinationslength > 0 && { combinations: combinations }),
        ...(valuesSchemaSize > 0 && { values: values.default }),
        ...(variantsSchemaSize > 0 && { variants: variants.create }),
        ...(tagsLength > 0 && { tags: tags }),
      },
    }),
    ...(string === 'andSetVersionValid' && { andSetVersionValid: true }),
  };
};

export const updateProduct = (
  productID: string,
  name: string | boolean,
  mainCategory: string | null | boolean,
  newCategories: string[] | boolean,
  versionID: string | boolean,
  deleteActualVersion = false,
  is_visible = null,
) => {
  return {
    query: `
    mutation {
      productUpdate(
        id: "${productID}"
        input: {
        ${name === typeof 'string' ? `name: "${escapeString(name)}"` : ''}
        ${mainCategory ? `category_id: "${mainCategory}"` : 'category_id: null'}
        ${
          versionID
            ? `actual_version_id: "${versionID}"`
            : deleteActualVersion
              ? 'actual_version_id: null'
              : ''
        }
        ${
          Array.isArray(newCategories) && newCategories.length > 0
            ? `categories: { sync: ${JSON.stringify(newCategories)} }`
            : 'categories: { sync: [] }'
        }
        ${is_visible !== null ? `is_visible: ${is_visible}` : ''}
      }) {
        actual_version {
          id
          status {
            name
            id
          }
          product {
            id
            category {
              id
            }
            categories {
              id
            }
          }
          cover_image {
            name
            id
            url
            file_path
            file_name
            tags {
              tag_name
            }
          }
          texts {
            name
            slug
            introduction
            annotation
            description
            footnote
            subname
          }
        }
      }
    }
    `,
  };
};

export const getProductValues = (language: number, text: string) => {
  return {
    query: `
    {
      productValues(first:1000, language_id: ${language}, fulltext: "${text}", type_id: [1110, 1121, 1102]) {
        data {
          type_id
          language_id
          slug
          name
          }
      }
    }
    `,
  };
};

export const getSlugs = (slug: string, id = '') => {
  return {
    query: `
    {
      productSlug(slug: "${slug}", product_id: "${id}") {
        exists
      }
    }    
    `,
  };
};
