import constants from '@admin8/shared/constants';
import { MainActionType } from '@admin8/shared/types';

import { getLocalStorageItem, setLocalStorageItem } from './localStorage';
import { removeStoredData } from './login';
import { mainContextStore } from './store';

const { TOKEN, REFRESH_TOKEN } = constants;

export const getRefreshTokenAndResend = async (axiosInstance, error) => {
  const refreshToken = getLocalStorageItem(REFRESH_TOKEN);
  // console.log('refreshToken', refreshToken);

  if (refreshToken) {
    try {
      const res = await axiosInstance.post('auth/refresh', {
        data: {
          refresh_token: refreshToken,
        },
      });

      if (res.data.success) {
        setLocalStorageItem(TOKEN, res?.data?.data?.access_token);
        setLocalStorageItem(REFRESH_TOKEN, res?.data?.data?.refresh_token);
        axiosInstance.defaults.headers.common['Authorization'] =
          'Bearer ' + res?.data?.data?.access_token;

        return;
      } else {
        return true;
      }

      // document.body.classList.add('displayLogoutModal');
      // removeStoredData();
      // mainContextStore.isReady &&
      //   mainContextStore.dispatch({
      //     type: MainActionType.SET_LOGIN_STATUS,
      //     payload: false,
      //   });
    } catch (error) {
      console.log('getRefreshTokenAndResend ', error);
      return true;
    }
  }

  document.body.classList.add('displayLogoutModal');
  removeStoredData();
  mainContextStore.isReady &&
    mainContextStore.dispatch({
      type: MainActionType.SET_LOGIN_STATUS,
      payload: false,
    });
};
