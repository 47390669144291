import { createContext } from 'react';

import { ChartSliderContextType } from '@admin8/shared/types';
import { dates } from '@admin8/shared/utils';

const { YEAR_NOW } = dates;

export const initialChartSliderState = {
  lastYear: 0,
  month: 1,
  monthTurnover: 1,
  // day: 1,
  marks: [],
  marksMonth: [],
  // marksDay: [],
  sliderValue: YEAR_NOW,
  sliderValueTurnover: YEAR_NOW,
};

export const ChartSliderContext = createContext<ChartSliderContextType>({
  chartSliderContext: initialChartSliderState,
  dispatchChartSlider: () => null,
});
