import { TextField } from '@mui/material';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NotPublishedText } from '@admin8/components';
import { FormDefaultsCategoryType, InputCategoryProps } from '@admin8/shared/types';

export default function InputCategory({ name, label, hasChanged, css = '' }: InputCategoryProps) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<FormDefaultsCategoryType>();

  return (
    <div {...(css && { className: css })}>
      <NotPublishedText condition={hasChanged} />
      <Controller
        name={name}
        control={control}
        rules={{ required: { value: true, message: t('Category must have a title') } }}
        render={({ field: { onChange, value } }) => (
          <TextField
            value={value}
            className={`input hiddenHelperText${hasChanged ? ' notPublishedOneLine' : ''}${
              errors?.[name] ? ' hideHelperText' : ''
            }`}
            onChange={onChange}
            label={t(label)}
            variant="outlined"
            error={!!errors[name]}
            data-testid={name}
          />
        )}
      />
      {errors[name]?.message && <div className="error">{errors?.[name]?.message}</div>}
      {(errors?.['slug_cz']?.type === 'slugExists' ||
        errors?.['slug_en']?.type === 'slugExists') && (
        <div className="error hide">{t('Slug already exists')}</div>
      )}
    </div>
  );
}
