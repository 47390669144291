import { useContext } from 'react';

import { useLocation } from 'react-router-dom';

import { ToolbarImageProps } from '@admin8/shared/types';
import { axios } from '@admin8/shared/utils';
import { MainContext, setCategoryEditorCurrent, setModal } from '@admin8/store';

import Icon from '../../../icon';

export default function ToolbarImage({ addImage }: ToolbarImageProps) {
  const location = useLocation();
  const isCategory = location.pathname.includes('categories');
  const { dispatch } = useContext(MainContext);

  const categoryHandler = () =>
    typeof addImage === 'string' && dispatch(setCategoryEditorCurrent(addImage));

  const openModalsHandler = (id = 1, size = 'xxl') => {
    dispatch(
      setModal({
        id: id,
        open: true,
        returnFunction: async (imgId: string) => {
          const response = await axios.get(`/admin/asset/${imgId}`);
          if (response?.data && typeof addImage === 'function') {
            addImage({
              url: response?.data?.url,
              name: response?.data?.name,
              id: response?.data?.id,
            });
          }
        },
        size: size,
        data: {},
      }),
    );
  };

  const imageClickHandler = () => (!isCategory ? openModalsHandler() : categoryHandler());

  return <Icon id="photo-artframe" width={25} height={25} onClick={imageClickHandler} />;
}
