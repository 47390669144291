import { useCallback, useEffect, useState } from 'react';

import { TextField } from '@mui/material';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { NotPublishedText } from '@admin8/components';
import { getCategorySlugs } from '@admin8/graphql';
import {
  DataCategorySlugExist,
  FormDefaultsCategoryType,
  InputCategoryProps,
} from '@admin8/shared/types';
import { debounce, slugify } from '@admin8/shared/utils';

export default function InputCategorySlug({
  name,
  label,
  defaultValue,
  hasChanged,
  css = '',
}: InputCategoryProps) {
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    setError,
  } = useFormContext<FormDefaultsCategoryType>();

  const [slug, setSlug] = useState(defaultValue);
  const GET_SLUGS = getCategorySlugs(slug);
  const { data } = useQuery<DataCategorySlugExist, Error>(['slug', GET_SLUGS], {
    enabled: slug.length > 0 && slug !== defaultValue,
  });
  const [slugExist, setSlugExist] = useState<boolean>(false);

  const setDebouncedSlugValue = debounce((value) => setSlug(value), 2000);

  const slugifyValue = useCallback(
    (value: string, onChange: (...event: any[]) => void) => {
      const slugifyValue = slugify(value);
      setDebouncedSlugValue(slugifyValue);
      onChange(slugifyValue);
    },
    [setDebouncedSlugValue],
  );

  useEffect(() => {
    const slugExist = data?.data?.data?.categorySlug?.exists;

    if (slugExist) {
      setSlugExist(true);
      return setError(name, { type: 'slugExists', message: '' });
    }
    setSlugExist(false);
  }, [data, setError, name]);

  return (
    <div {...(css && { className: css })}>
      <NotPublishedText condition={hasChanged} />
      <Controller
        name={name}
        control={control}
        rules={{
          required: { value: true, message: t('Category must have a slug') },
          validate: {
            slug: () => slugExist === false,
            value: (value) => value?.length > 0 && slugExist === false,
            noErrors: () => (!errors?.[name] ? true : errors?.[name]?.message),
          },
        }}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              className={`input${hasChanged ? ' notPublishedOneLine' : ''}${
                (errors?.[name] && slugExist === false) || slugExist ? ' hideHelperText' : ''
              }`}
              label={t(label)}
              onChange={({ target: { value: onChangeValue } }) =>
                slugifyValue(onChangeValue, onChange)
              }
              value={value}
              variant="outlined"
              error={(errors[name] as any) || slugExist}
              helperText={t('Do not use special characters')}
              data-testid={name}
              onFocus={() => slugifyValue(value, onChange)}
            />
          );
        }}
      />
      {errors[name]?.message && <div className="error">{errors?.[name]?.message}</div>}
      {slugExist && <div className="error">{t('Slug already exists')}</div>}
    </div>
  );
}
