import dayjs from 'dayjs';

export const dates = {
  NOW: dayjs(),
  YEAR_NOW: dayjs().year(),
  MONTH_NOW: dayjs().month(),
  DAY_NOW: dayjs().day(),
  FIRST: 1,
  LAST_MONTH: 12,
  LAST_DAY: 7,
  TODAY_NAME: dayjs().format('dddd'),
  DAYS: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  MONTHS: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
};

export const getTime = (item: string) => dayjs(item).format('HH:mm');

export const getDate = (item: string) => dayjs(item).format('DD.MM.YYYY');

export const getDateForVersionHistory = (item: string) => dayjs(item).format('DD.MM.YYYY - HH:mm');

export const getDateForInput = (item: string, withTime = false) =>
  withTime ? dayjs(item).format('YYYY-MM-DDTHH:mm') : dayjs(item).format('YYYY-MM-DD');

export const getDateTimeForInput = (item: Date) => dayjs(item).format('YYYY-MM-DDTHH:mm');

export const createDateForInput = () => dayjs().format('YYYY-MM-DD');

export const createDateTimeForInput = () => dayjs().format('YYYY-MM-DDTHH:mm');

export const parseDate = (item: string) => dayjs(item).format('YYYY-MM-D');

export const convertDateToTimestamp = (date: string) => {
  if (!date?.length) return '';
  return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
};

export const getDatesFromNow = (dateArr: any[]) => {
  const datesOnlyFromToday = dateArr.filter((current) => dayjs(current.finish_at) >= dates.NOW);
  for (const date of datesOnlyFromToday) {
    if (date['start_at']) date.start_at = dayjs(date.start_at).format('YYYY-MM-DDTHH:mm');
    if (date['finish_at']) date.finish_at = dayjs(date.finish_at).format('YYYY-MM-DDTHH:mm');
  }
  return datesOnlyFromToday;
};

export const getDatesToNow = (dateArr: any[]) => {
  const datesOnlyFromToday = dateArr.filter((current) => dayjs(current.finish_at) < dates.NOW);
  for (const date of datesOnlyFromToday) {
    if (date['start_at']) date.start_at = dayjs(date.start_at).format('YYYY-MM-DDTHH:mm');
    if (date['finish_at']) date.finish_at = dayjs(date.finish_at).format('YYYY-MM-DDTHH:mm');
  }
  return datesOnlyFromToday;
};

export const sortDates = (arr: any[]) => arr.sort((a, b) => dayjs(b).diff(dayjs(a)));
export const sortAtDates = (arr: any[], type: 'created_at' | 'updated_at') =>
  arr.sort((a, b) => (dayjs(b[type]).isValid() ? dayjs(b[type]).diff(dayjs(a[type])) : -1));

// helpers functions

export const getDaysArrayByMonth = (month: number) => {
  let daysInMonth = dayjs(
    `${dates.YEAR_NOW}-${month < 10 ? `0${month}` : month}`,
    'YYY-MM',
  ).daysInMonth();
  const arrDays = [];

  while (daysInMonth) {
    arrDays.push(daysInMonth);
    daysInMonth--;
  }

  arrDays.sort((a, b) => a - b);
  const strArr = arrDays.map((item) => String(item));

  return strArr;
};

export const generateMonth = (date: Date) => {
  const getLocalDay = (date: Date) => {
    let day = date.getDay();
    if (day === 0) {
      day = 7;
    }
    return day;
  };

  const currentMonth = date.getMonth();
  const firstDate = new Date(date.getFullYear(), currentMonth, 1);
  const lastDate = new Date(date.getFullYear(), currentMonth + 1, 0);
  const month = [];
  let week = [];

  for (let i = 1; i < getLocalDay(firstDate); i++) {
    week.push('');
  }

  for (let i = 1; i <= lastDate.getDate(); i++) {
    const tmpDate = new Date(date.getFullYear(), currentMonth, i);
    if (getLocalDay(tmpDate) === 1) {
      if (week.length) month.push(week);
      week = [];
    }
    week.push(i);
  }

  for (let i = getLocalDay(lastDate); i < 7; i++) {
    week.push('');
  }

  if (week.length) month.push(week);

  return month;
};

export const getDaysArray = (year: number, month: number) => {
  const monthIndex = month;
  const names = dates.DAYS;
  const date = dayjs(`${year}-${monthIndex < 10 ? `0${monthIndex}` : monthIndex}-01`);
  let newDate = date;
  const result = [];
  while (newDate.month() + 1 === monthIndex) {
    result.push(newDate.date() + ' - ' + names[newDate.day()]);
    newDate = newDate.set('date', newDate.date() + 1);
  }
  return result;
};
