import { GalleryImages, galleryActions } from '@admin8/shared/types';

export const galleryReducer = (state: GalleryImages, action: galleryActions) => {
  switch (action.type) {
    case 'UPDATE': {
      const copyOfState = Array.from(state);
      const newImages = copyOfState.map((item) => ({
        id: item.id,
        url: item.url,
        file_name: item.file_name,
        mimetype: item.mimetype,
      }));
      const [updatedArr] = newImages.splice(action.payload.drag, 1);
      newImages.splice(action.payload.hover, 0, updatedArr);
      const newUpdates: GalleryImages = newImages.map((item, index) => ({
        ...item,
        position: index,
      }));
      return newUpdates;
    }
    case 'ADD': {
      const copyOfNewState = [...state];
      const newImg = action.payload.newImg;
      newImg.position = copyOfNewState.length;
      const newState = [...copyOfNewState, newImg];
      return newState;
    }
    case 'REPLACE': {
      const copyOfReplacedState = [...state];
      const newReplacedImg = { ...action.payload.newImg };
      newReplacedImg.position = action.payload.index;
      copyOfReplacedState.splice(action.payload.index, 1);
      copyOfReplacedState.splice(action.payload.index, 0, newReplacedImg);
      return copyOfReplacedState;
    }
    case 'REMOVE': {
      const copyOfRemovedState = [...state];
      copyOfRemovedState.splice(action.payload.index, 1);
      return copyOfRemovedState;
    }
    case 'SERIALIZE': {
      const images = [...action.payload];
      const serializedImages = images.map((item, index) => ({ ...item, position: index }));
      return serializedImages;
    }
    case 'SET': {
      const defaultImages = [...action.payload];
      return defaultImages;
    }
    default:
      return state;
  }
};
